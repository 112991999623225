import axios from 'axios';
import { useEffect, useState } from 'react';
import i18next from '../../utils/locales/i18n.js';
import { ApiEndpoint } from 'utils/ApiEndPont.js';
import { Card, Col, Row } from 'react-bootstrap';
import ListItem from 'Dashboards/Cards/ListItem.js';
import ListItemLabor from 'Dashboards/Cards/ListItemLabor.js';
import { FormatCurrency2Dec } from 'utils/FormatData.js';

const ServiceCustomerPartsList = ({ idService }) => {
  const [serviceParts, setServiceParts] = useState([]);
  const [refreshParts, setRefreshParts] = useState(false);
  const URI = ApiEndpoint + 'services/parts/';

  useEffect(() => {
    getServiceParts();
  }, []);

  useEffect(() => {
    if (refreshParts === true) {
      getServiceParts();
      setRefreshParts(false);
    }
  }, [refreshParts]);

  const getServiceParts = () => {
    axios.get(URI + idService).then(response => {
      console.log('response.data_parts');
      console.log(response.data);
      let parts = response.data
        .filter(item => item.chargetocustomer == true)
        .map(part => {
          return {
            description: part.part.description,
            category: '', //part.commonfailure.symtomdescription,
            status: '0', //part.idcommonfailurestatus,
            comments: part.part.comments,
            quantity: part.quantity,
            price: part.price,
            subtotal: part.quantity * part.price,
            title: part.part.partcode,
            icon: 'toolbox'
          };
        });

      setServiceParts(parts);
      // setIdsymptomcategory(response.data.idsymptomcategory);
    });
  };
  const getTotalPrice = items => {
    return items
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {i18next.t('label.Parts')}
              </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions"></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {serviceParts.length > 0 ? (
            <ListItemLabor data={serviceParts} />
          ) : (
            <span>
              <em>No data was found.</em>
            </span>
          )}
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="flex-between-center">
            <Col xs="auto"></Col>
            <Col xs="auto">
              <h4 className="text-primary mb-0">
                Total {FormatCurrency2Dec(getTotalPrice(serviceParts))}
              </h4>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};
export default ServiceCustomerPartsList;
