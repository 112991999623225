import {
  Page,
  Document,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer';

import { useEffect, useState } from 'react';
import HeaderPdf from 'shared/HeaderPdf';
import {
  FormatCurrency,
  FormatCurrency2Dec,
  FormatDate
} from 'utils/FormatData';
import CustomTablePDF from './_TablePDF';
const BORDER_COLOR = '#000';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  headerDiv: {
    'text-align': 'center!important',
    'margin-bottom': '1rem!important',
    display: 'flex',
    'flex-wrap': 'wrap'
  },
  logoheader: {
    'text-align': 'left!important',
    flex: '0 0 auto',
    width: '16.66666667%'
  },
  textHeader: {
    'font-size': '.8333333333rem!important'
  },
  em: {
    fontStyle: 'bold'
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  headerBg: {
    // backgroundColor: '#aaa'
  },
  table: {
    display: 'table',
    width: '50%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 0.5
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
    margin: 0.5
  },

  tableCellHeader: {
    fontSize: 9,
    fontWeight: 'bold'
    // fontFamily: "CustomRoboto",
  },
  tableCell: {
    margin: 1,
    fontSize: 6,
    color: '#2D2D2D'
    // fontFamily: "CustomRoboto",
  },
  textCenter: {
    textAlign: 'center'
  }
});

let tableCol = {
  borderStyle: 'solid',
  borderColor: '#dcdcdc',
  borderBottomColor: '#000',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0.5
};

let footer = { position: 'absolute', left: 0, bottom: 0, right: 0 };
const Br = () => '\n';
// Create Document Component
const ORderPdf = ({ parts, service, failures, labors }) => {
  const serviceParts = parts
    ? parts
        .filter(item => item?.chargetocustomer)
        .map(part => {
          return {
            description: part.part.description,
            partcode: part.part.partcode,
            quantity: part.quantity,
            price: part.price,
            amount: part.price * part.quantity
          };
        })
    : [];
  const serviceFailures = failures
    ? failures
        .filter(
          item =>
            item?.idcommonfailurestatus === 1 ||
            item?.idcommonfailurestatus === 2
        )
        .map(failure => {
          return {
            description: failure.commonfailure.shortdescription,
            price: failure.price ?? 0,
            comments: failure.comments,
            labors: failure.servicelabors
          };
        })
    : [];

  const totalParts = serviceParts.reduce(
    (total, currentValue) => (total = total + Number(currentValue.amount)),
    0
  );
  const totalFailures = serviceFailures.reduce(
    (total, currentValue) => (total = total + Number(currentValue.price)),
    0
  );

  const laborTax = Number(service.laborstax ?? 0) / 100;
  const totalLaborsTax = labors
    ? labors
        .filter(item => !item.isincluded)
        .reduce(
          (total, currentValue) =>
            (total = total + laborTax * Number(currentValue.price)),
          0
        )
    : 0;
  const totalLabors = labors
    ? labors
        .filter(item => !item.isincluded)
        .reduce(
          (total, currentValue) => (total = total + Number(currentValue.price)),
          0
        )
    : 0;
  const totalDiscLabors = labors
    ? labors
        .filter(item => item.price < 0)
        .reduce(
          (total, currentValue) => (total = total + Number(currentValue.price)),
          0
        )
    : 0;

  const totalDiscLaborsNoDisc = labors
    ? labors
        .filter(item => item.price > 0)
        .reduce(
          (total, currentValue) => (total = total + Number(currentValue.price)),
          0
        )
    : 0;
  const tax = Number(service.partstax ?? 0) / 100;
  const totalTax = serviceParts.reduce(
    (total, currentValue) =>
      (total = total + tax * Number(currentValue.amount)),
    0
  );

  return (
    <Document>
      <Page size="A4" style={[styles.body]}>
        <View style={{ padding: '15px' }}>
          <View
            fixed
            style={[
              styles.header,
              {
                display: 'flex',
                flexDirection: 'row',
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }
            ]}
          >
            <View style={{ flex: 1 }}>
              <Image
                style={{
                  width: '100px',
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
                source="/css/falcon.png"
                //assets/img/illustrations/falcon.png
              />
            </View>
            <View
              style={{
                flex: 2,
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              <HeaderPdf></HeaderPdf>
            </View>
            <View
              style={{
                flex: 1,
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              <Text style={{ textAlign: 'center', fontSize: '10px' }}>
                {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'} #
                <Br /> {String('0000000' + service.idservice).slice(-7)}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <View style={{ flex: 2, marginBottom: '0px !important' }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  marginBottom: '0px !important'
                }}
              >
                {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'} FOR
                SERVICES
              </Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text style={{ textAlign: 'right', fontSize: '10px' }}>
                {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'} Date:{' '}
                {FormatDate(service.datecreate)}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <View
              style={{
                flex: 4,
                height: 1,
                backgroundColor: 'black',
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            />
          </View>
          <View
            style={{
              minHeight: '70px',
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <View
              style={{
                width: '50%',
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: '12px',
                  fontWeight: 'bold'
                }}
              >
                {service.customer.company}
              </Text>
              <Text style={{ textAlign: 'left', fontSize: '10px' }}>
                {service.customer.address}
                <Br /> {service.customer.city}, {service.customer.state}
                <Br /> {service.customer.email}-{service.customer.phone}
              </Text>
            </View>
            <View
              style={{
                width: '50%',
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: '10px',
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
              >
                {service.vehicle.year}-{service.vehicle.make}-
                {service.vehicle.model}
                <Br />
                Lic #: {service.vehicle.license} Odometer In: {service.odometer}
                <Br />
                Unit #: {service.vehicle.unit} Vin #: {service.vehicle.vin}
              </Text>
            </View>
            <View style={{ flex: 2 }}></View>
          </View>
          <View
            style={{
              width: '100%',
              minHeight: '40%',
              display: 'table',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <View style={styles.tableRow}>
              <View style={{ flexDirection: 'column', width: '50%' }}>
                <View style={[styles.headerBg, { flexDirection: 'row' }]}>
                  <View key="head1" style={[tableCol, { width: '50%' }]}>
                    <Text
                      style={[styles.tableCellHeader, { textAlign: 'left' }]}
                    >
                      Part Descr.
                    </Text>
                  </View>
                  <View key="head2" style={[tableCol, { width: '10%' }]}>
                    <Text
                      style={[styles.tableCellHeader, { textAlign: 'center' }]}
                    >
                      Qty
                    </Text>
                  </View>
                  <View key="head3" style={[tableCol, { width: '20%' }]}>
                    <Text
                      style={[styles.tableCellHeader, { textAlign: 'center' }]}
                    >
                      Price
                    </Text>
                  </View>
                  <View key="head4" style={[tableCol, { width: '20%' }]}>
                    <Text
                      style={[styles.tableCellHeader, { textAlign: 'center' }]}
                    >
                      Amount
                    </Text>
                  </View>
                </View>
                {serviceParts &&
                  serviceParts.map((item, idx) => (
                    <View key={idx} style={styles.tableRow}>
                      <View key={idx} style={[tableCol, { width: '50%' }]}>
                        <Text
                          style={[
                            styles.tableCell,
                            item.style ? item.style : {}
                          ]}
                        >
                          {item.partcode + ' / ' + item.description}
                        </Text>
                      </View>
                      <View key={idx} style={[tableCol, { width: '10%' }]}>
                        <Text
                          style={{
                            margin: 1,
                            fontSize: 7,
                            textAlign: 'right'
                          }}
                        >
                          {item.quantity}
                        </Text>
                      </View>
                      <View key={idx} style={[tableCol, { width: '20%' }]}>
                        <Text
                          style={{
                            margin: 1,
                            fontSize: 7,
                            textAlign: 'right'
                          }}
                        >
                          {FormatCurrency2Dec(item.price)}
                        </Text>
                      </View>
                      <View key={idx} style={[tableCol, { width: '20%' }]}>
                        <Text
                          style={{
                            margin: 1,
                            fontSize: 7,
                            textAlign: 'right'
                          }}
                        >
                          {FormatCurrency2Dec(item.amount)}
                        </Text>
                      </View>
                    </View>
                  ))}
              </View>
              <View style={{ flexDirection: 'column', width: '50%' }}>
                <View style={[styles.tableRow, styles.headerBg]}>
                  <View key="head4" style={[tableCol, { width: '80%' }]}>
                    <Text
                      style={[styles.tableCellHeader, { textAlign: 'left' }]}
                    >
                      Labor Description
                    </Text>
                  </View>
                  <View key="head5" style={[tableCol, { width: '20%' }]}>
                    <Text
                      style={[styles.tableCellHeader, { textAlign: 'center' }]}
                    >
                      Extended
                    </Text>
                  </View>
                </View>
                <>
                  {serviceFailures &&
                    serviceFailures.map((item, idx) => (
                      <View key={idx} style={styles.tableRow}>
                        <View key={idx} style={[tableCol, { width: '80%' }]}>
                          <Text
                            style={[
                              styles.tableCell,
                              item.style ? item.style : {}
                            ]}
                          >
                            {item.description}
                            {item.labors
                              ? item.labors.map((item, idx) => {
                                  return (
                                    <>
                                      <Br /> {item.shortdescription}
                                    </>
                                  );
                                })
                              : item.comments}
                          </Text>
                        </View>
                        <View key={idx} style={[tableCol, { width: '20%' }]}>
                          <Text
                            style={{
                              margin: 1,
                              fontSize: 8,
                              textAlign: 'right'
                            }}
                          >
                            {FormatCurrency2Dec(item.price)}
                          </Text>
                        </View>
                      </View>
                    ))}
                  {labors &&
                    labors
                      .filter(item => !item.isincluded)
                      .map((item, idx) => (
                        <View key={idx} style={styles.tableRow}>
                          <View key={idx} style={[tableCol, { width: '80%' }]}>
                            <Text
                              style={[
                                styles.tableCell,
                                item.style ? item.style : {}
                              ]}
                            >
                              {item.shortdescription.length > 255
                                ? item.shortdescription.substring(0, 254)
                                : item.shortdescription}
                            </Text>
                          </View>
                          <View key={idx} style={[tableCol, { width: '20%' }]}>
                            <Text
                              style={{
                                margin: 1,
                                fontSize: 8,
                                textAlign: 'right'
                              }}
                            >
                              {FormatCurrency2Dec(item.price)}
                            </Text>
                          </View>
                        </View>
                      ))}
                </>
              </View>
            </View>
          </View>
        </View>

        <View
          style={[
            styles.tableRow,
            {
              borderTop: '1px',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }
          ]}
        >
          <View
            style={{
              paddingLeft: '15px',
              flexDirection: 'row'
            }}
          >
            <View key="total1" style={{ width: '30%' }}>
              <Text
                style={[
                  {
                    textAlign: 'left',
                    fontSize: '10px'
                  }
                ]}
              >
                Part Supplies {FormatCurrency(totalParts)}
              </Text>
            </View>
            <View key="total2" style={[{ textAlign: 'right', width: '30%' }]}>
              <Text style={[{ fontSize: '10px' }]}>
                Labor{' '}
                {FormatCurrency(
                  Number(totalFailures || 0) + Number(totalLabors || 0)
                )}
              </Text>
            </View>
            <View key="total2" style={[{ textAlign: 'right', width: '40%' }]}>
              <Text style={[{ textAlign: 'right', fontSize: '10px' }]}>
                {'Amount:  '}
                {FormatCurrency2Dec(
                  Number(totalDiscLaborsNoDisc) +
                    Number(totalFailures) +
                    Number(totalParts)
                )}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View key="total1" style={{ width: '30%' }}>
            <Text
              style={[
                {
                  textAlign: 'left',
                  fontSize: '10px'
                }
              ]}
            ></Text>
          </View>
          <View key="total2" style={[{ textAlign: 'right', width: '30%' }]}>
            <Text style={[{ fontSize: '10px' }]}></Text>
          </View>
          <View
            key="total2_1"
            style={[
              {
                alignContent: 'right',
                textAlign: 'right',
                color: '#0BA425',
                width: '40%'
              }
            ]}
          >
            <Text style={[{ textAlign: 'right', fontSize: '10px' }]}>
              {'Discounts:  '}
              {FormatCurrency2Dec(Number(totalDiscLabors))}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View key="total1" style={{ width: '30%' }}>
            <Text
              style={[
                {
                  textAlign: 'left',
                  fontSize: '10px'
                }
              ]}
            ></Text>
          </View>
          <View key="total2" style={[{ textAlign: 'right', width: '30%' }]}>
            <Text style={[{ fontSize: '10px' }]}></Text>
          </View>
          <View
            key="total2_1"
            style={[
              {
                alignContent: 'right',
                textAlign: 'right',

                width: '40%'
              }
            ]}
          >
            <Text style={[{ textAlign: 'right', fontSize: '10px' }]}>
              {'SubTotal:  '}
              {FormatCurrency2Dec(
                Number(totalLabors) + Number(totalFailures) + Number(totalParts)
              )}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View key="total1" style={{ width: '30%' }}>
            <Text
              style={[
                {
                  textAlign: 'left',
                  fontSize: '10px'
                }
              ]}
            ></Text>
          </View>
          <View key="total2" style={[{ textAlign: 'right', width: '30%' }]}>
            <Text style={[{ fontSize: '10px' }]}></Text>
          </View>
          <View
            key="total2_1"
            style={[
              {
                alignContent: 'right',
                textAlign: 'right',

                width: '40%'
              }
            ]}
          >
            <Text style={[{ textAlign: 'right', fontSize: '10px' }]}>
              {(service.partstax ?? 0) > 0
                ? 'Tax (' + service.partstax + '%):   '
                : 'Tax:  '}
              {FormatCurrency2Dec(Number(totalTax) + Number(totalLaborsTax))}
            </Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View
            style={{
              flexDirection: 'row'
            }}
          >
            <View key="total1" style={{ width: '30%' }}>
              <Text
                style={[
                  {
                    textAlign: 'right',
                    fontSize: '10px'
                  }
                ]}
              ></Text>
            </View>
            <View key="total2" style={[{ textAlign: 'right', width: '30%' }]}>
              <Text style={[{ fontSize: '10px' }]}></Text>
            </View>
            <View
              key="total2"
              style={[
                { alignContent: 'right', textAlign: 'right', width: '40%' }
              ]}
            >
              <Text style={[{ textAlign: 'right', fontSize: '10px' }]}>
                {'Total:  '}
                {FormatCurrency2Dec(
                  Number(totalLabors) +
                    Number(totalFailures) +
                    Number(totalParts) +
                    Number(totalTax) +
                    Number(totalLaborsTax)
                )}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            bottom: '20pt',
            left: 0,
            position: 'absolute',
            right: 0
          }}
        >
          <Image
            style={{ width: '100%', border: '1px' }}
            source="/css/legals.png"
            //assets/img/illustrations/falcon.png
          />
        </View>
      </Page>
    </Document>
  );
};
export default ORderPdf;
