import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { EditServiceContext } from 'context/Context';
import ConfirmAction from 'form-components/ConfirmationModal';
import i18next from 'i18next';
import React, { useContext, useRef, useState } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency2Dec } from 'utils/FormatData';
const DND_ITEM_TYPE = 'row';
const Row = ({
  labor,
  index,
  moveRow,
  setIdLaborToDelete,
  setOpenConfirmAttended,
  setOpenModalLabor,
  setOpenConfirm,
  setIdServiceLabor,
  setCurrentLabor,
  setAttended,
  isEditable
}) => {
  const dropRef = React.useRef(null);

  const [, drop] = useDrop(() => ({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      //   moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  }));

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DND_ITEM_TYPE,
    item: { labor },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      const dragIndex = item.index;
      console.log('newitem.index');
      console.log(dropResult);
      console.log('item');
      console.log(item);

      moveRow(item.labor, item.index);
    }
  }));

  const opacity = isDragging ? 0 : 1;

  drag(drop(dropRef));

  return (
    <tr
      ref={dropRef}
      style={{ opacity, fontSize: 'smaller', cursor: 'move' }}
      className="align-middle"
      key={'labor_' + labor.idservicelabor}
      row={labor}
    >
      <td style={{ width: '28%' }}>{labor.shortdescription}</td>
      <td className="text-nowrap">{labor.employee?.firstname}</td>
      <td className="text-nowrap">{labor.isincluded ? 'Si' : 'No'}</td>
      <td className="text-nowrap">{labor.visibletocustomer ? 'Si' : 'No'}</td>
      <td className="text-nowrap">{labor.isexternal ? 'Si' : 'No'}</td>
      <td className="text-nowrap">
        {labor.attended ? labor.attendeddate : null}
      </td>
      <td className="text-nowrap">
        {!labor.requireauth || (labor.requireauth && labor.isauth)
          ? 'Si'
          : 'No'}
      </td>
      <td
        className="text-nowrap"
        style={{
          textAlign: 'right',
          color: labor.price >= 0 ? 'black' : 'red'
        }}
      >
        {FormatCurrency2Dec(labor.price ?? 0)}
      </td>

      <td className="text-end">
        {!labor.attended ? (
          <>
            {(!labor.requireauth || (labor.requireauth && labor.isauth)) && (
              <Button
                variant="falcon-default"
                size="sm"
                disabled={!isEditable}
                onClick={() => {
                  setCurrentLabor(labor);
                  setAttended(true);
                  setOpenConfirmAttended(true);
                }}
              >
                <FontAwesomeIcon icon="check" />
              </Button>
            )}
            <Button
              variant="falcon-default"
              size="sm"
              disabled={!isEditable}
              onClick={() => {
                setIdServiceLabor(labor.idservicelabor);
                setOpenModalLabor(true);
              }}
            >
              <FontAwesomeIcon icon="pencil-alt" />
            </Button>
          </>
        ) : (
          ''
        )}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{i18next.t('label.Delete')}</Tooltip>}
        >
          <Button
            variant="falcon-default"
            size="sm"
            disabled={!isEditable}
            onClick={() => {
              setIdLaborToDelete(labor.idservicelabor);
              setOpenConfirm(true);
            }}
          >
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

const LaborsListTable = ({
  laborsList,
  idServiceLabor,
  setIdServiceLabor,
  setCurrentLabor,
  setAttended,
  setIdLaborToDelete,
  setOpenConfirmAttended,
  setOpenModalLabor,
  setOpenConfirm,
  isEditable,
  setRefreshData,
  newId
}) => {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [messageConfirm, setMessageConfirm] = useState(null);
  const [openConfirmMove, setOpenConfirmMove] = useState(null);
  const [laborToMoveConfirmed, setLaborToMoveConfirmed] = useState(null);
  const [newIndex, setNewIndex] = useState(null);

  const handleCloseConfirmMove = () => {
    setOpenConfirmMove(false);
    setOpenConfirmAttended(false);
    setRefreshData(true);
    // window.location.reload();
  };

  const moveRow = (laborToMove, index) => {
    if (laborToMove == null || index == null) return;
    setNewIndex(index + 1);
    setLaborToMoveConfirmed(laborToMove);
    setMessageConfirm(
      'Do you want to move labor ' +
        laborToMove.shortdescription +
        ' to position ' +
        (Number(index) + 1)
    );
    setOpenConfirmMove(true);
  };

  const moveRowConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    const URILabor = ApiEndpoint + 'services/reorderlabor/';
    axios
      .post(URILabor, {
        idservice: laborToMoveConfirmed.idservice,
        idservicelabor: laborToMoveConfirmed.idservicelabor,
        neworder: Number(newIndex) + 1
      })
      .then(function (response) {
        setAlertMessage(i18next.t('label.SuccessfulRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="table-responsive fs--1">
      <Table
        responsive
        striped
        hover
        style={{ width: '100%' }}
        onRow={(record, index) => ({
          index,
          moveRow
        })}
      >
        <thead>
          <tr>
            <th scope="col" st>
              {i18next.t('label.Description')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.Technician')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('status.IsIncluded')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.Visible')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.IsExternal')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.AttendedDate')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.IsAuth')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.Price')}
            </th>
            <th style={{ fontSize: 'smaller' }} scope="col">
              {i18next.t('label.Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {laborsList.map((labor, index) => (
            <Row
              index={index}
              labor={labor}
              moveRow={moveRow}
              setIdLaborToDelete={setIdLaborToDelete}
              setOpenConfirmAttended={setOpenConfirmAttended}
              setOpenModalLabor={setOpenModalLabor}
              setOpenConfirm={setOpenConfirm}
              idServiceLabor={idServiceLabor}
              setIdServiceLabor={setIdServiceLabor}
              setCurrentLabor={setCurrentLabor}
              setAttended={setAttended}
              isEditable={isEditable}
            />
          ))}
        </tbody>
      </Table>
      {openConfirmMove && (
        <ConfirmAction
          message={messageConfirm}
          title={'Confirm'}
          handleClose={handleCloseConfirmMove}
          open={openConfirmMove}
          ConfirmAction={moveRowConfirmed}
        ></ConfirmAction>
      )}
    </div>
  );
};

export default LaborsListTable;
