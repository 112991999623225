export const newDashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  customerRoute: false,
  employeeRoute: true,
  almacenistaRoute: false,
  children: [
    {
      name: 'Dashboard Locations', // `${i18next.t('label.CommonFailures')}`,
      icon: 'file-alt',
      to: '/Services/DashboardLocations/1',
      active: true
    }
  ]
};

export const dashboardCustomer = {
  label: 'Services',
  customerRoute: true,
  almacenistaRoute: false,
  employeeRoute: false,
  labelDisable: true,
  children: [
    {
      name: 'Dashboard', // `${i18next.t('label.CommonFailures')}`,
      icon: 'file-alt',
      to: '/Dashboard/Customers',
      active: true
    }
  ]
};

export const dashboardRoutes = {
  label: 'Services',
  customerRoute: false,
  almacenistaRoute: false,
  employeeRoute: true,
  labelDisable: true,
  children: [
    {
      name: 'Services', // `${i18next.t('label.CommonFailures')}`,
      icon: 'fa-calendar-day',
      to: '/Services/Services',
      active: true
    },
    {
      name: 'Search By Vehicle', // `${i18next.t('label.CommonFailures')}`,
      icon: 'fa-calendar-day',
      to: '/Services/SearchVehicle',
      active: true
    },
    // {
    //   name: 'NewService', // `${i18next.t('label.CommonFailures')}`,
    //   icon: 'flag',
    //   to: '/Services/NewQuickService/',
    //   active: true
    // },
    {
      name: 'Dashboard Customers', // `${i18next.t('label.CommonFailures')}`,
      icon: 'fa-chart-pie',
      to: '/Dashboard/Customers',
      active: true
    }
  ]
};

export const administrationRoutes = {
  label: 'Módulo Administration', //`${i18next.t('label.Administration')}`,
  labelDisable: false,
  customerRoute: false,
  almacenistaRoute: false,
  employeeRoute: false,
  children: [
    {
      name: 'Administration', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'layer-group',
      children: [
        {
          name: 'Customers', // `${i18next.t('label.Customer')}`,
          to: '/Administration/Customers',
          active: true
        }
      ]
    }
  ]
};

export const configurationRoutes = {
  label: 'Moldulo de Configuración', // `${i18next.t('label.Configuration')}`,
  labelDisable: false,
  customerRoute: false,
  employeeRoute: false,
  almacenistaRoute: false,
  children: [
    {
      name: 'Configuration', // `${i18next.t('label.Configuration')}`,
      active: true,
      icon: 'gear',
      children: [
        {
          name: 'Companies', //  `${i18next.t('label.Companies')}`,
          to: '/Configuration/Companies',
          active: true
        },
        {
          name: 'Vendors', //  `${i18next.t('label.Vendors')}`,
          to: '/Configuration/Vendors',
          active: true
        },
        {
          name: 'Locations', //  `${i18next.t('label.Locations')}`,
          to: '/Configuration/Locations',
          active: true
        },
        {
          name: 'Thecnicians', //  `${i18next.t('label.Employees')}`,
          to: '/Configuration/Employees',
          active: true
        },
        {
          name: 'Warehouse', //  `${i18next.t('label.Warehouse')}`,
          to: '/Configuration/warehouses',
          active: true
        },
        {
          name: 'Parts Categories', //  `${i18next.t('label.PartsCategories')}`,
          to: '/Configuration/PartsCategories',
          active: true
        },
        {
          name: 'Categories', //  `${i18next.t('label.SymptomsCategories')}`,
          to: '/Configuration/SymptomsCategories',
          active: true
        },
        {
          name: 'Failures', // `${i18next.t('label.CommonFailures')}`,
          to: '/Administration/CommonFailures',
          active: true
        }
      ]
    }
  ]
};
export const warehouseRoutes = {
  label: 'Módulo de Almacenes', //`${i18next.t('label.Administration')}`,
  labelDisable: false,
  customerRoute: false,
  employeeRoute: true,
  almacenistaRoute: true,
  children: [
    {
      name: 'Warehouse', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'tools',
      children: [
        {
          name: 'Refacciones', // `${i18next.t('label.Customer')}`,
          to: '/Warehouse/Parts',
          active: true
        },
        {
          name: 'Proveedores', // `${i18next.t('label.Customer')}`,
          to: '/Warehouse/Suppliers',
          active: true
        }
      ]
    }
  ]
};
export const securityRoutes = {
  label: 'Security', //`${i18next.t('label.Administration')}`,
  labelDisable: false,
  customerRoute: false,
  almacenistaRoute: false,
  employeeRoute: false,
  children: [
    {
      name: 'Users', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'fa-user',
      children: [
        {
          name: 'Users', // `${i18next.t('label.Customer')}`,
          to: '/Security/Users',
          active: true
        },
        {
          name: 'Thecnical Users', // `${i18next.t('label.Customer')}`,
          to: '/Security/ThecnicalUsers',
          active: true
        }
      ]
    }
  ]
};
export const reportsRoutes = {
  label: 'Reports',
  customerRoute: false,
  almacenistaRoute: false,
  labelDisable: false,
  employeeRoute: false,
  children: [
    {
      name: 'General Reports',
      active: true,
      icon: 'fa-user',
      children: [
        {
          name: 'Labors', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/Labors',
          active: true
        },
        {
          name: 'External Labors', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/ExternalLabors',
          active: true
        },
        {
          name: 'RServices', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/Services',
          active: true
        },
        {
          name: 'Summary', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/Summary',
          active: true
        },
        {
          name: 'Parts Detail', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/Parts',
          active: true
        }
      ]
    },
    {
      name: 'Warehouse Reports',
      active: true,
      icon: 'fa-user',
      children: [
        {
          name: 'Parts', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/Parts',
          active: true
        }
      ]
    },
    {
      name: 'Customers Reports',
      active: true,
      icon: 'fa-user',
      children: [
        {
          name: 'Vehicles', // `${i18next.t('label.CommonFailures')}`,
          icon: 'fa-calendar-day',
          to: '/Reports/Vehicles',
          active: true
        }
      ]
    }
  ]
};

export const accountTypes = {
  label: 'Accounting ', //`${i18next.t('label.Administration')}`,
  labelDisable: false,
  customerRoute: false,
  almacenistaRoute: false,
  employeeRoute: false,
  children: [
    {
      name: 'AccountTypes', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'fa-user',
      to: '/Accounting/AccountTypes'
    },
    {
      name: 'BankAccounts', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'fa-user',
      to: '/Accounting/BankAccounts'
    },
    {
      name: 'PaymentsTypes', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'fa-user',
      to: '/Accounting/PaymentTypes'
    },
    {
      name: 'Payments', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'fa-user',
      to: '/Accounting/Payments'
    },
    {
      name: 'NewPayment', // `${i18next.t('label.Administration')}`,
      active: true,
      icon: 'fa-user',
      to: '/Accounting/NewPayment'
    }
  ]
};

export const helpdocs = {
  label: 'Help & Documentation', //`${i18next.t('label.Administration')}`,
  labelDisable: false,
  customerRoute: false,
  almacenistaRoute: false,
  children: []
};
export default [
  newDashboardRoutes,
  dashboardCustomer,
  dashboardRoutes,
  administrationRoutes,
  configurationRoutes,
  warehouseRoutes,
  reportsRoutes,
  securityRoutes,
  accountTypes,
  helpdocs
];
