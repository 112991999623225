import axios from 'axios';
import { useEffect, useState } from 'react';
import { ApiEndpoint } from 'utils/ApiEndPont';
import FilterVehicleServices from './Filters/FiltersVehicle';
import ListServicesComponent from 'components/franco/ListServicesComponent';
import { FormatDateToDb } from 'utils/FormatData';

const ServicesVehicleList = () => {
  const [vehicleIdFilter, setVehicleIdFilter] = useState(0);
  const [filterFolio, setFilterFolio] = useState(0);
  const [services, setServices] = useState([]);
  //mostrar companies /services/filters
  const getServices = async () => {
    const URI2 = ApiEndpoint + 'servicesfilters/';
    const res = await axios.post(URI2 + 'filters', {
      idcompany: 0,
      idcustomer: 0,
      idvehicle: vehicleIdFilter,
      idlocation: 0,
      idstatus: 0,
      del: '2022/01/01',
      al: FormatDateToDb(new Date()),
      folio: Number(filterFolio)
    });

    setServices(res.data.length > 0 ? res.data : []);
  };

  useEffect(() => {
    getServices();
  }, []);
  return (
    <>
      <FilterVehicleServices
        action={getServices}
        setVehicleIdFilter={setVehicleIdFilter}
        vehicleIdFilter={vehicleIdFilter}
        filterFolio={filterFolio}
        setFilterFolio={setFilterFolio}
      ></FilterVehicleServices>
      <ListServicesComponent services={services}></ListServicesComponent>
    </>
  );
};

export default ServicesVehicleList;
