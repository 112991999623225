import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormInputText } from 'form-components/FormInputText';
import { EditServiceContext } from 'context/Context';
import Select from 'react-select';

const URI = ApiEndpoint + 'scategories/';
const URIFailures = ApiEndpoint + 'failures/';

export default function EditServicePart({
  closeModal,
  idService,
  idServicePart
}) {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [selectedPartStr, setSelectedPartStr] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [idEmployee, setIdEmployee] = useState(0);
  const [chargetoCustomer, setChargetoCustomer] = useState(1);
  const [idPart, setIdPart] = useState(0);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [part, setPart] = useState(0);

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      price: '0',
      serialnumber: '',
      quantity: '1',
      chargetoCustomer: '1'
    }
  });
  const [values, setValues] = useState({
    price: '0',
    serialnumber: '',
    quantity: '1',
    chargetoCustomer: '1'
  });
  const [partService, setPartService] = useState(values);

  useEffect(() => {
    const uriEmployees = ApiEndpoint + 'employees/';
    axios(uriEmployees).then(({ data }) => {
      const employeeslist = data.map(employee => {
        return {
          value: `${employee.idemployee}`,
          label: `${employee.firstname} ${employee.lastname}`
        };
      });
      setEmployeeList(employeeslist);
    });
  }, []);

  useEffect(() => {
    const uriServicePart = ApiEndpoint + 'services/part/' + idServicePart;
    axios(uriServicePart).then(({ data }) => {
      setPartService(data);
      setValues(data);
      setPart(data.part);
    });
  }, []);

  const onSubmit = async (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    const uriParts = ApiEndpoint + 'services/updatepart/' + idServicePart;

    axios
      .put(uriParts, {
        price: partService.price,
        serialnumber: partService.serialnumber,
        quantity: partService.quantity,
        chargetocustomer: chargetoCustomer
      })
      .then(function (response) {
        setAlertMessage(i18next.t('label.SuccessfulRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        console.log(response);
        closeModal();
      })
      .catch(function (error) {
        console.log(error);
        setAlertMessage(i18next.t('label.ErrorSelectValid'));
        setTypeAlert('warning');
        setOpenAlert(true);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setPartService({ ...partService, [name]: value });
  };

  const onChangeIsVisible = event => {
    setChargetoCustomer(!chargetoCustomer);
  };
  return (
    <div>
      {' '}
      {partService && (
        <Card variant="elevation">
          <Card.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col>
                  <Form.Label>
                    {i18next.t('label.Edit')}
                    <h5 className="mb-3 fs-0">{part.description}</h5>
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="employee">
                    <Form.Label>{i18next.t('label.Thecnician')}</Form.Label>
                    <Form.Select
                      aria-label="Default select"
                      name="employee"
                      style={{ minWidth: '250px' }}
                      value={partService.idemployee}
                      onChange={selectedOption => {
                        onChange(selectedOption);
                        setIdEmployee(`${selectedOption.target.value}`);
                      }}
                    >
                      <option key={'location_0'} value={0}>
                        {i18next.t('label.SelectSomeValue')}
                      </option>
                      {!!employeeList?.length &&
                        employeeList.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="quantity">
                    <FormInputText
                      label={i18next.t('label.Quantity')}
                      changeHandler={onChange}
                      name={'quantity'}
                      control={control}
                      defaultValue={partService.quantity}
                    ></FormInputText>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="price">
                    <FormInputText
                      label={i18next.t('label.Price')}
                      changeHandler={onChange}
                      name={'price'}
                      control={control}
                      defaultValue={partService.price}
                    ></FormInputText>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="serial">
                    <FormInputText
                      label={i18next.t('label.Serial')}
                      changeHandler={onChange}
                      name={'serialnumber'}
                      control={control}
                      defaultValue={partService.serialnumber}
                    ></FormInputText>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Check
                    type="switch"
                    id="isVisible"
                    label={i18next.t('label.VisibleToCustomer')}
                    value={partService.chargetocustomer}
                    defaultChecked="true"
                    onChange={onChangeIsVisible}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {' '}
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                    size="sm"
                  >
                    {i18next.t('label.Save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
