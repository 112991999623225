import IconButton from 'components/common/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CloseButton,
  Col,
  Image,
  Modal,
  Row,
  Table
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import axios from 'axios';
import { ApiEndpoint } from 'utils/ApiEndPont';
import ReactToPrint from 'react-to-print';
import {
  Format2Dec,
  FormatCurrency,
  FormatCurrency2Dec,
  FormatDate
} from 'utils/FormatData';
import HeaderPdf from 'shared/HeaderPdf';
import ORderPdf from './ServiceOrderPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
const Br = () => '\n';
const PreviewPrint = ({ idService, currentId, closeModal, newOpenModal }) => {
  let componentRef = useRef();
  const [service, setService] = useState(null);
  const [serviceFailures, setServiceFailures] = useState(null);
  const [serviceParts, setServiceParts] = useState(null);
  const [serviceLabors, setServiceLabors] = useState(null);
  const [totalLabors, setTotalLabors] = useState(0);
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [totalLaborsNoDisc, setTotalLaborsNoDisc] = useState(0);
  const [print, setPrint] = useState(false);
  const [totalParts, setTotalParts] = useState(0);
  const [totalFailures, setTotalFailures] = useState(0);
  const [laborTax, setLaborTax] = useState(0);
  const [partTax, setPartTax] = useState(0);
  const pageStyle = `{ size: 2.5in 4in }`;

  useEffect(() => {
    console.log('idService 1');
    console.log(idService);
  }, []);

  const getService = () => {
    const URI = ApiEndpoint + 'services/' + idService;
    axios
      .get(URI)
      .then(response => {
        let serviceData = response.data[0];
        setService(serviceData);

        const laborTaxTmp = Number(serviceData.laborstax ?? 0) / 100;
        const partTaxTmp = Number(serviceData.partstax ?? 0) / 100;
        setLaborTax(laborTaxTmp);
        setPartTax(partTaxTmp);
      })
      .then(getServiceFailures())
      .then(getServiceLabors())
      .then(getServiceParts());
  };

  useEffect(() => {
    getService();
    // getServiceFailures();
    // getServiceLabors();
    // getServiceParts();
  }, []);

  const getServiceFailures = () => {
    const URI = ApiEndpoint + 'services/failures/';
    axios.get(URI + currentId).then(response => {
      let failures = response.data;
      setServiceFailures(failures);
      if (!failures || failures.length == 0) {
        setTotalFailures(0);
        return;
      }

      const totalFailuresL = failures.reduce(
        (total, currentValue) => (total = total + Number(currentValue.price)),
        0
      );
      setTotalFailures(totalFailuresL);
    });
  };

  const getServiceParts = () => {
    const URIParts = ApiEndpoint + 'services/parts/';
    axios.get(URIParts + currentId).then(response => {
      let parts1 = response.data;
      console.log('parts1');
      console.log(parts1);
      // setIdsymptomcategory(response.data.idsymptomcategory);
      const totalPartsL = parts1
        .filter(item => item.chargetocustomer)
        .reduce(
          (total, currentValue) =>
            (total =
              total +
              Number(currentValue.quantity) * Number(currentValue.price)),
          0
        );
      setTotalParts(totalPartsL);
      setServiceParts(parts1);
    });
  };

  const getServiceLabors = () => {
    const URIParts = ApiEndpoint + 'services/labors/';
    axios.get(URIParts + currentId).then(response => {
      let parts1 = response.data;

      const resultLabors = parts1.filter(
        item => item.idservicefailures === null && item.visibletocustomer
      );

      const subtotalLabors = resultLabors.reduce(
        (total, currentValue) => (total = total + Number(currentValue.price)),
        0
      );
      const subtotalDiscLabors = parts1
        .filter(item => Number(item.price) < 0)
        .reduce(
          (total, currentValue) => (total = total + Number(currentValue.price)),
          0
        );

      const subtotalLaborsNoDisc = parts1
        .filter(item => Number(item.price) > 0)
        .reduce(
          (total, currentValue) => (total = total + Number(currentValue.price)),
          0
        );
      setTotalLaborsNoDisc(subtotalLaborsNoDisc);
      setTotalDiscounts(subtotalDiscLabors);
      setTotalLabors(subtotalLabors);
      setServiceLabors(resultLabors);
    });
  };

  const totalTax = !serviceParts
    ? 0
    : serviceParts.reduce(
        (total, currentValue) =>
          (total =
            total +
            Number(partTax) *
              Number(currentValue.quantity) *
              Number(currentValue.price)),
        0
      );

  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col style={{ alignContent: 'right' }}>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="file"
            className="me-1 mb-2 mb-sm-0"
            iconClassName="me-1"
            onClick={() => {
              setPrint(true);
            }}
          >
            {service && (
              <PDFDownloadLink
                document={
                  <ORderPdf
                    parts={serviceParts}
                    service={service}
                    failures={serviceFailures}
                    labors={serviceLabors}
                  />
                }
                fileName={
                  'COT-' +
                  String('0000000' + service.idservice).slice(-7) +
                  '.pdf'
                }
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Download'
                }
              </PDFDownloadLink>
            )}
          </IconButton>
        </Col>
        <Col>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="print"
                className="me-1 mb-2 mb-sm-0"
                iconClassName="me-1"
              >
                Print
              </IconButton>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>

      {service && (
        <div ref={componentRef}>
          <Row
            className="align-items-center text-center"
            style={{ marginBottom: '0px !important' }}
          >
            <Col sm={2} className="text-sm-start">
              <Image
                style={{ width: '100px', marginBottom: '0px !important' }}
                src="../../css/falcon.png"
              />
            </Col>
            <Col
              sm={6}
              style={{ textAlign: 'center', marginBottom: '0px !important' }}
              className="mt-0 mt-sm-0"
            >
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '12px'
                }}
                className="mt-0 mt-sm-0"
              >
                FRANCO MOBILE REPAIR
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                SHOP: 425 TAFT HWY / MAIL: 7605 Cranberry Way
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                Bakersfield, CA. 93307
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                Phone: 661-377-4222
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                MAIL ADDRESS: 7605 Cranberry Way BAKERSFIELD, CA. 93313
              </div>
            </Col>
            <Col className="text-sm-end mt-0 mt-sm-0">
              <Table>
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'}#{' '}
                      <Br /> {String('0000000' + service.idservice).slice(-7)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: 'left' }}>
              <h6>
                {' '}
                {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'} FOR
                SERVICES
              </h6>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <h6>
                {' '}
                {service.idservicestatus == 1
                  ? 'ESTIMATE'
                  : 'INVOICE'} Date: {FormatDate(service.datecreate)}{' '}
              </h6>
            </Col>
          </Row>{' '}
          <hr style={{ margin: 0 }} />
          <Row
            className="align-items-center"
            style={{
              padding: '0px',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <Col
              style={{
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              {/* <h6 className="text-500">Invoice to</h6> */}
              <h6
                style={{
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
              >
                {service.customer.company}
              </h6>
              <p className="fs--1" style={{ marginBottom: '0px !important' }}>
                {service.customer.address}
                <br />
                {service.customer.city}, {service.customer.state}
                <br />
                {service.customer.email} {service.customer.phone}
              </p>
            </Col>
            <Col>
              {/* <h6 className="text-500">Invoice to</h6> */}
              <h6
                style={{
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
              >
                {service.customer.company}
              </h6>
              <p className="fs--1" style={{ marginBottom: '0px !important' }}>
                {service.vehicle.year}-{service.vehicle.make}-
                {service.vehicle.model}
                <br />
                Lic #: {service.vehicle.license} Odometer In: {service.odometer}
                <br />
                Unit #: {service.vehicle.unit} Vin #: {service.vehicle.vin}
              </p>
            </Col>
          </Row>
          <hr style={{ margin: 0, marginBottom: '0px !important' }} />
          <Row>
            <Col>
              <Table
                striped
                className="border-bottom"
                style={{
                  minHeight: '430px',
                  maxHeight: '430px',
                  padding: '0px',
                  paddingLeft: '2px',
                  display: 'table',
                  width: '100%'
                }}
              >
                <thead className="light">
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      colSpan={6}
                      className="border-0"
                    >
                      Part Description/Number
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      className="border-0 text-center"
                    >
                      Qty
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      className="border-0 text-end"
                    >
                      Price
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      className="border-0 text-end"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {serviceParts &&
                    serviceParts.map(part => (
                      <tr>
                        <td
                          style={{
                            fontSize: 8,
                            color: '#2D2D2D',
                            margin: 1,
                            padding: 1
                          }}
                          colSpan={6}
                          className="align-left"
                        >
                          {part.part.partcode + ' / ' + part.part.description}
                        </td>
                        <td
                          style={{
                            fontSize: 8,
                            color: '#2D2D2D',
                            margin: 1,
                            padding: 1
                          }}
                          className="align-middle text-center"
                        >
                          {part.quantity ?? 1}
                        </td>
                        <td
                          style={{
                            fontSize: 8,
                            color: '#2D2D2D',
                            margin: 1,
                            padding: 1
                          }}
                          className="align-middle text-end"
                        >
                          {part.chargetocustomer
                            ? FormatCurrency2Dec(part.price)
                            : FormatCurrency2Dec(0)}
                        </td>
                        <td
                          style={{
                            fontSize: 8,
                            color: '#2D2D2D',
                            margin: 1,
                            padding: 1
                          }}
                          className="align-middle text-end"
                        >
                          {part.chargetocustomer
                            ? FormatCurrency2Dec(part.price * part.quantity)
                            : FormatCurrency2Dec(0)}
                        </td>
                      </tr>
                    ))}
                  {(() => {
                    let rows = [];
                    for (
                      let i = serviceParts ? serviceParts.length : 0;
                      i < 15;
                      i++
                    ) {
                      rows.push(
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            colSpan={6}
                            className="align-left"
                          >
                            -
                          </td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-center"
                          ></td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-end"
                          ></td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-end"
                          ></td>
                        </tr>
                      );
                    }
                    return rows;
                  })()}
                </tbody>
              </Table>
            </Col>
            <Col>
              <SimpleBarReact>
                <Table
                  style={{
                    minHeight: '420px',
                    maxHeight: '420px',
                    marginBottom: '0px !important',
                    marginTop: '0px !important'
                  }}
                  striped
                  className="border-bottom"
                >
                  <thead className="light">
                    <tr>
                      <th
                        style={{
                          fontSize: 11,
                          color: '#2D2D2D',
                          fontWeight: 'bold'
                        }}
                        colSpan={5}
                        className="border-0"
                      >
                        Labor Description
                      </th>
                      <th
                        style={{
                          fontSize: 11,
                          color: '#2D2D2D',
                          fontWeight: 'bold'
                        }}
                        className="border-0 text-center"
                      >
                        Extended
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceFailures &&
                      serviceFailures.map(failure => (
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            colSpan={5}
                            className="align-left"
                          >
                            {failure.commonfailure.shortdescription}
                            <p className="mb-0">
                              {failure.comments ||
                                failure.commonfailure.symtomdescription}
                            </p>
                          </td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-end"
                          >
                            ${failure.commonfailure.price}
                          </td>
                        </tr>
                      ))}
                    {serviceLabors &&
                      serviceLabors.map(labor => (
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              margin: 1,
                              padding: 1,
                              color: labor.price < 0 ? '#0BA425' : '#2D2D2D'
                            }}
                            colSpan={5}
                            className="align-left"
                          >
                            {labor.shortdescription}
                          </td>
                          <td
                            style={{
                              fontSize: 8,
                              margin: 1,
                              padding: 1,
                              color: labor.price < 0 ? '#0BA425' : '#2D2D2D'
                            }}
                            className="align-middle text-end"
                          >
                            {FormatCurrency2Dec(labor.price)}
                          </td>
                        </tr>
                      ))}
                    {(() => {
                      let rows = [];
                      for (
                        let i = serviceLabors ? serviceLabors.length : 0;
                        i < 15;
                        i++
                      ) {
                        rows.push(
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                color: '#2D2D2D',
                                margin: 1,
                                padding: 1
                              }}
                              colSpan={6}
                              className="align-left"
                            >
                              -
                            </td>
                            <td
                              style={{
                                fontSize: 8,
                                color: '#2D2D2D',
                                margin: 1,
                                padding: 1
                              }}
                              className="align-middle text-center"
                            ></td>
                          </tr>
                        );
                      }
                      return rows;
                    })()}
                  </tbody>
                </Table>
              </SimpleBarReact>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                style={{
                  margin: '1px',
                  marginBottom: '0px !important',
                  padding: '1px'
                }}
                borderless
                size="sm"
                className="fs--1 text-end"
              >
                <tbody>
                  <tr
                    className=" fw-bolder text-800"
                    style={{
                      margin: '0px',
                      padding: '0px',
                      marginBottom: '0px !important'
                    }}
                  >
                    <td>
                      <th> Part Supplies</th>
                      <td>{FormatCurrency(totalParts)}</td>
                    </td>
                    <td>
                      <th> Labor:</th>
                      <td>
                        {FormatCurrency(
                          Number(totalFailures || 0) + Number(totalLabors || 0)
                        )}{' '}
                      </td>
                    </td>
                    <td
                      style={{
                        alignContent: 'right'
                      }}
                    >
                      Amount:{' '}
                      {FormatCurrency(
                        Number(totalLaborsNoDisc || 0) +
                          Number(totalFailures || 0) +
                          Number(totalParts || 0)
                      )}
                    </td>
                  </tr>
                  {totalDiscounts < 0 && (
                    <tr
                      className=" fw-bolder text-800"
                      style={{
                        marginTop: '0px !important',
                        marginBottom: '0px !important'
                      }}
                    >
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          alignContent: 'right',
                          textAlign: 'right',
                          alignItems: 'right',
                          color: '#0BA425',
                          size: 9
                        }}
                      >
                        {totalDiscounts < 0
                          ? 'Discounts:' +
                            FormatCurrency(Number(totalDiscounts || 0))
                          : ''}
                      </td>
                    </tr>
                  )}
                  <tr className=" fw-bolder text-800">
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        alignContent: 'right',
                        textAlign: 'right',
                        alignItems: 'right',
                        size: 9
                      }}
                    >
                      Subtotal:{' '}
                      {FormatCurrency(
                        Number(totalLabors || 0) +
                          Number(totalFailures || 0) +
                          Number(totalParts || 0)
                      )}
                    </td>
                  </tr>
                  <tr className=" fw-bolder text-800">
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        alignContent: 'right',
                        textAlign: 'right',
                        alignItems: 'right',
                        size: 9
                      }}
                    >
                      Tax: {partTax > 0 ? Format2Dec(partTax * 100) + '%' : ''}{' '}
                      {'   '}
                      {FormatCurrency(Number(totalTax || 0))}
                    </td>
                  </tr>
                  <tr className=" fw-bolder text-900">
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        alignContent: 'right',
                        textAlign: 'right',
                        alignItems: 'right'
                      }}
                    >
                      Total{' '}
                      {FormatCurrency(
                        Number(totalLabors || 0) +
                          Number(totalFailures || 0) +
                          Number(totalTax || 0) +
                          Number(totalParts || 0)
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="align-items-center text-center mb-3">
            <Image
              style={{
                width: '100%',
                marginTop: '0px !important',
                maxHeight: '160px',
                border: '1px'
              }}
              src="../../css/legals.png"
            />
          </Row>
        </div>
      )}
    </>
  );
};

export default PreviewPrint;
