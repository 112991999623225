import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { EditServiceContext } from 'context/Context';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import i18next from 'i18next';
import ListItem from 'Dashboards/Cards/ListItem';

const URI = ApiEndpoint + 'services/failures/';
const ServiceCustomerFailures = ({ idService }) => {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [failuresList, setFailuresList] = useState([]);
  const handleClose = () => {
    getServiceFailures();
  };

  useEffect(() => {
    getServiceFailures();
  }, []);

  const getServiceFailures = () => {
    axios.get(URI + idService).then(response => {
      let failures = response.data;
      console.log('failures');
      console.log(failures);
      let newFailureList = failures.map(failure => {
        return {
          description: failure.commonfailure.symtomdescription,
          category: failure.commonfailure.symtomdescription,
          status: failure.idcommonfailurestatus,
          comments: failure.comments,
          price: failure.commonfailure.price,
          idservicefailures: failure.idservicefailures,
          idcommonfailures: failure.idcommonfailures,
          title: failure.commonfailure.shortdescription,
          icon: 'fa-wrench'
        };
      });
      setFailuresList(newFailureList);
    });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {i18next.t('label.FailuresReported')}
              </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions"></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {failuresList.length > 0 ? (
            <ListItem data={failuresList} />
          ) : (
            <span>
              <em>No data was found.</em>
            </span>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ServiceCustomerFailures;
