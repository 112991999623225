import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Flex from 'components/common/Flex';
import { EditServiceContext } from 'context/Context';
import ConfirmAction from 'form-components/ConfirmationModal';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import i18next from 'i18next';

import { v4 as uuidv4 } from 'uuid';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { set } from 'react-hook-form';
import MyModal from 'shared/Modal';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency2Dec } from 'utils/FormatData';
import EditLaborService from './_EditServiceLabor';
import LaborsListTable from './Partials/LaborsListTable';

const URI = ApiEndpoint + 'services/labors/';
const ServiceLaborsList = ({ idService, isEditable, isFinished }) => {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);

  const [openModalLabor, setOpenModalLabor] = useState(false);
  const [laborsList, setLaborsList] = useState([]);
  const [idServiceLabor, setIdServiceLabor] = useState(0);
  const [idLaborToDelete, setIdLaborToDelete] = useState(0);
  const [attended, setAttended] = useState(false);
  const [currentLabor, setCurrentLabor] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [confirmValue, setConfirmValue] = useState(0);
  const [refreshData, setRefreshData] = useState(0);
  const [refreshId, setRefreshId] = useState(null);
  const [openConfirmAttended, setOpenConfirmAttended] = useState(null);

  const handleClose = () => {
    setOpenModalLabor(false);
    getServiceLabors();
    window.location.reload();
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setOpenConfirmAttended(false);
    getServiceLabors();
    window.location.reload();
  };

  const AttendedConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    if (!attended) {
      return;
    }
    if (!currentLabor.idtechnician && currentLabor.idtechnician <= 0) {
      setAlertMessage(i18next.t('label.MustAsignThecnician'));
      setTypeAlert('warning');
      setOpenAlert(true);
      return;
    }

    const URILabor = ApiEndpoint + 'services/laborattended/';
    axios
      .put(URILabor + currentLabor.idservicelabor, {
        hours: confirmValue
      })
      .then(function (response) {
        setAlertMessage(i18next.t('label.SuccessfulRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getServiceLabors();
  }, []);

  useEffect(() => {
    if (!refreshData) return;
    setRefreshId(null);
    setRefreshData(false);
    getServiceLabors();
  }, [refreshData]);

  const getServiceLabors = () => {
    console.log(URI + idService);
    axios.get(URI + idService).then(response => {
      let labors = response.data;
      setLaborsList(labors);

      setRefreshId(uuidv4());
    });
  };

  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    const URIDelete = ApiEndpoint + 'services/labor/';
    axios
      .delete(URIDelete + idLaborToDelete)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        getServiceLabors();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTotalPrice = items => {
    return items
      .filter(item => !item.isincluded)
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };

  const getTotalService = items => {
    return items
      .filter(({ price }) => price > 0)
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };

  const getTotalDisccounts = items => {
    return items
      .filter(({ price }) => price < 0)
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <GenericTableHeader
            label={i18next.t('label.Labors')}
            isFinished={isFinished}
            newFunction={() => {
              setIdServiceLabor(0);
              setOpenModalLabor(true);
            }}
          />
        </Card.Header>
        <Card.Body className="p-0">
          {refreshId && (
            <LaborsListTable
              laborsList={laborsList}
              idServiceLabor={idServiceLabor}
              setIdServiceLabor={setIdServiceLabor}
              setCurrentLabor={setCurrentLabor}
              setAttended={setAttended}
              setIdLaborToDelete={setIdLaborToDelete}
              setOpenConfirmAttended={setOpenConfirmAttended}
              setOpenModalLabor={setOpenModalLabor}
              setOpenConfirm={setOpenConfirm}
              isEditable={isEditable}
              newId={refreshId}
              setRefreshData={setRefreshData}
            ></LaborsListTable>
          )}
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="g-0 flex-between-center">
            <Col xs="auto">
              <h1 className="fs-0 text-900">Total Service</h1>
              <Flex>
                <h4 className="text-primary mb-0">
                  {FormatCurrency2Dec(getTotalService(laborsList))}
                </h4>
              </Flex>
            </Col>
            <Col xs="auto">
              <h1 className="fs-0 text-900">Total Disccounts</h1>
              <Flex>
                <h4 className="text-primary mb-0">
                  {FormatCurrency2Dec(getTotalDisccounts(laborsList))}
                </h4>
              </Flex>
            </Col>
            <Col xs="auto">
              <h1 className="fs-0 text-900">Total Customer</h1>
              <Flex>
                <h4 className="text-primary mb-0">
                  {FormatCurrency2Dec(getTotalPrice(laborsList))}
                </h4>
              </Flex>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {openModalLabor && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.LaborEdit')}
          openModal={openModalLabor}
          closeModal={handleClose}
        >
          <EditLaborService
            idservicelabor={idServiceLabor}
            closeModal={handleClose}
            idService={idService}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          />
        </MyModal>
      )}
      {openConfirm && (
        <ConfirmAction
          message={i18next.t('label.ConfirmDelete')}
          title={'Confirm'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
      {openConfirmAttended && (
        <ConfirmAction
          message={i18next.t('label.ConfirmAttended')}
          title={'Confirm'}
          handleClose={handleCloseConfirm}
          open={openConfirmAttended}
          ConfirmAction={AttendedConfirmed}
          setConfirmValue={setConfirmValue}
          confirmValue={confirmValue}
          isValue={true}
          setRefreshData={setRefreshData}
        ></ConfirmAction>
      )}
    </>
  );
};

export default ServiceLaborsList;
