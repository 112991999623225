import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AlertNotification from 'form-components/AlertNotification';
import ConfirmAction from 'form-components/ConfirmationModal';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader.js';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MyModal from 'shared/Modal';
import { ApiEndpoint } from 'utils/ApiEndPont.js';
import EditUserPassword from './_EditPassword';
import EditUser from './_EditUser';
const URI = ApiEndpoint + 'users/';
const Users = () => {
  const [users, setUsers] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [idUserToDelete, setIdUserToDelete] = useState(0);
  const handleClose = () => {
    setOpenModal(false);
    getUsersList();
    setOpenModalPassword(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    getUsersList();
  };
  useEffect(() => {
    getUsersList();
  }, []);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const getUsersList = async () => {
    setUsers(null);
    const res = await axios.get(URI);
    let usersList = res.data
      .filter(
        item =>
          item.idusertype === 1 ||
          item.idusertype === 2 ||
          item.idusertype === 5
      )
      .map(item => item);
    setUsers(usersList);
    console.log(res.data);
  };

  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    axios
      .delete(URI + idUserToDelete)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        getUsersList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      accessor: 'alias',
      Header: `${i18next.t('label.Alias')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'name',
      Header: `${i18next.t('label.Name')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'lastname',
      Header: `${i18next.t('label.Lastname')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'active',
      Header: `${i18next.t('label.Active')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let user = rowData.row.original.active;
        return user ? 'Yes' : 'No';
      }
    },
    {
      accessor: 'idusertype',
      Header: `${i18next.t('label.UserType')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        let user = rowData.row.original.usertype.usertype;
        return user;
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idemployee = rowData.row.original.iduser;
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{i18next.t('label.Edit')}</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdUser(idemployee);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{i18next.t('label.EditPassword')}</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdUser(idemployee);
                  setOpenModalPassword(true);
                }}
              >
                <FontAwesomeIcon icon={'lock'} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{i18next.t('label.Delete')}</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdUserToDelete(idemployee);
                  setOpenConfirm(true);
                }}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];

  return (
    <>
      {users && (
        <AdvanceTableWrapper
          columns={columns}
          data={users}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <GenericTableHeader
                label={i18next.t('label.Users')}
                newFunction={() => {
                  setIdUser(0);
                  setOpenModal(true);
                }}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
      {openModal && (
        <MyModal
          id="id_myModal"
          title={
            (idUser > 0 ? i18next.t('label.Edit') : i18next.t('label.Add')) +
            ' ' +
            i18next.t('label.User')
          }
          openModal={openModal}
          closeModal={handleClose}
          setOpenAlert={setOpenAlert}
          setTypeAlert={setTypeAlert}
          setAlertMessage={setAlertMessage}
        >
          <EditUser
            idUser={idUser}
            closeModal={handleClose}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          />
        </MyModal>
      )}
      {openModalPassword && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.EditPassword')}
          openModal={openModalPassword}
          closeModal={handleClose}
          setOpenAlert={setOpenAlert}
          setTypeAlert={setTypeAlert}
          setAlertMessage={setAlertMessage}
        >
          <EditUserPassword
            idUser={idUser}
            closeModal={handleClose}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          />
        </MyModal>
      )}
      {openAlert && (
        <AlertNotification
          open={openAlert}
          handleClose={handleCloseAlert}
          type={typeAlert}
          message={alertMessage}
        />
      )}
      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default Users;
