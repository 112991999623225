import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { useAuth } from 'context/AuthProvider';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';

export const MDetailService = () => {
  const Auth = useAuth();
  const [service, setService] = useState([]);
  const [isThecnician, setIsThecnician] = useState(false);
  const [idService, setIdService] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [isAsignable, setIsAsignable] = useState(false);
  const [totalService, setTotalService] = useState(0);
  let { id } = useParams();

  const URI = ApiEndpoint + 'services/' + id;
  const getService = () => {
    axios.get(URI).then(response => {
      let serviceNew = response.data[0];
      setIdService(serviceNew.idservice);
      setService(serviceNew);
      if (
        serviceNew.idservicestatus === 3 ||
        serviceNew.idservicestatus === 4 ||
        serviceNew.idservicestatus === 5
      ) {
        setIsEditable(false);
      }
      if (
        serviceNew.idservicestatus === 5 ||
        serviceNew.idservicestatus === 4
      ) {
        setIsFinished(true);
      }
      if (
        (serviceNew.idservicestatus === 1 || serviceNew.idservicestatus == 2) &&
        (!serviceNew.idtechnician || serviceNew.idtechnician == 0)
      ) {
        setIsAsignable(true);
      }

      let totalLabors = getTotalLaborPrice(serviceNew.servicelabors ?? []);
      let totalParts = getTotalPartsPrice(serviceNew.serviceparts ?? []);

      setTotalService(totalLabors + totalParts);
    });
  };
  useEffect(() => {
    getService();

    const userData = Auth.getSessionData();
    if (userData.idemployee && userData.idemployee > 0) {
      setIsThecnician(true);
    }
  }, []);

  const getTotalLaborPrice = items => {
    return items
      .filter(item => !item.isincluded)
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };

  const getTotalPartsPrice = items =>
    items
      .filter(item => item?.chargetocustomer == true)
      .map(({ quantity, price }) => Number(quantity) * Number(price))
      .reduce((total, currentValue) => Number(total) + Number(currentValue), 0);

  return (
    <>
      {' '}
      <PageHeader
        title={
          i18next.t('label.ServiceDetail') +
          ' - ' +
          String('0000000' + service.idservice).slice(-7)
        }
        titleTag="h5"
        className="mb-3"
      >
        <p className="mb-3">{FormatDate(service.datecreate)}</p>
        Status:
        <SoftBadge
          pill
          bg={classNames({
            success: service.idservicestatus === 1,
            primary: service.idservicestatus === 2,
            warning: service.idservicestatus === 3,
            secondary: service.idservicestatus === 4,
            info: service.idservicestatus === 5
          })}
          className="me-2"
        >
          {service.idservicestatus === 1 && `${i18next.t('status.InProccess')}`}
          {service.idservicestatus === 2 && `${i18next.t('status.InLocation')}`}
          {service.idservicestatus === 3 &&
            `${i18next.t('status.InExternalService')}`}
          {service.idservicestatus === 4 && `${i18next.t('status.Canceled')}`}
          {service.idservicestatus === 5 && `${i18next.t('status.Completed')}`}
          <FontAwesomeIcon
            icon={classNames({
              check:
                service.idservicestatus === `${i18next.t('status.Completed')}`,
              redo:
                service.idservicestatus === `${i18next.t('status.InLocation')}`,
              stream:
                service.idservicestatus ===
                `${i18next.t('status.InExternalService')}`,
              ban:
                service.idservicestatus === `${i18next.t('status.Canceled')}`,
              highlight:
                service.idservicestatus === `${i18next.t('status.InProccess')}`
            })}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
        {service.employee && (
          <p>
            Assigned to:
            {service.employee?.firstname + ' ' + service.employee?.lastname}
          </p>
        )}
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4} className="mb-4 mb-lg-0">
              <h5 className="mb-3 fs-0">Customer</h5>
              <h6 className="mb-2">{service.customer?.shortname}</h6>
              <p className="mb-1 fs--1">{service.customer?.address}</p>
              <p className="mb-0 fs--1">
                <strong>
                  Email:{' '}
                  <a href={`$mailto:${service.customer?.email}`}>
                    {service.customer?.email}
                  </a>
                </strong>
              </p>
              <p className="mb-0 fs--1">
                <strong>
                  Phone: <a href="tel:7897987987">{service.customer?.phone}</a>
                </strong>
              </p>
            </Col>
            <Col md={4} className="mb-4 mb-lg-0">
              <h5 className="mb-3 fs-0">{i18next.t('label.Vehicle')}</h5>
              <h6 className="mb-2">{service.vehicle?.vin}</h6>
              <p className="mb-0 fs--1">
                {service.vehicle?.year ?? ''}-{service.vehicle?.make ?? ''}-
                {service.vehicle?.model ?? ''}
              </p>
              <div className="text-500 fs--1">
                Unit #: {service.vehicle?.unit ?? ''}
              </div>
              {!isFinished && !isThecnician && (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="arrow-down"
                  iconClassName="me-1"
                  onClick={() => {}}
                >
                  {i18next.t('label.ChangeStatus')}
                </IconButton>
              )}
              {isAsignable && (
                <>
                  {isThecnician ? (
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="arrow-down"
                      iconClassName="me-1"
                      onClick={() => {}}
                    >
                      {i18next.t('label.TakeService')}
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="arrow-down"
                      iconClassName="me-1"
                      onClick={() => {}}
                    >
                      {i18next.t('label.AsignService')}
                    </IconButton>
                  )}
                </>
              )}
            </Col>
            <Col md={4}>
              <h5 className="mb-3 fs-0">{i18next.t('label.Total')}</h5>
              <div className="d-flex">
                <div className="flex-1">
                  <h4 className="text-primary mb-0">
                    {FormatCurrency(totalService)}
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="g-3 mb-3">
        <Col
          lg={2}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <div
              className="bg-white dark__bg-1100 p-3 h-100"
              style={{ alignContent: 'center', alignItems: 'center' }}
            >
              <Link
                to={
                  (isThecnician
                    ? '/DetailServiceCustomer/'
                    : '/ServiceDetail/') + service.idService
                }
              >
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src="../css/labors.png"
                  width={100}
                />
              </Link>
              <h6 className="mb-1">
                <Link to="/user/profile">Failures</Link>
              </h6>
            </div>
          </div>
        </Col>
        <Col
          lg={2}
          style={{
            alignContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <div
              className="bg-white dark__bg-1100 p-3 h-100"
              style={{
                alignContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Link
                to={
                  (isThecnician
                    ? '/DetailServiceCustomer/'
                    : '/ServiceDetail/') + service.idService
                }
              >
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src="../css/labors.png"
                  width={100}
                />
              </Link>
              <h6 className="mb-1">
                <Link to="/user/profile">Labors</Link>
              </h6>
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <div
              className="bg-white dark__bg-1100 p-3 h-100"
              style={{
                alignContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Link
                to={
                  (isThecnician
                    ? '/DetailServiceCustomer/'
                    : '/ServiceDetail/') + service.idService
                }
              >
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src="../css/parts.png"
                  width={100}
                />
              </Link>
              <h6 className="mb-1">
                <Link to="/user/profile">Parts</Link>
              </h6>
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <div
              className="bg-white dark__bg-1100 p-3 h-100"
              style={{
                alignContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Link
                to={
                  (isThecnician
                    ? '/DetailServiceCustomer/'
                    : '/ServiceDetail/') + service.idService
                }
              >
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src="../css/files.png"
                  width={100}
                />
              </Link>
              <h6 className="mb-1">
                <Link to="/user/profile">Files</Link>
              </h6>
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <div
              className="bg-white dark__bg-1100 p-3 h-100"
              style={{
                alignContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Link
                to={
                  (isThecnician
                    ? '/DetailServiceCustomer/'
                    : '/ServiceDetail/') + service.idService
                }
              >
                <Image
                  thumbnail
                  fluid
                  roundedCircle
                  className="mb-3 shadow-sm"
                  src="../css/details.png"
                  width={100}
                />
              </Link>
              <h6 className="mb-1">
                <Link to="/user/profile">Details</Link>
              </h6>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
