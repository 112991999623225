import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

export const ProtectedRoute = ({ children }) => {
  const Auth = useAuth();
  const userData = Auth.getSessionData();
  if (!userData) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
