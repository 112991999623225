import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col, Toast } from 'react-bootstrap';
import axios from 'axios';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { useAuth } from 'context/AuthProvider';

const LoginForm = ({ hasLabel, layout }) => {
  const Auth = useAuth();
  let history = useNavigate();
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);

    const URI = ApiEndpoint + 'users/login';
    axios
      .post(URI, {
        user: formData.email,
        password: formData.password
      })
      .then(function (response) {
        if (!response.data.error) {
          Auth.login(response.data);

          return {
            isAuth: true,
            isCustomer: response.data.idcustomer > 0,
            idLocation: response.data.idlocation ?? 0
          };
        }
        return { isAuth: false, isCustomer: false };
      })
      .then(result => {
        if (result.isAuth == false) {
          return result.isAuth;
        }
        if (result.isCustomer == true) {
          return;
        }

        const URI = ApiEndpoint + 'locations/';
        return axios.get(URI).then(response => {
          let locationList = response.data;
          if (result.idLocation > 0) {
            const list = locationList
              .filter(item => item.idLocation == result.idLocation)
              .map(item => {
                return {
                  name: 'Dashboard ' + item.locationName, // `${i18next.t('label.CommonFailures')}`,
                  icon: 'file-alt',
                  to: '/Services/DashboardLocations/' + item.idLocation,
                  active: true
                };
              });
            Auth.setLocations(list);
          } else {
            const list = locationList.map(item => {
              return {
                name: 'Dashboard ' + item.locationName, // `${i18next.t('label.CommonFailures')}`,
                icon: 'file-alt',
                to: '/Services/DashboardLocations/' + item.idLocation,
                active: true
              };
            });
            Auth.setLocations(list);
          }
        });
      })
      .then(isAuth => {
        if (isAuth == false) {
          setShow(true);
        } else {
          history('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <Form.Control.Feedback>
        Provide a valid User and Password
      </Form.Control.Feedback>
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
      <Toast show={show} onClose={() => setShow(!show)} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Invalid Data</strong>
        </Toast.Header>
        <Toast.Body>Please Provide a valid user data</Toast.Body>
      </Toast>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
