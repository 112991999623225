import axios from 'axios';
import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';

const EditPaymentType = ({
  idPaymentType,
  closeModal,
  setOpenAlert,
  setTypeAlert,
  setAlertMessage
}) => {
  const [validated, setValidated] = useState(false);
  const [paymentType, setPaymentType] = useState(values);
  const URI = ApiEndpoint + 'paymenttype/';
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      paymenttype: ''
    }
  });
  const [values, setValues] = useState({
    paymenttype: ''
  });

  useEffect(() => {
    axios.get(URI + idPaymentType).then(response => {
      setValues(response.data);
    });
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    if (idPaymentType > 0) {
      axios
        .put(URI + idPaymentType, {
          idpaymenttype: idPaymentType,
          paymenttype: values.paymenttype
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          paymenttype: values.paymenttype
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3" controlId="paymenttype">
              <FormInputText
                label={i18next.t('label.PaymentType')}
                changeHandler={onChange}
                name={'paymenttype'}
                control={control}
                defaultValue={values.paymenttype}
              ></FormInputText>
            </Form.Group>
            <Button type="submit" color="primary" size="sm">
              {i18next.t('label.Save')}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditPaymentType;
