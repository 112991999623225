const { default: axios } = require('axios');

const ApiUrl = 'https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/';

export const GetVinData = vin => {
  axios.get(ApiUrl + vin + '?format=json').then(response => {
    const result = {
      Make: GetValueJson(response.data.Results, 'Make'),
      Manufacturer: GetValueJson(response.data.Results, 'Manufacturer Name'),
      Model: GetValueJson(response.data.Results, 'Model'),
      Year: GetValueJson(response.data.Results, 'Model Year'),
      VehicleType: GetValueJson(response.data.Results, 'Vehicle Type')
    };
    return result;
  });
};

export const GetValueJson = (jsonObj, key) => {
  var res = jsonObj.filter(function (item) {
    return item.Variable === key;
  });
  return res[0].Value;
};

export const GetVinDataAsync = vin => {
  axios.get(ApiUrl + vin + '?format=json').then(res => {
    const result = {
      Make: GetValueJson(res.data.Results, 'Make'),
      Manufacturer: GetValueJson(res.data.Results, 'Manufacturer Name'),
      Model: GetValueJson(res.data.Results, 'Model'),
      Year: GetValueJson(res.data.Results, 'Model Year'),
      VehicleType: GetValueJson(res.data.Results, 'Vehicle Type')
    };
    return result;
  });
};
