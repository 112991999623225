import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Flex from 'components/common/Flex';
import { EditServiceContext } from 'context/Context';
import ListItem from 'Dashboards/Cards/ListItem';
import ListItemCustomer from 'Dashboards/Cards/ListItemLaborCustomer';
import ConfirmAction from 'form-components/ConfirmationModal';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency2Dec } from 'utils/FormatData';

const URI = ApiEndpoint + 'services/labors/';
const ServiceCustomerLaborsList = ({ idService }) => {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [laborsList, setLaborsList] = useState([]);
  const [confirmAuth, setConfirmAuth] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(null);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    window.location.reload();
  };
  useEffect(() => {
    getServiceLabors();
  }, []);

  const AuthConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    const URIDelete = ApiEndpoint + 'services/authlabor/';
    axios
      .post(URIDelete + confirmAuth)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulAutorizeLabor'));
        setTypeAlert('success');
        setOpenAlert(true);
        getServiceLabors();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (confirmAuth > 0) {
      setOpenConfirm(true);
    }
  }, [confirmAuth]);

  const getServiceLabors = () => {
    axios.get(URI + idService).then(response => {
      let labors = response.data
        .filter(item => item.visibletocustomer == true)
        .map(labor => {
          return {
            idservicelabor: labor.idservicelabor,
            description: '',
            //labor.employee.firstname + ' ' + labor.employee.lastname,
            category: '',
            status: labor.attended,
            comments: '',
            requireauth: labor.requireauth && !labor.isauth,
            price:
              labor.visibletocustomer && !labor.isincluded
                ? Number(labor.price)
                : 0,
            priceNumber:
              labor.visibletocustomer && !labor.isincluded
                ? Number(labor.price)
                : 0,
            title: labor.shortdescription,
            icon: 'fa-solid fa-hammer'
          };
        });
      setLaborsList(labors);
    });
  };
  const getTotalPrice = items => {
    return items
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {i18next.t('label.Labors')}
              </h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {laborsList.length > 0 ? (
            <ListItemCustomer
              data={laborsList}
              setConfirmAuth={setConfirmAuth}
            />
          ) : (
            <span>
              <em>No data was found.</em>
            </span>
          )}
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="flex-between-center">
            <Col xs="auto"></Col>
            <Col xs="auto">
              <h4 className="text-primary mb-0">
                Total {FormatCurrency2Dec(getTotalPrice(laborsList))}
              </h4>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {openConfirm && (
        <ConfirmAction
          message={i18next.t('label.ConfirmAuthorize')}
          title={'Confirm'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={AuthConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default ServiceCustomerLaborsList;
