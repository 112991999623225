import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import signalImg from 'assets/img/icons/signal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import FalconLink from 'components/common/FalconLink';
import SimpleBarReact from 'simplebar-react';
import i18next from 'i18next';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import { FormatCurrency } from 'utils/FormatData';

const SingleItem = ({
  icon,
  title,
  idServiceFailures,
  idCommonFailures,
  description,
  category,
  comments,
  status,
  price,
  setIdCommonFailureService,
  setIdCommonFailureStatus,
  setOpenModalStatus,
  setIdCommonFailure,
  isEditable
}) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative">
      <Row>
        <Col xs={3}>
          <Row>
            <Col xs={2}>
              <FontAwesomeIcon icon={icon} />
            </Col>
            <Col xs={10}>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{category}</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={10}>
              <h5 className="fs--1 text-800">{description}</h5>
              <p className="mb-0 fs--2 text-500">{comments}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={2} style={{ textAlign: 'right' }}>
          <Flex>
            <h4 className="text-primary mb-0">{FormatCurrency(price ?? 0)}</h4>
          </Flex>
          <SoftBadge
            pill
            bg={classNames({
              success: status === 1,
              primary: status === 2,
              warning: status === 3,
              secondary: status === 4,
              info: status === 5
            })}
            className="d-block"
          >
            {status === 1 && `${i18next.t('status.InProccess')}`}
            {status === 2 && `${i18next.t('status.InLocation')}`}
            {status === 3 && `${i18next.t('status.InExternalService')}`}
            {status === 4 && `${i18next.t('status.Canceled')}`}
            {status === 5 && `${i18next.t('status.Completed')}`}
          </SoftBadge>
        </Col>

        <Col xs={3} style={{ textAlign: 'right' }}>
          <>
            {status !== 4 && status !== 5 && status !== 3 && status !== 2 ? (
              <>
                <Button
                  variant="falcon-default"
                  size="sm"
                  disabled={!isEditable}
                  title={i18next.t('label.RejectFailure')}
                  onClick={() => {
                    setIdCommonFailureService(idServiceFailures);
                    setIdCommonFailureStatus('4');
                    setOpenModalStatus(true);
                  }}
                >
                  <FontAwesomeIcon icon="trash-alt" />
                </Button>
              </>
            ) : null}
            {status !== 4 && status !== 2 && status !== 3 && (
              <Button
                variant="falcon-default"
                size="sm"
                disabled={!isEditable}
                title={i18next.t('label.DelayFailure')}
                onClick={() => {
                  setIdCommonFailureService(idServiceFailures);
                  setIdCommonFailureStatus('4');
                  setOpenModalStatus(true);
                }}
              >
                <FontAwesomeIcon icon="clock" />
              </Button>
            )}
            {status !== 4 && status !== 3 && status !== 2 && (
              <Button
                variant="falcon-default"
                disabled={!isEditable}
                title={i18next.t('label.ConfirmFailure')}
                size="sm"
                onClick={() => {
                  setIdCommonFailureService(idServiceFailures);
                  setIdCommonFailure(idCommonFailures);
                  setIdCommonFailureStatus('2');
                  setOpenModalStatus(true);
                }}
              >
                <FontAwesomeIcon icon="check" />
              </Button>
            )}
          </>
        </Col>
      </Row>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const ListItem = ({
  data,
  setIdCommonFailureService,
  setIdCommonFailureStatus,
  setOpenModalStatus,
  setIdCommonFailure,
  isEditable
}) => {
  return (
    <Card className="h-100">
      <Card.Body className="p-0">
        <SimpleBarReact className="ask-analytics">
          <div className="pt-0 px-card">
            {data.map((item, index) => (
              <SingleItem
                key={'key' + index}
                icon={item.icon ?? 'wrench'}
                title={item.title}
                description={item.description}
                category={item.category}
                comments={item.comments}
                status={item.statusId}
                price={item.price}
                idServiceFailures={item.idservicefailures}
                idCommonFailures={item.idcommonfailures}
                setIdCommonFailureService={setIdCommonFailureService}
                setIdCommonFailureStatus={setIdCommonFailureStatus}
                setOpenModalStatus={setOpenModalStatus}
                setIdCommonFailure={setIdCommonFailure}
                isEditable={isEditable}
              />
            ))}
          </div>
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer className="bg-light text-end py-2"></Card.Footer>
    </Card>
  );
};

ListItem.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired
};

export default ListItem;
