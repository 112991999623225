import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import BtnExportToExcell from 'form-components/ExportToExcel';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate, FormatDateToDb } from 'utils/FormatData';
import ExternalLaborsFilters from './Filters/_ExternalLaborsFilters';
import LaborsFilters from './Filters/_LaborsFilters';
import ExternalLaborsReportPdf from './Generic/ExternalLaborsReportPdf';

const URI = ApiEndpoint + 'services/';
const columns = [
  {
    accessor: 'service',
    Header: 'Service',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>{String('0000000' + rowData.row.original.idservice).slice(-7)}</>
      );
    }
  },
  {
    accessor: 'name',
    Header: `${i18next.t('label.Supplier')}`,
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'locationName',
    Header: 'Location',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'vin',
    Header: 'Vin',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'price',
    Header: 'Price',
    headerProps: { style: { minWidth: '70px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'shortdescription',
    Header: 'Labor',
    headerProps: { style: { minWidth: '200px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'datecreate',
    Header: 'Labor Date',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatDate(rowData.row.original.datecreate) || ''}</>;
    }
  }
];

const ExternalLaborsReportList = () => {
  const [locationIdFilter, setLocationIdFilter] = useState(0);
  const [employeeFilter, setEmployeeFilter] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(new Date());
  const [toFilter, setToFilter] = useState(new Date());
  const [services, setServices] = useState([]);
  const [vehicleIdFilter, setVehicleIdFilter] = useState(0);
  const [supplierFilter, setSupplierFilter] = useState(0);
  //mostrar companies /services/filters
  const getServices = async () => {
    const URI2 = ApiEndpoint + 'reports/';
    const res = await axios.post(URI2 + 'externallabors', {
      idcompany: 0,
      idcustomer: customerIdFilter,
      idvehicle: vehicleIdFilter,
      idlocation: locationIdFilter,
      idstatus: idStatusFilter,
      del: FormatDateToDb(fromFilter),
      al: FormatDateToDb(toFilter),
      idemployee: employeeFilter,
      idsupplier: supplierFilter
    });
    console.log(res.data);
    setServices(res.data.length > 0 ? res.data : []);
  };

  return (
    <>
      <ExternalLaborsFilters
        setLocationIdFilter={setLocationIdFilter}
        locationIdFilter={locationIdFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        action={getServices}
        employeeFilter={employeeFilter}
        setEmployeeFilter={setEmployeeFilter}
        supplierFilter={supplierFilter}
        setSupplierFilter={setSupplierFilter}
        setVehicleIdFilter={setVehicleIdFilter}
        vehicleIdFilter={vehicleIdFilter}
      ></ExternalLaborsFilters>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                {services && services.length > 0 && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    transform="shrink-3"
                  >
                    <PDFDownloadLink
                      document={
                        <ExternalLaborsReportPdf
                          period={
                            FormatDate(fromFilter) +
                            ' - ' +
                            FormatDate(toFilter)
                          }
                          reportData={services.map(labor => {
                            return {
                              idservice: String(
                                '0000000' + labor.idservice
                              ).slice(-7),
                              supplier: labor.name,
                              companyName: labor.companyName,
                              company: labor.company,
                              vin: labor.vin,
                              model: labor.model,
                              year: labor.year,
                              locationName: labor.locationName,
                              datecreate: FormatDate(labor.datecreate),
                              recibe: labor.recibe,
                              comments: labor.comments,
                              shortdescription: labor.shortdescription,
                              price: labor.price
                            };
                          })}
                        />
                      }
                      fileName={'ReportExternallabors.pdf'}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Print'
                      }
                    </PDFDownloadLink>
                  </IconButton>
                )}
                {services && services.length > 0 && (
                  <BtnExportToExcell
                    dataReport={services.map(labor => {
                      return {
                        idservice: String('0000000' + labor.idservice).slice(
                          -7
                        ),
                        supplier: labor.name,
                        companyName: labor.companyName,
                        company: labor.company,
                        vin: labor.vin,
                        model: labor.model,
                        year: labor.year,
                        locationName: labor.locationName,
                        datecreate: FormatDate(labor.datecreate),
                        recibe: labor.recibe,
                        comments: labor.comments,
                        shortdescription: labor.shortdescription,
                        price: { v: labor.price, t: 'n' }
                      };
                    })}
                    fileName={'reportlabors.pdf'}
                  ></BtnExportToExcell>
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTableWrapper
            columns={columns}
            data={services}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default ExternalLaborsReportList;
