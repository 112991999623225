import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import AppContext from 'context/Context';
import Customers from 'Catalogs/Administration/Customers';
import DetailCustomer from 'Catalogs/Administration/DetailCustomer';
import CommonFailures from 'Catalogs/Administration/CommonFailures';
import Warehouses from 'Catalogs/Configuration/warehouses';
import SymptomCategories from 'Catalogs/Configuration/SymptomesCategories';
import PartsCategories from 'Catalogs/Configuration/PartsCategories';
import Locations from 'Catalogs/Configuration/Locations';
import Employees from 'Catalogs/Configuration/Employees.js';
import Companies from 'Catalogs/Configuration/Companies';
import Vendors from 'Catalogs/Warehouse/Vendors';
import Suppliers from 'Catalogs/Warehouse/Suppliers';
import NewService from 'Catalogs/NewService/NewService';
import ServicesList from 'Catalogs/Services/ListServices';
import DetailService from 'Catalogs/Services/DetailService';
import PartsCatalog from 'Catalogs/Warehouse/Parts';
import DetailCommonFailure from 'Catalogs/Administration/DetailFailure';
import Users from 'Catalogs/Security/Users';
import ThecnicalUsers from 'Catalogs/Security/ThecnicalUsers';
//import Router from 'next/router';
import Login from 'Auth/Login';
import { useNavigate } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import Logout from 'Catalogs/Security/Logout';
import DashboardByLocation from 'Dashboards/DashboardLocation';
import DashboardByCustomer from 'Dashboards/DashboardsCustomer/Dashboard';
import DetailServiceCustomer from 'Dashboards/DashboardsCustomer/DetailServiceCustomer';

import WelcomePage from 'Auth/Welcome';
import LaborsReportList from 'Reports/LaborsReport';
import ExternalLaborsReportList from 'Reports/ExternalLaborReport';
import PartsReport from 'Reports/Warehouse/PartsReport';
import ServicesReport from 'Reports/ServicesReport';
import VehiclesReport from 'Reports/Customers/VehiclesReport';
import ServicesSummaryReport from 'Reports/ServicesSummaryReport';
import { MDetailService } from 'Catalogs/ServicesMobile/MDetailService';
import ServicesVehicleList from 'Dashboards/SearchVehicle';
import AccountTypes from 'Catalogs/Accounting/AccountTypes';
import BankAccounts from 'Catalogs/Accounting/BankAccounts';
import { useAuth } from 'context/AuthProvider';
import { ProtectedRoute } from 'context/ProtectedRoute';
import ServicePartsReport from 'Reports/ServicePartsReport';
import PaymentsType from 'Catalogs/Accounting/PaymentsType';
import NewPayment from 'Catalogs/Payments/NewPayment';
import PaymentsList from 'Catalogs/Payments/PaymentsList';
const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  const Auth = useAuth();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<AuthSimpleLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/security/Logout" element={<Logout />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          {Auth.isCustomer ? (
            <>
              {/* <Route path="/" element={<WelcomePage />} /> */}
              <Route
                path="/Dashboard/Customers/"
                element={<DashboardByCustomer />}
              />
              <Route
                path="/DetailServiceCustomer/:id"
                element={<DetailServiceCustomer />}
              />
              <Route path="/" element={<DashboardByCustomer />} />
            </>
          ) : Auth.isAlmacenista ? (
            <>
              <Route path="/Warehouse/Suppliers" element={<Suppliers />} />
              <Route path="/Warehouse/Parts" element={<PartsCatalog />} />
              <Route path="/" element={<WelcomePage />} />
            </>
          ) : Auth.isEmployee ? (
            <>
              {/*Dashboard*/}
              <Route path="/" element={<WelcomePage />} />
              <Route
                path="/Administration/CommonFailures"
                element={<CommonFailures />}
              />
              <Route
                path="CommonFailureDetail/:id"
                element={<DetailCommonFailure />}
              />
              <Route
                path="/Configuration/warehouses"
                element={<Warehouses />}
              />
              <Route
                path="/Configuration/partscategories"
                element={<PartsCategories />}
              />

              <Route path="/Warehouse/Suppliers" element={<Suppliers />} />
              <Route path="/Warehouse/Parts" element={<PartsCatalog />} />
              <Route path="/Services/NewService" element={<NewService />} />
              <Route
                key={'services'}
                path="/Services/Services"
                element={<ServicesList />}
              />
              <Route path="/ServiceDetail/:id" element={<DetailService />} />
              {/* <Route path="/Services/NewQuickService"  /> */}
              <Route
                path="/Services/DashboardLocations/:idLocation"
                element={<DashboardByLocation />}
              />
            </>
          ) : (
            <>
              {/*Dashboard*/}
              <Route path="/" element={<WelcomePage />} />
              <Route path="/Administration/Customers" element={<Customers />} />
              <Route
                path="/Administration/CommonFailures"
                element={<CommonFailures />}
              />
              <Route path="CustomerDetail/:id" element={<DetailCustomer />} />
              <Route
                path="CommonFailureDetail/:id"
                element={<DetailCommonFailure />}
              />
              <Route
                path="/Configuration/warehouses"
                element={<Warehouses />}
              />
              <Route
                path="/Configuration/partscategories"
                element={<PartsCategories />}
              />
              <Route
                path="/Configuration/SymptomsCategories"
                element={<SymptomCategories />}
              />
              <Route path="/Configuration/Locations" element={<Locations />} />
              <Route path="/Configuration/Employees" element={<Employees />} />
              <Route path="/Configuration/Companies" element={<Companies />} />
              <Route path="/Configuration/Vendors" element={<Vendors />} />
              <Route path="/Warehouse/Suppliers" element={<Suppliers />} />
              <Route path="/Warehouse/Parts" element={<PartsCatalog />} />
              <Route path="/Services/NewService" element={<NewService />} />
              <Route
                key={'services2'}
                path="/Services/Services"
                element={<ServicesList />}
              />
              <Route
                path="/Services/SearchVehicle"
                element={<ServicesVehicleList />}
              />
              <Route path="/ServiceDetail/:id" element={<DetailService />} />
              <Route path="/MServiceDetail/:id" element={<MDetailService />} />
              <Route
                path="/DetailServiceCustomer/:id"
                element={<DetailServiceCustomer />}
              />
              <Route
                path="/Services/DashboardLocations/:idLocation"
                element={<DashboardByLocation />}
              />
              <Route
                path="/Dashboard/Customers/"
                element={<DashboardByCustomer />}
              />
              <Route path="/Security/Users" element={<Users />} />
              <Route
                path="/Security/ThecnicalUsers"
                element={<ThecnicalUsers />}
              />
              <Route path="/Reports/Labors/" element={<LaborsReportList />} />
              <Route
                path="/Reports/ExternalLabors/"
                element={<ExternalLaborsReportList />}
              />
              <Route path="/Reports/Parts/" element={<PartsReport />} />
              <Route path="/Reports/Services/" element={<ServicesReport />} />
              <Route
                path="/Reports/ServiceParts/"
                element={<ServicePartsReport />}
              />
              <Route
                path="/Reports/Summary/"
                element={<ServicesSummaryReport />}
              />{' '}
              <Route path="/Reports/Vehicles/" element={<VehiclesReport />} />
              <Route
                path="/Accounting/AccountTypes"
                element={<AccountTypes />}
              />
              <Route
                path="/Accounting/BankAccounts"
                element={<BankAccounts />}
              />
              <Route
                path="/Accounting/PaymentTypes"
                element={<PaymentsType />}
              />
              <Route path="/Accounting/Payments" element={<PaymentsList />} />
              <Route path="/Accounting/NewPayment" element={<NewPayment />} />
            </>
          )}
        </Route>
      </Routes>
    </>
  );
};

export default Layout;
