import i18next from 'i18next';
import { Button, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormInputText } from './FormInputText';

const ConfirmAction = ({
  open,
  handleClose,
  title,
  message,
  ConfirmAction,
  confirmValue,
  setConfirmValue,
  isValue = false
}) => {
  const onChange = event => {
    setConfirmValue(event.target.value);
  };
  const { control } = useForm({
    mode: 'onBlur'
  });
  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          handleClose(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>{message}</Row>
          {isValue && (
            <Col>
              <Form.Group className="mb-3" controlId="confirmValue">
                <FormInputText
                  label={i18next.t('label.Hours')}
                  changeHandler={onChange}
                  control={control}
                  name={'confirmValue'}
                  defaultValue={confirmValue}
                ></FormInputText>
              </Form.Group>
            </Col>
          )}{' '}
          <Row></Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              ConfirmAction(false);
              handleClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              ConfirmAction(true);
              handleClose(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmAction;
