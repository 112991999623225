import axios from 'axios';
import Flex from 'components/common/Flex';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { ApiEndpoint } from 'utils/ApiEndPont';
import 'utils/_DatePicker.css';
const PaymentsFilters = ({
  customerIdFilter,
  setCustomerIdFilter,
  setIdStatusFilter,
  idStatusFilter,
  fromFilter,
  setFromFilter,
  toFilter,
  setToFilter,
  filterFolio,
  setFilterFolio,
  action
}) => {
  const [customers, setCustomers] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const getCustomers = async () => {
    const URICustomers = ApiEndpoint + 'customers/';
    const res = await axios.get(URICustomers);
    setCustomers(res.data);
  };

  useEffect(() => {
    const URI = ApiEndpoint + 'servicestatus/';
    axios(URI).then(({ data }) => {
      const result = data.map(item => {
        return { label: item.status, value: item.idservicestatus };
      });
      setStatusList(result);
    });
  }, []);

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filters</Accordion.Header>
        <Accordion.Body>
          <Form as={Row} className="gx-2">
            <Row>
              <Col xs="4">
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {i18next.t('label.Folio')}
                  </InputGroup.Text>
                  <Form.Control
                    value={filterFolio || ''}
                    name={filterFolio}
                    placeholder={i18next.t('label.Folio')}
                    onChange={event => {
                      setFilterFolio(event.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col xs="4">
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {i18next.t('label.Customer')}
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select"
                    size="sm"
                    name="customerIdFilter"
                    onChange={selectedOption => {
                      setCustomerIdFilter(`${selectedOption.target.value}`);
                    }}
                    value={`${customerIdFilter}`}
                  >
                    <option key={'location_0'} value={0}>
                      {i18next.t('label.SelectSomeValue')}
                    </option>
                    {!!customers?.length &&
                      customers.map(customer => (
                        <option
                          key={customer.idcustomer}
                          value={customer.idcustomer}
                        >
                          {customer.shortname}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </Col>

              <Col xs="4">
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {i18next.t('label.Status')}
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select"
                    size="sm"
                    name="status"
                    onChange={selectedOption => {
                      setIdStatusFilter(`${selectedOption.target.value}`);
                    }}
                    value={`${idStatusFilter}`}
                  >
                    <option key={'location_0'} value={0}>
                      {i18next.t('label.SelectSomeValue')}
                    </option>
                    {!!statusList?.length &&
                      statusList.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col xs="4">
                <Row>
                  <Col style={{ alignContent: 'right' }}>
                    {i18next.t('label.From')}
                  </Col>
                  <Col>
                    <ReactDatePicker
                      className="mb-3"
                      selected={fromFilter}
                      size="sm"
                      onChange={date => setFromFilter(date)}
                      placeholderText="Select Date"
                      dateFormat={'MM/dd/yyyy'}
                    />
                  </Col>
                </Row>
              </Col>
              <Row></Row>
              <Col xs="4">
                <Row>
                  <Col style={{ alignContent: 'right' }}>
                    {i18next.t('label.To')}
                  </Col>
                  <Col>
                    <ReactDatePicker
                      className="mb-3"
                      selected={toFilter}
                      size="sm"
                      onChange={date => setToFilter(date)}
                      placeholderText={i18next.t('label.To')}
                      dateFormat={'MM/dd/yyyy'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="4">
                <Col style={{ alignContent: 'left' }}></Col>
                <Col>
                  <Button
                    type="button"
                    className="mb-5"
                    onClick={() => action()}
                    color="primary"
                    size="sm"
                  >
                    {i18next.t('label.Filter')}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default PaymentsFilters;
