import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import signalImg from 'assets/img/icons/signal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import FalconLink from 'components/common/FalconLink';
import SimpleBarReact from 'simplebar-react';
import i18next from 'i18next';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';

const SingleItem = ({
  icon,
  title,
  description,
  date,
  company,
  status,
  uniqueId,
  isCustomer = false
}) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={1}>
              <FontAwesomeIcon icon={icon} className="text-primary" />
            </Col>
            <Col xs={11}>
              <Row>
                <Col>
                  <Link
                    to={'/ServiceDetail/' + uniqueId}
                    className="stretched-link text-decoration-none"
                  >
                    <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link
                    to={
                      (isCustomer
                        ? '/DetailServiceCustomer/'
                        : '/ServiceDetail/') + uniqueId
                    }
                    className="stretched-link text-decoration-none"
                  >
                    <h5 className="fs--1 text-600 mb-0 ps-3">{company}</h5>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={10}>
              <h5 className="fs--1 text-800">{description}</h5>
              <p className="mb-0 fs--2 text-500">{date}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={2}>
          <SoftBadge
            pill
            bg={classNames({
              success: status === 1,
              primary: status === 2,
              warning: status === 3,
              secondary: status === 4,
              info: status === 5,
              danger: status === 6
            })}
            className="d-block"
          >
            {status === 1 && `${i18next.t('status.InProccess')}`}
            {status === 2 && `${i18next.t('status.InLocation')}`}
            {status === 3 && `${i18next.t('status.InExternalService')}`}
            {status === 4 && `${i18next.t('status.Canceled')}`}
            {status === 5 && `${i18next.t('status.Completed')}`}
            {status === 6 && `${i18next.t('status.PendingComplete')}`}
          </SoftBadge>
        </Col>
      </Row>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const Intelligence = ({ data, isCustomer }) => {
  return (
    <Card className="h-100">
      <Card.Header as={Flex} alignItems="center">
        <img src={signalImg} alt="intelligence" height={35} className="me-2" />
        <h5 className="fs-0 fw-normal text-800 mb-0">
          {i18next.t('label.Services')}
        </h5>
      </Card.Header>
      <Card.Body className="p-0">
        <SimpleBarReact className="ask-analytics">
          <div className="pt-0 px-card">
            {data.map(item => (
              <SingleItem
                key={item.title}
                icon={item.icon}
                title={item.title}
                description={item.description}
                date={item.date}
                company={item.company}
                status={item.status}
                isCustomer={isCustomer}
                uniqueId={item.uniqueId}
              />
            ))}
          </div>
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer className="bg-light text-end py-2"></Card.Footer>
    </Card>
  );
};

Intelligence.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired
};

export default Intelligence;
