import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PreviewPrint from 'Catalogs/Formatos/PreviewPrint';
import CreateService from 'Catalogs/NewService/CreateService';
import axios from 'axios';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ConfirmAction from 'form-components/ConfirmationModal';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MyModal from 'shared/Modal';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate } from 'utils/FormatData';

const ListServicesComponent = ({ services, refreshServices }) => {
  const [openPrint, setOpenPrint] = useState(false);
  const [confirmPending, setConfirmPending] = useState(false);
  const [serviceToPrint, setServiceToPrint] = useState(null);
  const [serviceToPendingComplete, setServiceToPendingComplete] =
    useState(null);
  const [idServiceToPrint, setIdServiceToPrint] = useState(0);
  const [openModalService, setOpenModalService] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const handleCloseConfirm = () => {
    setConfirmPending(false);
  };
  const handleCloseService = () => {
    setOpenModalService(false);
  };
  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const ChangeConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }

    const URI = ApiEndpoint + 'services/statuswithuid/';
    axios
      .put(URI + serviceToPendingComplete, {
        idservice: serviceToPendingComplete,
        idStatus: 6
      })
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        refreshServices();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      accessor: 'idservice',
      Header: 'Services', //Header: 'Info'
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        return (
          <>
            <Link to={'/ServiceDetail/' + rowData.row.original.serviceid}>
              <strong>
                #COT-
                {String('0000000' + rowData.row.original.idservice).slice(-7)}
              </strong>
            </Link>
            <p className="mb-0 text-500">
              Received: {FormatDate(rowData.row.original.datereceived) || ''}
            </p>
            <p className="mb-0 text-500">
              {rowData.row.original.idservicestatus === 5 &&
                `Finished: ${FormatDate(rowData.row.original.datefinished)}`}
              {rowData.row.original.idservicestatus != 5 &&
                `${rowData.row.original.days || ''} Days`}
            </p>
          </>
        );
      }
    },
    {
      accessor: 'customer',
      Header: 'Customer', //'Customer'
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <>
            <strong>{rowData.row.original.company}</strong>
            <strong>
              {rowData.row.original.vin}-{rowData.row.original.make}-
              {rowData.row.original.model}
            </strong>
            <p className="mb-0 text-500">
              {rowData.row.original.address}- {rowData.row.original.shortname}
            </p>
          </>
        );
      }
    },
    {
      accessor: 'locationName',
      Header: 'Location', //'Location',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <>
            <strong>{rowData.row.original.locationName}</strong>
            <p className="mb-0 text-500">{rowData.row.original.recibe}</p>
            <p className="mb-0 text-500">
              {rowData.row.original.thecnician &&
                rowData.row.original.thecnician != '  ' &&
                `Asigned to: ${rowData.row.original.thecnician}`}
            </p>
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const status = rowData.row.original.idservicestatus;
        return (
          <>
            <SoftBadge
              pill
              bg={classNames({
                success: status === 1,
                primary: status === 2,
                warning: status === 3,
                secondary: status === 4,
                info: status === 5,
                danger: status === 6
              })}
              className="d-block"
            >
              {status === 1 && `${i18next.t('status.InProccess')}`}
              {status === 2 && `${i18next.t('status.InLocation')}`}
              {status === 3 && `${i18next.t('status.InExternalService')}`}
              {status === 4 && `${i18next.t('status.Canceled')}`}
              {status === 5 && `${i18next.t('status.Completed')}`}
              {status === 6 && `${i18next.t('status.PendingComplete')}`}
            </SoftBadge>
          </>
        );
      }
    },
    {
      accessor: 'Imprimir',
      Header: 'Imprimir',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const currentIdService = rowData.row.original.serviceid;
        const currentId = rowData.row.original.idservice;
        const status = rowData.row.original.idservicestatus;
        return (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{i18next.t('label.Edit')}</Tooltip>}
          >
            <>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setServiceToPrint(`${currentIdService}`);
                  setIdServiceToPrint(currentId);
                  setOpenPrint(true);
                }}
              >
                <FontAwesomeIcon icon="print" /> Print
              </Button>
              {status != 4 && status != 5 && status != 6 && (
                <Button
                  variant="falcon-default"
                  size="sm"
                  onClick={() => {
                    setServiceToPendingComplete(`${currentIdService}`);
                    setConfirmPending(true);
                  }}
                >
                  <FontAwesomeIcon icon="print" /> Pending Complete
                </Button>
              )}
            </>
          </OverlayTrigger>
        );
      }
    }
  ];
  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={services}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <ServicesTableHeader
              table
              dataReport={services}
              setOpenModalService={setOpenModalService}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {openModalService && (
        <MyModal
          id="id_myModalService"
          closeModal={handleCloseService}
          title={i18next.t('label.NewService')}
          openModal={openModalService}
        >
          <CreateService closeModal={handleCloseService} />
        </MyModal>
      )}
      {openPrint && (
        <MyModal
          style={{ width: '100%' }}
          openModal={openPrint}
          closeModal={handleClosePrint}
          title="Service"
          size="xl"
        >
          <PreviewPrint
            idService={serviceToPrint}
            currentId={idServiceToPrint}
            closeModal={handleClosePrint}
          ></PreviewPrint>
        </MyModal>
      )}
      {confirmPending && (
        <ConfirmAction
          message={i18next.t('message.ConfirmPendingService')}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={confirmPending}
          ConfirmAction={ChangeConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default ListServicesComponent;
