import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import MyModal from 'shared/Modal';
import { ApiEndpoint, FilesEndpoint } from 'utils/ApiEndPont';
import pdfImg from '../../assets/img/icons/pdf.png';
import genericFile from '../../assets/img/icons/genericFile.png';
import { EditServiceContext } from 'context/Context';
import FilesPreviewList from 'components/_FilesPreviewList';

const ServiceCustomerFilesList = ({ idService, setOpenModal, serviceGuid }) => {
  const {
    openAlert,
    setOpenAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage,
    setHandleCloseAlert
  } = useContext(EditServiceContext);
  const [serviceFiles, setServiceFiles] = useState([]);
  const { control } = useForm();
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [file, setFile] = useState(null);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [refreshFiles, setRefreshFiles] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [idToChangeVisibility, setIdToChangeVisibility] = useState(false);
  const [idPreToChangeVisibility, setIdPreToChangeVisibility] = useState(false);
  const [fileVisibility, setFileVisibility] = useState(false);
  const URI = ApiEndpoint + 'services/files/';
  const URIFiles = ApiEndpoint + 'services/getfile';
  const handleClose = () => {
    setOpenModalFile(false);
    getServiceFiles();
    setConfirmVisible(false);
  };

  useEffect(() => {
    getServiceFiles();
  }, []);

  useEffect(() => {
    if (idToChangeVisibility && idToChangeVisibility > 0) {
      const URIVis = ApiEndpoint + 'services/filevisibility/';
      axios
        .put(URIVis + idToChangeVisibility, {
          visibilitycustomer: fileVisibility
        })
        .then(response => {
          setRefreshFiles(true);
        });
    }
  }, [idToChangeVisibility]);

  useEffect(() => {
    if (refreshFiles) {
      getServiceFiles();
      setRefreshFiles(false);
    }
  }, [refreshFiles]);

  const getServiceFiles = () => {
    console.log(URI + idService);
    axios.get(URI + idService).then(response => {
      let details = response.data.filter(item => item.visibilitycustomer);
      setServiceFiles(details);
    });
  };

  const handleDownload = (url, filename) => {
    const config = { responseType: 'blob', path: url };
    // axios.post(URIFiles, config).then(response => {
    //     new File(response, filename);
    // });

    axios({
      url: URIFiles,
      method: 'POST',
      data: config,
      responseType: 'blob' // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <FilesPreviewList files={serviceFiles} />
      {/* <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {i18next.t('label.AttachedFiles')}
              </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions"></div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <div class="table-responsive scrollbar">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">{i18next.t('label.FileName')}</th>
                  <th scope="col"> </th>
                </tr>
              </thead>
              <tbody>
                {serviceFiles.map((file, index) => (
                  <>
                    <tr class="hover-actions-trigger">
                      <td class="align-middle text-nowrap">
                        <div class="d-flex align-items-center">
                          <div class="avatar avatar-xl">
                            {(() => {
                              switch (file.filetype) {
                                case 'image/png':
                                case 'image/jpeg':
                                  return (
                                    <img
                                      className="img-thumbnail"
                                      src={
                                        FilesEndpoint +
                                        file.path +
                                        file.filename
                                      }
                                      alt=""
                                    />
                                  );
                                case 'application/pdf':
                                  return (
                                    <img
                                      className="img-thumbnail"
                                      src={pdfImg}
                                      alt=""
                                    />
                                  );
                                default:
                                  return (
                                    <img
                                      className="rounded-circle"
                                      src={genericFile}
                                      alt=""
                                    />
                                  );
                              }
                            })()}
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6 className="mb-1">
                          <span className="  fw-semi-bold">
                            {file.filename}
                          </span>
                        </h6>
                        <div className="fs--1">
                          <span className="fw-semi-bold">
                            {file.description}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="falcon-default"
                          size="sm"
                          onClick={() => {
                            handleDownload(
                              file.path + file.filename,
                              file.filename
                            );
                          }}
                        >
                          <FontAwesomeIcon icon="cloud-download-alt" />
                        </Button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>{' '}
        </Card.Body>
      </Card> */}
    </>
  );
};

export default ServiceCustomerFilesList;
