import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import i18next from 'i18next';
import classNames from 'classnames';
import { FormatCurrency, FormatCurrency2Dec } from 'utils/FormatData';
import SoftBadge from 'components/common/SoftBadge';

const SingleItem = ({
  icon,
  title,
  description,
  category,
  comments,
  status,
  price,
  requireAuth,
  id,
  confirmAuth
}) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative">
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={10}>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{category}</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={2} sx={{ textAlign: 'right' }}>
          <Flex>
            <h4 className="text-primary mb-0">
              {FormatCurrency2Dec(price ?? 0)}
            </h4>
          </Flex>
        </Col>
        <Col xs={2}>
          {
            <SoftBadge
              pill
              bg={classNames({
                success: status,
                warning: requireAuth,
                primary: !requireAuth && !status
              })}
              className="d-block"
            >
              {status && `${i18next.t('status.InProccess')}`}
              {requireAuth
                ? `${i18next.t('label.RequireAuth')}`
                : `${i18next.t('status.InLocation')}`}
            </SoftBadge>
          }
        </Col>
        <Col xs={2} textAlign="right">
          <div className="vr" />
          <h4 className="text-primary mb-0">
            {requireAuth && (
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  confirmAuth(id);
                }}
              >
                <FontAwesomeIcon icon="fa-check-circle" />
              </Button>
            )}
          </h4>
        </Col>
      </Row>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const ListItemCustomer = ({ data, setConfirmAuth }) => {
  console.log('data labors 2');
  console.log(data);
  return (
    <SimpleBarReact className="ask-analytics">
      <div className="pt-0 px-card">
        {data.map(item => (
          <SingleItem
            key={item.title}
            icon={item.icon}
            title={item.title}
            description={item.description}
            category={item.category}
            comments={item.comments}
            status={item.status}
            price={item.price}
            requireAuth={item.requireauth}
            id={item.idservicelabor}
            confirmAuth={setConfirmAuth}
          />
        ))}
      </div>
    </SimpleBarReact>
  );
};

ListItemCustomer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired
};

export default ListItemCustomer;
