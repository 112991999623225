import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import GenericTableHeader from '../../form-components/TableHeaders/GenericTableHeader';
import MyModal from 'shared/Modal';
import EditVehicle from './_EditVehicle';
import { ApiEndpoint } from 'utils/ApiEndPont';
import AlertNotification from 'form-components/AlertNotification';
import ConfirmAction from 'form-components/ConfirmationModal';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
const URI = ApiEndpoint + 'customervehicles/';
const CustomerVehicleList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  let [idCustomer, setIdCustomer] = useState(0);
  let [idVehicle, setIdVehicle] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [idVehicleToDelete, setIdVehicleToDelete] = useState(0);

  const columns = [
    {
      accessor: 'vin',
      Header: `${i18next.t('label.Vin')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'license',
      Header: `${i18next.t('label.License')}`,
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    },
    {
      accessor: 'year',
      Header: `${i18next.t('label.Year')}`
    },
    {
      accessor: 'make',
      Header: `${i18next.t('label.Make')}`
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        let idVehicle = rowData.row.original.idVehicle;
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{i18next.t('label.Edit')}</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdVehicle(idVehicle);
                  setOpenModal(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{i18next.t('label.Delete')}</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setIdVehicleToDelete(idVehicle);
                  setOpenConfirm(true);
                }}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </OverlayTrigger>
          </>
        );
      }
    }
  ];

  let { id } = useParams();
  const handleClose = () => {
    setOpenModal(false);
    getVehicles();
  };

  //mostrar companies
  const getVehicles = async () => {
    setIdCustomer(id);
    const res = await axios.get(URI + id);
    setVehicles(res.data);
    console.log(res.data);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    getVehicles();
  };

  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    const URIDelete = ApiEndpoint + 'vehicles/';
    axios
      .delete(URIDelete + idVehicleToDelete)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        getVehicles();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <Card className="mb-3">
      <GenericTableHeader
        label={i18next.t('label.vehicles')}
        newFunction={() => {
          setIdVehicle(0);
          setOpenModal(true);
        }}
      />

      <Card.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={vehicles}
          sortable
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Header>
              <GenericTableHeader
                label={i18next.t('label.Parts')}
                newFunction={() => {
                  setIdVehicle(0);
                  setOpenModal(true);
                }}
                dataReport={vehicles}
                exportToExcel={true}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <Row className="flex-end-center mb-3">
                <Col xs="auto" sm={6} lg={4}>
                  <AdvanceTableSearchBox table />
                </Col>
              </Row>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
              <AdvanceTableFooter
                rowCount={vehicles.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
                pageSize={20}
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Card.Body>
      {openModal && (
        <MyModal
          id="id_myModal"
          title={
            (idVehicle > 0 ? i18next.t('label.Edit') : i18next.t('label.Add')) +
            ' ' +
            i18next.t('label.Vehicle')
          }
          openModal={openModal}
          closeModal={handleClose}
        >
          <EditVehicle
            idVehicle={idVehicle}
            idCustomer={idCustomer}
            closeModal={handleClose}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          />
        </MyModal>
      )}
      {openAlert && (
        <AlertNotification
          open={openAlert}
          handleClose={handleCloseAlert}
          type={typeAlert}
          message={alertMessage}
        />
      )}
      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
    </Card>
  );
};
CustomerVehicleList.propTypes = {};

export default CustomerVehicleList;
