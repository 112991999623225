import axios from 'axios';
import { useAuth } from 'context/AuthProvider';
import { EditServiceContext } from 'context/Context';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';
const URI = ApiEndpoint + 'servicestatus/';
const EditAsignService = ({ service, closeModal }) => {
  const Auth = useAuth();
  const [thecnicianList, setthecnicianList] = useState([]);
  const [idThec, setIdThec] = useState([]);
  const [isThecnician, setIsThecnician] = useState(false);
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const {
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idThec: 0
    }
  });

  useEffect(() => {
    const userData = Auth.getSessionData();
    if (userData.idemployee != null && userData.idemployee > 0) {
      setIdThec(userData.idemployee);
      setIsThecnician(true);
    } else {
      const uriEmployees = ApiEndpoint + 'employees/';
      axios(uriEmployees).then(({ data }) => {
        const employeeslist = data.map(employee => {
          return {
            value: `${employee.idemployee}`,
            label: `${employee.firstname} ${employee.lastname}`
          };
        });
        setthecnicianList(employeeslist);
      });
      setIsThecnician(false);
    }
  }, []);

  const onSubmit = (data, e) => {
    if (idThec == 0) {
      return;
    }
    if (service.idservice > 0) {
      const URIStatus = ApiEndpoint + 'services/asign/';
      axios
        .put(URIStatus + service.idservice, {
          idemployee: idThec
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  return (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              {!isThecnician ? (
                <Form.Group className="mb-3" controlId="status">
                  <Form.Label>{i18next.t('label.Thecnician')}</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    name="thecnician"
                    style={{ minWidth: '250px' }}
                    onChange={selectedOption => {
                      setIdThec(`${selectedOption.target.value}`);
                    }}
                    value={`${idThec}`}
                  >
                    <option key={'thec_0'} value={0}>
                      {i18next.t('label.SelectSomeValue')}
                    </option>
                    {!!thecnicianList?.length &&
                      thecnicianList.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <>Asign this service to myself</>
              )}
            </Col>
          </Row>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">
            {i18next.t('label.Asign')}
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            {i18next.t('label.Cancel')}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditAsignService;
