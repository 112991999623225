import i18next from 'i18next';
import React from 'react';
import { Table } from 'react-bootstrap';
import { FormatCurrency2Dec } from 'utils/FormatData';

const ServiceReportSummary = ({ dataInfo }) => {
  console.log('dataInfo aaa');
  console.log(dataInfo);
  return (
    <div className="table-responsive fs--1">
      <Table responsive striped hover style={{ width: '100%' }}>
        <thead>
          <tr>
            <th scope="col" st>
              {i18next.t('label.PostedOrderSales')}
            </th>
            <th scope="col">{i18next.t('label.Taxable')}</th>
            <th scope="col">{i18next.t('label.NoTaxable')}</th>
            <th scope="col">{i18next.t('label.Total')}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="align-middle">
            <td style={{ width: '28%' }}>{i18next.t('label.Parts')}</td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.PartsTaxable)}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.PartsNoTaxable)}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.sumParts)}
            </td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}>{i18next.t('label.Labors')}</td>
            <td className="text-nowrap">{FormatCurrency2Dec(0)}</td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.Labors)}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.Labors)}
            </td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}>{i18next.t('label.LaborDiscount')}</td>
            <td className="text-nowrap">{FormatCurrency2Dec(0)}</td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.LaborsDiscount)}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.LaborsDiscount)}
            </td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}>{i18next.t('label.Tires')}</td>
            <td className="text-nowrap">{FormatCurrency2Dec(0)}</td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.PartsTire)}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.PartsTire)}
            </td>
          </tr>{' '}
          <tr className="align-middle">
            <td style={{ width: '28%' }}>
              <hr />
            </td>
            <td className="text-nowrap">
              <hr />
            </td>
            <td className="text-nowrap">
              <hr />
            </td>
            <td className="text-nowrap">
              <hr />
            </td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}>{i18next.t('label.Subtotal')}</td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.PartsTaxable)}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(
                Number(dataInfo.Labors) +
                  Number(dataInfo.PartsTire) +
                  Number(dataInfo.LaborsDiscount) +
                  Number(dataInfo.PartsNoTaxable)
              )}
            </td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.Total)}
            </td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}></td>
            <td className="text-nowrap"></td>
            <td className="text-nowrap">+ {i18next.t('label.Tax')}</td>
            <td className="text-nowrap">{FormatCurrency2Dec(0)}</td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}></td>
            <td className="text-nowrap"></td>

            <td className="text-nowrap">
              <hr />
            </td>
            <td className="text-nowrap">
              <hr />
            </td>
          </tr>
          <tr className="align-middle">
            <td style={{ width: '28%' }}></td>
            <td className="text-nowrap"></td>
            <td className="text-nowrap">+ {i18next.t('label.TotalSalesW')}</td>
            <td className="text-nowrap">
              {FormatCurrency2Dec(dataInfo.Total)}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ServiceReportSummary;
