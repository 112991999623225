import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import BtnExportToExcell from 'form-components/ExportToExcel';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate, FormatDateToDb } from 'utils/FormatData';
import LaborsFilters from '../Filters/_LaborsFilters';
import PartsFilters from '../Filters/_PartsFilters';
import LaborsReportPdf from '../Generic/LaborsReportPdf';
import PartsReportPdf from '../Generic/PartsReportPdf';
import PartsReportSummaryPdf from '../Generic/PartSummaryReportPdf';

const URI = ApiEndpoint + 'services/';
const columns = [
  {
    accessor: 'service',
    Header: 'Service',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>{String('0000000' + rowData.row.original.idservice).slice(-7)}</>
      );
    }
  },
  {
    accessor: 'firstname',
    Header: `${i18next.t('label.Name')}`,
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>
          <strong>
            {rowData.row.original.firstname} {rowData.row.original.lastname}
          </strong>
        </>
      );
    }
  },
  {
    accessor: 'partcode',
    Header: 'Part Code',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'description',
    Header: 'Part',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'category',
    Header: 'Category',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'warehousename',
    Header: 'Warehouse',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'vin',
    Header: 'Vin',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'quantity',
    Header: 'Quantity',
    headerProps: { style: { minWidth: '70px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'price',
    Header: 'Price',
    headerProps: { style: { minWidth: '70px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'total',
    Header: 'Total',
    headerProps: { style: { minWidth: '70px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'request',
    Header: 'Request Date',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatDate(rowData.row.original.request) || ''}</>;
    }
  }
];

const columnsSummary = [
  {
    accessor: 'partnumber',
    Header: 'Part Number',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'partcode',
    Header: 'Part Code',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'description',
    Header: 'Part',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'category',
    Header: 'Category',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'quantity',
    Header: 'Quantity',
    headerProps: { style: { minWidth: '70px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'total',
    Header: 'Total',
    headerProps: { style: { minWidth: '70px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  }
];

const PartsReport = () => {
  const [locationIdFilter, setLocationIdFilter] = useState(0);
  const [employeeFilter, setEmployeeFilter] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(new Date());
  const [onlySummary, setOnlySummary] = useState(0);
  const [toFilter, setToFilter] = useState(new Date());
  const [services, setServices] = useState([]);
  const [idPartCategoryFilter, setIdPartCategoryFilter] = useState(0);
  const [idWarehouseFilter, setIdWarehouseFilter] = useState(0);
  const [dataReport, setDataReport] = useState([]);
  const [vehicleIdFilter, setVehicleIdFilter] = useState(0);
  //mostrar companies /services/filters
  const getServices = async () => {
    const URI2 = ApiEndpoint + 'reports/';
    if (onlySummary) {
      const res = await axios.post(URI2 + 'partssummary', {
        idcompany: 0,
        idcustomer: customerIdFilter,
        idvehicle: vehicleIdFilter,
        idlocation: locationIdFilter,
        idstatus: idStatusFilter,
        del: FormatDateToDb(fromFilter),
        al: FormatDateToDb(toFilter),
        idemployee: employeeFilter,
        idwarehouse: idWarehouseFilter,
        idcategory: idPartCategoryFilter
      });
      setServices(res.data.length > 0 ? res.data : []);
    } else {
      const res = await axios.post(URI2 + 'parts', {
        idcompany: 0,
        idcustomer: customerIdFilter,
        idvehicle: vehicleIdFilter,
        idlocation: locationIdFilter,
        idstatus: idStatusFilter,
        del: FormatDateToDb(fromFilter),
        al: FormatDateToDb(toFilter),
        idemployee: employeeFilter,
        idwarehouse: idWarehouseFilter,
        idcategory: idPartCategoryFilter
      });
      setServices(res.data.length > 0 ? res.data : []);
    }
  };

  useEffect(() => {
    setServices([]);
  }, [onlySummary]);

  return (
    <>
      <PartsFilters
        setLocationIdFilter={setLocationIdFilter}
        locationIdFilter={locationIdFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        action={getServices}
        employeeFilter={employeeFilter}
        setEmployeeFilter={setEmployeeFilter}
        idPartCategoryFilter={idPartCategoryFilter}
        setIdPartCategoryFilter={setIdPartCategoryFilter}
        idWarehouseFilter={idWarehouseFilter}
        setIdWarehouseFilter={setIdWarehouseFilter}
        onlySummary={onlySummary}
        setOnlySummary={setOnlySummary}
        setVehicleIdFilter={setVehicleIdFilter}
        vehicleIdFilter={vehicleIdFilter}
      ></PartsFilters>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                {services && services.length > 0 && !onlySummary && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    transform="shrink-3"
                  >
                    <PDFDownloadLink
                      document={
                        <PartsReportPdf
                          period={
                            FormatDate(fromFilter) +
                            ' - ' +
                            FormatDate(toFilter)
                          }
                          reportData={services.map(part => {
                            return {
                              idservice: String(
                                '0000000' + part.idservice
                              ).slice(-7),
                              employenumber: part.employenumber,
                              name: part.firstname + ' ' + part.lastname,
                              customer: part.company,
                              vin: part.vin,
                              locationName: part.locationName,
                              datecreate: FormatDate(part.request),
                              warehouse: part.warehousename,
                              partnumber: part.partnumber,
                              partcode: part.partcode,
                              description: part.description,
                              category: part.category,
                              chargetocustomer: part.chargetocustomer,
                              quantity: part.quantity,
                              serialnumber: part.serialnumber,
                              price: part.price,
                              total: part.total
                            };
                          })}
                        />
                      }
                      fileName={'reportlabors.pdf'}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Print'
                      }
                    </PDFDownloadLink>
                  </IconButton>
                )}
                {services && services.length > 0 && onlySummary && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    transform="shrink-3"
                  >
                    <PDFDownloadLink
                      document={
                        <PartsReportSummaryPdf
                          period={
                            FormatDate(fromFilter) +
                            ' - ' +
                            FormatDate(toFilter)
                          }
                          reportData={services.map(part => {
                            return {
                              partnumber: part.partnumber,
                              partcode: part.partcode,
                              description: part.description,
                              category: part.category,
                              quantity: part.quantity,
                              total: part.total
                            };
                          })}
                        />
                      }
                      fileName={'reportlabors.pdf'}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Print'
                      }
                    </PDFDownloadLink>
                  </IconButton>
                )}
                {services && services.length > 0 && onlySummary && (
                  <BtnExportToExcell
                    dataReport={services.map(part => {
                      return {
                        partnumber: part.partnumber,
                        partcode: part.partcode,
                        description: part.description,
                        category: part.category,
                        quantity: part.quantity,
                        total: { v: part.total, t: 'n' }
                      };
                    })}
                    fileName={'reportparts.pdf'}
                  ></BtnExportToExcell>
                )}
                {services && services.length > 0 && !onlySummary && (
                  <BtnExportToExcell
                    dataReport={services.map(part => {
                      return {
                        idservice: String('0000000' + part.idservice).slice(-7),
                        employenumber: part.employenumber,
                        name: part.firstname + ' ' + part.lastname,
                        customer: part.company,
                        vin: part.vin,
                        locationName: part.locationName,
                        datecreate: FormatDate(part.request),
                        warehouse: part.warehousename,
                        partnumber: part.partnumber,
                        partcode: part.partcode,
                        description: part.description,
                        category: part.category,
                        chargetocustomer: part.chargetocustomer,
                        quantity: { v: part.quantity, t: 'n' },
                        serialnumber: part.serialnumber,
                        price: { v: part.price, t: 'n' },
                        total: { v: part.total, t: 'n' }
                      };
                    })}
                    fileName={'reportparts.pdf'}
                  ></BtnExportToExcell>
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTableWrapper
            columns={onlySummary ? columnsSummary : columns}
            data={services}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default PartsReport;
