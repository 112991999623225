import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormatCurrency2Dec } from 'utils/FormatData';

const CustomerDebt = ({ debtCustomer }) => {
  return (
    <>
      <Row>
        <Col>Current Debt</Col>
        <Col>{FormatCurrency2Dec(debtCustomer.total_debt)}</Col>{' '}
      </Row>
      <Row>
        <Col>Services Pending</Col>
        <Col>{debtCustomer.pendings}</Col>{' '}
      </Row>
    </>
  );
};

export default CustomerDebt;
