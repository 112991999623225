import { createContext, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from './LocalSess';
import { useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from 'utils/Encrypth';
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useLocalStorage('loginFalconData', null);
  const [auth, setAuth] = useState(null);
  const [customerUser, setCustomerUser] = useState(null);
  const [locationUser, setLocationUser] = useState(null);
  const [employeeUser, setEmployeeUser] = useState(null);
  const [isCustomer, setIsCustomer] = useState(false);
  const [locationsList, setLocationsList] = useLocalStorage('listRoutes', null);
  const navigate = useNavigate();
  const [isAlmacenista, setIsAlmacenista] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  // call this function when you want to authenticate the user
  const login = async data => {
    console.log('login');
    console.log(data);
    if (data.idcustomer > 0) {
      setCustomerUser(data.idcustomer);
      setIsCustomer(true);
    }
    if (data.idlocation > 0) {
      setLocationUser(data.idlocation ?? 0);
    }
    if (data.idemployee > 0) {
      setEmployeeUser(data.idemployee);
      setIsEmployee(true);
    }
    if (userData?.idusertype && userData.idusertype == 5) {
      setIsAlmacenista(true);
    }
    setAuth(true);
    setUserData(encryptData(data));
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAuth(false);
    setUserData(null);

    navigate('/', { replace: true });
  };

  const setLocations = async data => {
    setLocationsList(encryptData(data));
  };

  const getLocations = () => {};

  const getSessionData = () => {
    if (!userData) return null;
    const dataUserEncr = decryptData(userData);
    return dataUserEncr;
  };

  const value = useMemo(
    () => ({
      userData,
      auth,
      login,
      logout,
      getSessionData,
      customerUser,
      setCustomerUser,
      locationUser,
      setLocationUser,
      employeeUser,
      setEmployeeUser,
      isAlmacenista,
      setIsAlmacenista,
      isEmployee,
      setIsEmployee,
      setLocations
    }),
    [userData]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
