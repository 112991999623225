import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import i18next from 'i18next';
import classNames from 'classnames';
import { FormatCurrency, FormatCurrency2Dec } from 'utils/FormatData';
import SoftBadge from 'components/common/SoftBadge';

const SingleItem = ({
  icon,
  title,
  description,
  category,
  comments,
  status,
  price,
  requireAuth,
  id,
  confirmAuth
}) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative">
      <Row>
        <Col xs={4}>
          <Row>
            <Col xs={2}>
              <FontAwesomeIcon icon={icon} />
            </Col>
            <Col xs={10}>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{category}</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={10}>
              <h5 className="fs--1 text-800">{description}</h5>
              <p className="mb-0 fs--2 text-500">{comments}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={2} sx={{ textAlign: 'right' }}>
          <Flex>
            <h4 className="text-primary mb-0">
              {FormatCurrency2Dec(price ?? 0)}
            </h4>
          </Flex>
        </Col>
        <Col xs={2} sx={{ textAlign: 'right' }}>
          <Flex>
            <h4 className="text-primary mb-0">
              {requireAuth && (
                <Button
                  variant="falcon-default"
                  size="sm"
                  onClick={() => {
                    confirmAuth(id);
                  }}
                >
                  <FontAwesomeIcon icon="fa-check-circle" />
                </Button>
              )}
            </h4>
          </Flex>
        </Col>
        <Col xs={2}>
          {status > 0 && (
            <SoftBadge
              pill
              bg={classNames({
                success: status === 1,
                primary: status === 2,
                warning: status === 3,
                secondary: status === 4,
                info: status === 5
              })}
              className="d-block"
            >
              {status === 1 && `${i18next.t('status.InProccess')}`}
              {status === 2 && `${i18next.t('status.InLocation')}`}
              {status === 3 && `${i18next.t('status.InExternalService')}`}
              {status === 4 && `${i18next.t('status.Canceled')}`}
              {status === 5 && `${i18next.t('status.Completed')}`}
            </SoftBadge>
          )}
        </Col>
      </Row>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const ListItem = ({ data, setConfirmAuth }) => {
  return (
    <Card className="h-100">
      <Card.Body className="p-0">
        <SimpleBarReact className="ask-analytics">
          <div className="pt-0 px-card">
            {data.map(item => (
              <SingleItem
                key={item.title}
                icon={item.icon}
                title={item.title}
                description={item.description}
                category={item.category}
                comments={item.comments}
                status={item.status}
                price={item.price}
                requireAuth={item.requireauth}
                id={item.idservicelabor}
                confirmAuth={setConfirmAuth}
              />
            ))}
          </div>
        </SimpleBarReact>
      </Card.Body>
      <Card.Footer className="bg-light text-end py-2"></Card.Footer>
    </Card>
  );
};

ListItem.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired
};

export default ListItem;
