export const TRANSLATIONS_EN = {
  'menu.NewService': 'New Service',
  'menu.Services': 'Services',
  Company: 'Company',
  Employees: 'Employees',
  'label.NomEmpresa': 'Company Name',
  'label.Description': 'Description',
  'label.Categoria': 'Category',
  'label.Detalle': 'Detail',
  'label.Actions': 'Actions',
  'label.Add': 'Add',
  'label.Edit': 'Edit',
  'label.CommonFailure': 'Common Failure',
  'label.Alias': 'Alias',
  'label.Company': 'Company',
  'label.Contact': 'Contact',
  'label.Address': 'Address',
  'label.ZipCode': 'ZipCode',
  'label.City': 'City',
  'label.State': 'State',
  'label.Phone': 'Phone',
  'label.Mobile': 'Mobile',
  'label.Customer': 'Customer',
  'label.Customers': 'Customers',
  'label.Vin': 'Vin',
  'label.License': 'License',
  'label.Year': 'Year',
  'label.Make': 'Make',
  'label.Model': 'Model',
  'label.Color': 'Color',
  'label.Memo': 'Memo',
  'label.Unit': 'Unit',
  'label.Vehicle': 'Vehicle',
  'label.Location': 'Location',
  'label.ReportedFailures': 'Failures Reported',
  'label.CustomerData': 'Customer Info',
  'label.RequiredWorks': 'Required Works',
  'label.TimeAprox': 'Time',
  'label.Price': 'Price',
  'label.AddAndClose': 'Add and Close',
  'label.Cancel': 'Cancel',
  'label.ErrorSelectValid': 'Must select valid option',
  'label.SuccessfulRecord': 'Record added succesfully',
  'label.newService': 'New Service',
  'label.DetailsReported': 'Details',
  'label.DetailReported': 'Detail Reported',
  'label.SelectCustomer': 'Select Customer',
  'label.SelectVehicle': 'Select Vehicle',
  'label.FailureReported': 'Failure Reported',
  'label.GeneralData': 'General Data',
  'label.Name': 'Name',
  'label.Lastname': 'Lastname',
  'label.Email': 'Email',
  'label.Save': 'Save',
  'label.shortDescription': 'Short Description',
  'label.Required': 'Required',
  'label.faultCommons': 'Fault Commons',
  'label.FailuresEdit': 'Edit Failures',
  'label.EditCustomer': 'Edit Customer',
  'label.Addfailure': 'Add Failure',
  'label.birthdate': 'Birthdate',
  'label.ssn': 'SSN',
  'label.hiredate': 'Hiredate',
  'label.locationName': 'Location',
  'label.schedule': 'Schedule',
  'label.manager': 'Manager',
  'label.warehousename': 'Warehousename',
  'label.ContactName': 'ContactName',
  'label.Delete': 'Delete',
  'label.SymtomDescription': 'Symtom Description',
  'label.WorkRequested': 'Work Requested',
  'label.Hours': 'Hours',
  'status.InProccess': 'In proccess',
  'status.status': 'Status',
  'status.InLocation': 'En Location',
  'status.InExternalService': 'External Service',
  'status.Canceled': 'Cancel',
  'status.Completed': 'Completed',
  'label.ServiceInfo': 'Info service',
  'label.completed': 'Completed',
  'label.onhold': 'On Hold',
  'label.pending': 'Pending',
  'label.title': 'Title',
  'labe.password': 'Password',
  'label.Contacts': 'Contacts',
  'label.Vehicles': 'Vehicles',
  'label.administration': 'Administration',
  'label.ServiceDetail': 'Service Detail',
  'label.Details': 'Details',
  'label.FailureEdit': 'Edit Failure',
  'label.serviceorder': 'Service Order',
  'label.Failures': 'Failures',
  'label.AttachedFiles': 'Attached files',
  'label.FileName': 'Filename',
  'label.VisibleToCustomer': 'Visible to Customer',
  'label.ConfirmDelete': 'Confirm Delete',
  SuccessfulRecord: 'Successful registration',
  'label.SelectSomeValue': ' Select value',
  'label.Serial': 'Serial',
  'label.Falla': 'Failure',
  'label.Symptom': 'Symtom',
  'label.Comments': 'Comments',
  'label.Receives': 'Receives',
  'label.AddFile': 'Add File',
  'label.Parts': 'Parts',
  'label.Print': 'Print',
  'label.Quantity': 'Quanty',
  'label.WarehouseName': 'Warehouse Name',
  'label.Companies': 'Companies',
  'label.Thecnicians': 'Thecnicians',
  'label.HireDate': 'HireDate',
  'label.Thecnician': 'Thecnician',
  'label.Locations': 'Locations',
  'label.LocationName': 'Location Name',
  'label.SymptomsCategories': 'Symptoms Categories',
  'label.SymptomCategory': 'Symptoms Category',
  'label.PartsCategories': 'Parts Categories',
  'label.PartCategory': 'Part Category',
  'label.Vendors': 'Vendors',
  'label.Vendor': 'Vendor',
  'label.Extension': 'Extension',
  'label.Fax': 'Fax',
  'label.Terms': 'Terms',
  'label.Limits': 'Limits',
  'label.PartNumber': 'Part Number',
  'label.PartCode': 'Code Part',
  'label.Size': 'Size',
  'label.Manufacturer': 'Manufacture',
  'label.LastCost': 'LastCost',
  'label.IsTire': 'Tire',
  'label.IsTaxable': 'Taxable',
  'label.IsNew': 'New',
  'label.IsRebuild': 'Rebuild',
  'label.Part': 'Parte',
  'label.Profile': 'Profile',
  'label.Account': 'Account',
  'label.Settings': 'Settings',
  'label.Logout': 'Logout',
  'label.Notifications': 'Notifications',
  'label.MarkallRead': 'Mark all as read',
  'label.ViewAll': 'View all',
  'label.Suppliers': 'Suppliers',
  'label.Supplier': 'Supplier',
  'label.Labor': 'Labor',
  'label.Labors': 'Labors',
  'label.IsIncluded': 'Is Includec',
  'label.Visible': 'Visible',
  'label.EditFailureLabor': 'Edit Labor in Failure',
  'label.EditFailurePart': 'Edit Part Failure',
  'label.RequiredData': 'Required Data',
  'label.Technician': 'Technician',
  'status.IsIncluded': 'Included',
  'label.IsExternal': 'External',
  'label.ConfirmChangeVisibility': 'Confirm Change Visibility?',
  'label.MakeHidden': 'Change Hidden',
  'label.MakeVisible': 'Change Visible',
  'label.RejectFailure': 'Reject Failure',
  'label.DelayFailure': 'Delay Failure',
  'label.ConfirmFailure': 'Confirm Failure',
  'label.SelectAddVehiculo': '*Selecct or Add Vehicle',
  'label.AddFailsAndDetails': '*Add Fails And Details',
  'label.AddinformationDetails':
    'Please provide information related to the vehicle that can help the technical or administrative staff to do their best work',
  'label.Detail': 'Detail',
  'label.Services': 'Services',
  'label.ThecnicalUsers': ' Thecnica Users',
  'label.Active': 'Active',
  'label.UserType': 'User Type',
  'label.User': 'User',
  'label.Users': 'Users',
  'label.Thecnical': 'Thecnical',
  'label.IsActive': 'Active',
  'label.Password': 'Password',
  'label.ConfirmPassword': 'Confirm Password',
  'label.EditPassword': 'Edit Password',
  'label.Status': 'Status',
  'label.From': 'From',
  'label.To': 'To',
  'label.Filter': 'Filter',
  'label.Total': 'Total',
  'label.ChangeStatus': 'Change Status',
  'label.AsignService': 'Asign Service',
  'label.FailuresReported': 'Failures Reported',
  'label.IsAttended': 'Is Attended',
  'label.SuccessfulDeletedRecord': '¡Delete Record!',
  'label.LaborEdit': 'Edit Labor',
  'label.ConfirmAttended': 'Confirm Attended',
  'label.ChangePassword': 'Change Password',
  'label.Summary': 'Summary',
  'label.ChangeStatusToExternal': 'Change Status To External',
  'label.Asign': 'Asign',
  'label.EditFailureStatus': 'Edit Failure Status',
  'label.PaymentHourly': 'Payment Hourly',
  'label.Percentage': 'Payment Percentage',
  'label.Odometer': 'Odometer',
  'label.RequireAuth': 'Requires Authorization',
  'label.IsAuth': 'Authorized',
  'status.AuthConfirm': 'Authorize Work',
  'label.ConfirmAuthorize': 'Do you want to authorize this labor?',
  'label.SuccessfulAutorizeLabor': 'Labor updated succesfully',
  'label.Folio': 'Folio',
  'label.AlreadyExist': 'Record already exists',
  'label.AlreadyExistWithDifCustomer':
    'Record already exists with another customer',
  'label.AttendedDate': 'Attended Date',
  'label.PostedOrderSales': 'Posted Order Sales',
  'label.Taxable': 'Taxable',
  'label.NoTaxable': 'Non-Taxable',
  'label.LaborDiscount': 'Labor Discount',
  'label.Tires': 'Tires',
  'label.TotalSalesW': 'Total Sales With Tax',
  'label.NewService': 'New Service',
  'label.ReceivedDate': 'Reception Date',
  'label.TaxGeneral': 'General Taxes',
  'label.TaxLabors': 'Labor Taxes',
  'label.TaxParts': 'Parts Taxes',
  'label.AccountTypes': 'Account Types',
  'label.AccountType': 'Account Type',
  'label.BankAccounts': 'Bank Account',
  'label.BankAccount': 'Bank Account',
  'label.Bank': 'Bank',
  'label.AccountNumber': 'Account Number',
  'status.PendingComplete': 'Pending Complete',
  'label.TaskEdit': 'Edit Task',
  'label.TasksReported': 'Taks',
  'label.Task': 'Task',
  'label.Create': 'Created',
  'label.Autorized': 'Autorized',
  'message.GenericConfirm': 'Do you want to delete the record?',
  'message.ConfirmPendingService':
    'Do you want to send service for pending complete?',
  'label.PaymentTypes': 'Payment Types',
  'label.PaymentType': 'Payment Type'
};
