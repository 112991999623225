import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Image, Row, Table } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';

import SimpleBarReact from 'simplebar-react';
import ReactToPrint from 'react-to-print';
import {
  Format2Dec,
  FormatCurrency,
  FormatCurrency2Dec,
  FormatDate
} from 'utils/FormatData';
const Br = () => '\n';
const ServiceTaskPreview = ({
  idService,
  currentId,
  closeModal,
  newOpenModal
}) => {
  let componentRef = useRef();
  const [service, setService] = useState(null);
  const [serviceTaks, setServiceTaks] = useState(null);
  const [print, setPrint] = useState(false);
  const pageStyle = `{ size: 2.5in 4in }`;

  useEffect(() => {
    console.log('idService 1');
    console.log(idService);
  }, []);

  const getService = () => {
    const URI = ApiEndpoint + 'services/' + idService;
    axios
      .get(URI)
      .then(response => {
        let serviceData = response.data[0];
        setService(serviceData);
      })
      .then(getServiceTasks());
  };

  useEffect(() => {
    getService();
    // getServiceFailures();
    // getServiceLabors();
    // getServiceParts();
  }, []);

  const getServiceTasks = () => {
    const URITasks = ApiEndpoint + 'services/tasks/' + currentId;

    axios.get(URITasks).then(response => {
      let parts1 = response.data;

      setServiceTaks(parts1);
    });
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col style={{ alignContent: 'right' }}></Col>
        <Col>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="print"
                className="me-1 mb-2 mb-sm-0"
                iconClassName="me-1"
              >
                Print
              </IconButton>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>

      {service && (
        <div ref={componentRef}>
          <Row
            className="align-items-center text-center"
            style={{ marginBottom: '0px !important' }}
          >
            <Col sm={2} className="text-sm-start">
              <Image
                style={{ width: '100px', marginBottom: '0px !important' }}
                src="../../css/falcon.png"
              />
            </Col>
            <Col
              sm={6}
              style={{ textAlign: 'center', marginBottom: '0px !important' }}
              className="mt-0 mt-sm-0"
            >
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '12px'
                }}
                className="mt-0 mt-sm-0"
              >
                FRANCO MOBILE REPAIR
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                SHOP: 425 TAFT HWY / MAIL: 7605 Cranberry Way
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                Bakersfield, CA. 93307
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                Phone: 661-377-4222
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                MAIL ADDRESS: 7605 Cranberry Way, Bakersfield, CA.  93313
              </div>
            </Col>
            <Col className="text-sm-end mt-0 mt-sm-0">
              <Table>
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'}#{' '}
                      <Br /> {String('0000000' + service.idservice).slice(-7)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: 'left' }}>
              <h6>
                {' '}
                {service.idservicestatus == 1 ? 'ESTIMATE' : 'INVOICE'} FOR
                SERVICES
              </h6>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <h6>
                {' '}
                {service.idservicestatus == 1
                  ? 'ESTIMATE'
                  : 'INVOICE'} Date: {FormatDate(service.datecreate)}{' '}
              </h6>
            </Col>
          </Row>{' '}
          <hr style={{ margin: 0 }} />
          <Row
            className="align-items-center"
            style={{
              padding: '0px',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <Col
              style={{
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              {/* <h6 className="text-500">Invoice to</h6> */}
              <h6
                style={{
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
              >
                {service.customer.company}
              </h6>
              <p className="fs--1" style={{ marginBottom: '0px !important' }}>
                {service.customer.address}
                <br />
                {service.customer.city}, {service.customer.state}
                <br />
                {service.customer.email} {service.customer.phone}
              </p>
            </Col>
            <Col>
              {/* <h6 className="text-500">Invoice to</h6> */}
              <h6
                style={{
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
              >
                {service.customer.company}
              </h6>
              <p className="fs--1" style={{ marginBottom: '0px !important' }}>
                {service.vehicle.year}-{service.vehicle.make}-
                {service.vehicle.model}
                <br />
                Lic #: {service.vehicle.license} Odometer In: {service.odometer}
                <br />
                Unit #: {service.vehicle.unit} Vin #: {service.vehicle.vin}
              </p>
            </Col>
          </Row>
          <hr style={{ margin: 0, marginBottom: '0px !important' }} />
          <Row>
            <Col>
              <Table
                striped
                className="border-bottom"
                style={{
                  minHeight: '430px',
                  maxHeight: '430px',
                  padding: '0px',
                  paddingLeft: '2px',
                  display: 'table',
                  width: '100%'
                }}
              >
                <thead className="light">
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      colSpan={6}
                      className="border-0"
                    >
                      Tasks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {serviceTaks &&
                    serviceTaks.map(task => (
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            margin: 1,
                            padding: 1
                          }}
                          className="align-left"
                        >
                          {task.task}
                        </td>
                      </tr>
                    ))}
                  {(() => {
                    let rows = [];
                    for (
                      let i = serviceTaks ? serviceTaks.length : 0;
                      i < 15;
                      i++
                    ) {
                      rows.push(
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            colSpan={6}
                            className="align-left"
                          >
                            -
                          </td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-center"
                          ></td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-end"
                          ></td>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-end"
                          ></td>
                        </tr>
                      );
                    }
                    return rows;
                  })()}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ServiceTaskPreview;
