import {
  Page,
  Document,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer';

import { useEffect, useState } from 'react';
import HeaderPdf from 'shared/HeaderPdf';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
const BORDER_COLOR = '#000';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  headerDiv: {
    'text-align': 'center!important',
    'margin-bottom': '1rem!important',
    display: 'flex',
    'flex-wrap': 'wrap'
  },
  logoheader: {
    'text-align': 'left!important',
    flex: '0 0 auto',
    width: '16.66666667%'
  },
  textHeader: {
    'font-size': '.8333333333rem!important'
  },
  em: {
    fontStyle: 'bold'
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  headerBg: {
    // backgroundColor: '#aaa'
  },
  table: {
    display: 'table',
    width: '50%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row'
  },

  tableCellHeader: {
    fontSize: 10,
    fontWeight: 'bold'
    // fontFamily: "CustomRoboto",
  },
  tableCell: {
    margin: 1,
    fontSize: 9
    // fontFamily: "CustomRoboto",
  },
  textCenter: {
    textAlign: 'center'
  }
});
let tableCol = {
  borderStyle: 'solid',
  borderColor: '#dcdcdc',
  borderBottomColor: '#000',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0.5
};

const Br = () => '\n';
// Create Document Component
const LaborsReportPdf = ({ reportData, period }) => {
  const [uniqueEmployees, setUniqueEmployees] = useState([]);
  const getUniqueEmployees = () => {
    var AllEmployees = [];
    reportData.map(item => {
      AllEmployees.push(item.name);
    });

    const GetUniqueEmployees = [...new Set(AllEmployees)];
    setUniqueEmployees(GetUniqueEmployees);
  };

  useEffect(() => {
    getUniqueEmployees();
  }, []);

  return (
    <Document>
      <Page size="A4">
        <View style={{ padding: '15px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Image
                style={{ width: '100px' }}
                source="/css/falcon.png"
                //assets/img/illustrations/falcon.png
              />
            </View>
            <View style={{ flex: 3 }}>
              <HeaderPdf></HeaderPdf>
            </View>
          </View>
          <View key={'title'} style={[tableCol, { width: '100%' }]}>
            <Text
              style={{
                margin: 2,
                fontSize: 12,
                textAlign: 'center'
              }}
            >
              Labors By Period
            </Text>
          </View>
          <View key={'title'} style={[tableCol, { width: '100%' }]}>
            <Text
              style={{
                margin: 1,
                fontSize: 9,
                textAlign: 'center'
              }}
            >
              Period: {period}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 4, height: 1, backgroundColor: 'black' }} />
          </View>
          <View
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            {uniqueEmployees &&
              uniqueEmployees.map((employee, idx) => (
                <>
                  <View key={idx} style={styles.tableRow}>
                    <View key={idx} style={[tableCol, { width: '100%' }]}>
                      <Text style={[styles.tableCell]}>
                        Thecnician: {employee}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                      style={{ flex: 4, height: 1, backgroundColor: 'black' }}
                    />
                  </View>
                  {reportData &&
                    reportData
                      .filter(item => item.name == employee)
                      .map((item, idx) => (
                        <View key={idx} style={styles.tableRow}>
                          <View key={idx} style={[tableCol, { width: '15%' }]}>
                            <Text
                              style={[
                                styles.tableCell,
                                item.style ? item.style : {}
                              ]}
                            >
                              {item.idservice}
                              <Br />
                              {item.locationName}
                            </Text>
                          </View>
                          <View key={idx} style={[tableCol, { width: '35%' }]}>
                            <Text
                              style={{
                                margin: 1,
                                fontSize: 8,
                                textAlign: 'left'
                              }}
                            >
                              Customer:{item.companyName}
                              <Br />
                              Vehicle:{item.vin} -{item.model} - {item.year}
                            </Text>
                          </View>
                          <View key={idx} style={[tableCol, { width: '35%' }]}>
                            <Text
                              style={{
                                margin: 1,
                                fontSize: 8,
                                textAlign: 'left'
                              }}
                            >
                              Labor: {item.shortdescription}
                              <Br />
                              Date : {item.datecreate}
                            </Text>
                          </View>
                          <View key={idx} style={[tableCol, { width: '15%' }]}>
                            <Text
                              style={{
                                margin: 1,
                                fontSize: 10,
                                textAlign: 'right'
                              }}
                            >
                              {FormatCurrency(item.price)}
                            </Text>
                          </View>
                        </View>
                      ))}
                  <View key={idx} style={[tableCol, { width: '100%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 10,
                        textAlign: 'right'
                      }}
                    >
                      Total:
                      {FormatCurrency(
                        reportData
                          .filter(item => item.name == employee)
                          .reduce(
                            (total, currentValue) =>
                              (total = total + Number(currentValue.price)),
                            0
                          )
                      )}
                    </Text>
                  </View>
                </>
              ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default LaborsReportPdf;
