import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { EditServiceContext } from 'context/Context';
import axios from 'axios';
import { Button, Card } from 'react-bootstrap';
import i18next from 'i18next';
import { FormatCurrency } from 'utils/FormatData';
import MyModal from 'shared/Modal';
import EditServiceFailureStatus from './_EditServiceFailureStatus';
import AddFailureServiceOnEdit from './_AddFailureServiceOnEdit';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import LaborsTable from 'form-components/Tables/ListLabors';
import ListItem from './Partials/ListItem';

const URI = ApiEndpoint + 'services/failures/';
const ServiceFailures = ({ idService, isEditable, isFinished }) => {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [idFailure, setIdFailure] = useState(0);
  const [failuresList, setFailuresList] = useState([]);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalFailure, setOpenModalFailure] = useState(false);
  const [idCommonFailureService, setIdCommonFailureService] = useState(0);
  const [idCommonFailure, setIdCommonFailure] = useState(0);
  const [idCommonFailureStatus, setIdCommonFailureStatus] = useState('0');
  const handleClose = () => {
    setOpenModalStatus(false);
    setOpenModalFailure(false);
    getServiceFailures();
    window.location.reload();
  };

  useEffect(() => {
    getServiceFailures();
  }, []);

  const getServiceFailures = () => {
    axios.get(URI + idService).then(response => {
      let failures = response.data;
      let newFailureList = failures.map(failure => {
        return {
          description: failure.commonfailure.symtomdescription,
          title: failure.commonfailure.shortdescription,
          category: failure.commonfailure.symptomscategory.category,
          status: failure.commonfailuresstatus.failurestatus,
          comments: failure.comments,
          price: failure.commonfailure.price,
          statusId: failure.idcommonfailurestatus,
          idservicefailures: failure.idservicefailures,
          idcommonfailures: failure.idcommonfailures,
          subRows: failure.servicelabors.map(labor => {
            return {
              description: labor.shortdescription,
              visibletocustomer: labor.visibletocustomer,
              isexternal: labor.isexternal,
              price: labor.price,
              idservicefailures: labor.idservicelabor
            };
          })
          //labors: failure.
        };
      });
      setFailuresList(newFailureList);
      // setIdsymptomcategory(response.data.idsymptomcategory);
    });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <GenericTableHeader
            label={i18next.t('label.FailuresReported')}
            isFinished={isFinished}
            newFunction={() => {
              setIdFailure(0);
              setOpenModalFailure(true);
            }}
          />
        </Card.Header>
        <Card.Body className="p-0">
          {failuresList.length > 0 ? (
            <ListItem
              data={failuresList}
              isEditable={isEditable}
              setIdCommonFailureService={setIdCommonFailureService}
              setIdCommonFailureStatus={setIdCommonFailureStatus}
              setOpenModalStatus={setOpenModalStatus}
              setIdCommonFailure={setIdCommonFailure}
            />
          ) : (
            <span>
              <em>No data was found.</em>
            </span>
          )}
        </Card.Body>
      </Card>

      {openModalStatus && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.EditFailureStatus')}
          openModal={openModalStatus}
          closeModal={handleClose}
        >
          <EditServiceFailureStatus
            idCommonFailureService={idCommonFailureService}
            idCommonFailureStatus={idCommonFailureStatus}
            closeModal={handleClose}
            idCommonFailure={idCommonFailure}
            setHandleCloseAlert={() => setOpenAlert(false)}
          />
        </MyModal>
      )}
      {openModalFailure && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.FailureEdit')}
          openModal={openModalFailure}
          closeModal={handleClose}
        >
          <AddFailureServiceOnEdit
            idFailure={idFailure}
            failuresList={failuresList}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
            closeModal={handleClose}
            idService={idService}
          />
        </MyModal>
      )}
    </>
  );
};

export default ServiceFailures;
