import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/illustrations/falcon.png';

const JustLogo = ({ at, width, className, textClass, ...rest }) => {
  return (
    <div
      className={classNames(
        'd-flex',
        {
          'align-items-center py-3': at === 'navbar-vertical',
          'align-items-center': at === 'navbar-top',
          'flex-center fw-bolder fs-5 mb-4': at === 'auth'
        },
        className
      )}
    >
      <img className="me-2" src={logo} alt="Logo" width={width} />
      <span className={classNames('font-sans-serif', textClass)}>Franco</span>
    </div>
  );
};

JustLogo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

JustLogo.defaultProps = { at: 'auth', width: 58 };

export default JustLogo;
