import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import React, { useContext, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ServiceContext } from 'context/Context';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { ApiEndpoint } from 'utils/ApiEndPont';

const AddServiceTask = ({
  closeModal,
  idService,
  setOpenAlert,
  setTypeAlert,
  setAlertMessage
}) => {
  const [task, setTask] = useState('');
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      description: ''
    }
  });
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const onChange = event => {
    const { value } = event.target;
    setTask(value);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (data.task) {
      const URISaveFailures = ApiEndpoint + 'services/addtask';
      axios
        .post(URISaveFailures, {
          idservice: idService,
          task: data.task,
          datecreate: new Date(),
          autorized: true
        })
        .then(function (response) {
          setAlertMessage(i18next.t('SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          reset();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setAlertMessage(i18next.t('label.ErrorSelectValid'));
      setTypeAlert('warning');
      setOpenAlert(true);
    }

    //action(newData);
  };

  const onSubmitAndClose = async (data, e) => {
    e.preventDefault();
    if (data.task) {
      const URISaveFailures = ApiEndpoint + 'services/addtask';
      axios
        .post(URISaveFailures, {
          idservice: idService,
          task: data.task,
          datecreate: new Date(),
          autorized: true
        })
        .then(function (response) {
          setAlertMessage(i18next.t('SuccessfulRecord'));
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setAlertMessage(i18next.t('label.ErrorSelectValid'));
      setTypeAlert('warning');
      setOpenAlert(true);
    }
  };
  return (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormInputText
            label={i18next.t('label.Task')}
            name={'task'}
            control={control}
            defaultValue={task}
            changeHandler={onChange}
          ></FormInputText>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">
            {i18next.t('label.Add')}
          </Button>
          <Button onClick={handleSubmit(onSubmitAndClose)} variant="contained">
            {i18next.t('label.AddAndClose')}
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            {i18next.t('label.Cancel')}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddServiceTask;
