import axios from 'axios';
import { useAuth } from 'context/AuthProvider';
import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { v4 as uuidv4 } from 'uuid';
const URI = ApiEndpoint + 'users/';
const EditUserPasswordProfile = ({
  closeModal,
  setOpenAlert,
  setTypeAlert,
  setAlertMessage
}) => {
  const Auth = useAuth();
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [idUser, setIdUser] = useState(0);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstname: '',
      lastname: '',
      alias: '',
      password: '0',
      confirmPassword: ''
    }
  });
  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  });
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    alias: '',
    password: '0',
    confirmPassword: ''
  });
  useEffect(() => {
    let sesData = localStorage.getItem('loginFalconData');

    const userData = Auth.getSessionData();
    setIdUser(userData.iduser);
  }, []); // empty array makes hook working once

  const onSubmit = (data, e) => {
    if (error.password !== error.confirmPassword) {
      console.log('entre a submit 123');
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    axios
      .put(URI + 'pass/' + idUser, {
        password: password,
        uuid: uuidv4()
      })
      .then(function (response) {
        setAlertMessage(i18next.t('label.SuccessfulRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        console.log(response);
        closeModal();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onChange = event => {
    setPassword(event.target.value);
    validateInput(event);
  };
  const onChangeConfirm = event => {
    setConfirmPassword(event.target.value);
    validateInput(event);
  };

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'password':
          if (!value) {
            stateObj[name] = 'Please enter Password.';
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj['confirmPassword'] =
              'Password and Confirm Password does not match.';
          } else if (value.length < 6) {
            stateObj['password'] = 'Password is too small.';
          } else {
            stateObj['confirmPassword'] = confirmPassword
              ? ''
              : error.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Please enter Confirm Password.';
          } else if (password && value !== password) {
            stateObj[name] = 'Password and Confirm Password does not match.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormInputText
                  label={i18next.t('label.Password')}
                  changeHandler={onChange}
                  name={'password'}
                  control={control}
                  type="password"
                  defaultValue={password}
                ></FormInputText>
                {error.password && (
                  <span className="err">{error.password}</span>
                )}
              </Col>
              <Col>
                <FormInputText
                  control={control}
                  label={i18next.t('label.ConfirmPassword')}
                  name="confirmPassword"
                  type="password"
                  changeHandler={onChangeConfirm}
                  defaultValue={confirmPassword}
                ></FormInputText>
                {error.confirmPassword && (
                  <span className="err">{error.confirmPassword}</span>
                )}
              </Col>
            </Row>
            <Button type="submit" color="primary" size="sm">
              {i18next.t('label.Save')}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditUserPasswordProfile;
