import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDateToDb } from 'utils/FormatData';
import PaymentsFilters from './PaymentsFilters.js';
import ListPayments from './_ListPayments.js';

const PaymentsList = () => {
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [filterFolio, setFilterFolio] = useState(0);
  const [fromFilter, setFromFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toFilter, setToFilter] = useState(new Date());
  const [payments, setPayments] = useState([]);

  //mostrar companies /services/filters
  const getPayments = async () => {
    const URI2 = ApiEndpoint + 'payments/';
    const res = await axios.post(URI2 + 'getlist', {
      idcustomer: customerIdFilter,
      idstatus: idStatusFilter,
      del: FormatDateToDb(fromFilter),
      al: FormatDateToDb(toFilter),
      folio: Number(filterFolio)
    });
    setPayments(res.data.length > 0 ? res.data : []);
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <>
      <PaymentsFilters
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        action={getPayments}
        filterFolio={filterFolio}
        setFilterFolio={setFilterFolio}
      ></PaymentsFilters>
      {payments && <ListPayments paymentsList={payments}></ListPayments>}
    </>
  );
};

export default PaymentsList;
