import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import MyModal from 'shared/Modal';
import { ApiEndpoint, FilesEndpoint } from 'utils/ApiEndPont';
import AddServiceFile from './_AddServiceFile';
import pdfImg from '../../assets/img/icons/pdf.png';
import genericFile from '../../assets/img/icons/genericFile.png';
import { EditServiceContext } from 'context/Context';
import FilesPreviewServiceList from './Partials/_FilesPreviewServiceList';

const ServiceFilesList = ({
  idService,
  setOpenModal,
  serviceGuid,
  isEditable,
  isFinished
}) => {
  const {
    openAlert,
    setOpenAlert,
    typeAlert,
    setTypeAlert,
    alertMessage,
    setAlertMessage,
    setHandleCloseAlert
  } = useContext(EditServiceContext);
  const [serviceFiles, setServiceFiles] = useState([]);
  const { control } = useForm();
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [file, setFile] = useState(null);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [refreshFiles, setRefreshFiles] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [idToChangeVisibility, setIdToChangeVisibility] = useState(false);
  const [idPreToChangeVisibility, setIdPreToChangeVisibility] = useState(false);
  const [fileVisibility, setFileVisibility] = useState(false);
  const URI = ApiEndpoint + 'services/files/';
  const URIFiles = ApiEndpoint + 'services/getfile';
  const handleClose = () => {
    setOpenModalFile(false);
    getServiceFiles();
    setConfirmVisible(false);
    window.location.reload();
  };

  useEffect(() => {
    getServiceFiles();
  }, []);

  useEffect(() => {
    if (idToChangeVisibility && idToChangeVisibility > 0) {
      const URIVis = ApiEndpoint + 'services/filevisibility/';
      axios
        .put(URIVis + idToChangeVisibility, {
          visibilitycustomer: fileVisibility
        })
        .then(response => {
          setRefreshFiles(true);
        });
    }
  }, [idToChangeVisibility]);

  useEffect(() => {
    if (refreshFiles) {
      getServiceFiles();
      setRefreshFiles(false);
    }
  }, [refreshFiles]);

  const getServiceFiles = () => {
    console.log(URI + idService);
    axios.get(URI + idService).then(response => {
      let details = response.data;
      setServiceFiles(details);
    });
  };

  const handleDownload = (url, filename) => {
    const config = { responseType: 'blob', path: url };
    // axios.post(URIFiles, config).then(response => {
    //     new File(response, filename);
    // });

    axios({
      url: URIFiles,
      method: 'POST',
      data: config,
      responseType: 'blob' // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDeleteFile = (url, filename, idservicefile) => {
    // axios.post(URIFiles, config).then(response => {
    //     new File(response, filename);
    // });
    const URIDelete = ApiEndpoint + 'services/file/' + idservicefile;
    axios
      .delete(URIDelete, {
        url: url,
        filename: filename,
        idservicefile: idservicefile
      })
      .then(function (response) {
        console.log(response);
        getServiceFiles();
        setRefreshFiles(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <GenericTableHeader
            label={i18next.t('label.AttachedFiles')}
            isFinished={isFinished}
            newFunction={() => {
              setOpenModalFile(true);
            }}
          />
        </Card.Header>
        <Card.Body className="p-0" style={{ height: 350 }}>
          <FilesPreviewServiceList
            files={serviceFiles}
            isEditable={isEditable}
            setConfirmOpen={setConfirmOpen}
            setConfirmVisible={setConfirmVisible}
            setFileVisibility={setFileVisibility}
            setIdPreToChangeVisibility={setIdPreToChangeVisibility}
            setFile={setFile}
          ></FilesPreviewServiceList>
          {/* <div className="table-responsive scrollbar">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">{i18next.t('label.FileName')}</th>
                  <th scope="col">{i18next.t('label.VisibleToCustomer')}</th>
                  <th scope="col"> </th>
                </tr>
              </thead>
              <tbody>
                {serviceFiles.map((file, index) => (
                  <tr
                    className="hover-actions-trigger"
                    key={'filelist_' + index}
                  >
                    <td className="align-middle text-nowrap">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          {(() => {
                            switch (file.filetype) {
                              case 'image/png':
                              case 'image/jpeg':
                                return (
                                  <img
                                    className="img-thumbnail"
                                    src={
                                      FilesEndpoint +
                                      file.path.replace('/files/', '') +
                                      file.filename
                                      //  FilesEndpoint + file.path + file.filename
                                    }
                                    alt=""
                                  />
                                );
                              case 'application/pdf':
                                return (
                                  <img
                                    className="img-thumbnail"
                                    src={pdfImg}
                                    alt=""
                                  />
                                );
                              default:
                                return (
                                  <img
                                    className="rounded-circle"
                                    src={genericFile}
                                    alt=""
                                  />
                                );
                            }
                          })()}
                        </div>
                      </div>
                    </td>
                    <td>
                      <h6 className="mb-1">
                        <span className="  fw-semi-bold">{file.filename}</span>
                      </h6>
                      <div className="fs--1">
                        <span className="fw-semi-bold">{file.description}</span>
                      </div>
                    </td>
                    <td className="align-middle text-nowrap">
                      {file.visibilitycustomer ? 'visible' : 'No Visible'}
                    </td>
                    <td>
                      <Button
                        variant="falcon-default"
                        size="sm"
                        onClick={() => {
                          handleDownload(
                            file.path + file.filename,
                            file.filename
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="cloud-download-alt" />
                      </Button>
                      <Button
                        variant="falcon-default"
                        size="sm"
                        disabled={!isEditable}
                        onClick={() => {
                          setFile(file);
                          setConfirmOpen(true);
                        }}
                      >
                        <FontAwesomeIcon icon="trash-alt" />
                      </Button>
                      <Button
                        variant="falcon-default"
                        size="sm"
                        title={
                          file.visibilitycustomer
                            ? i18next.t('label.MakeHidden')
                            : i18next.t('label.MakeVisible')
                        }
                        onClick={() => {
                          setConfirmVisible(true);
                          setFileVisibility(file.visibilitycustomer);
                          setIdPreToChangeVisibility(file.idservicefile);
                        }}
                      >
                        {file.visibilitycustomer ? (
                          <FontAwesomeIcon icon={['fa', 'eye']} />
                        ) : (
                          <FontAwesomeIcon icon={['fa', 'eye']} />
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </Card.Body>
      </Card>
      {confirmVisible && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.ConfirmChangeVisibility')}
          openModal={confirmVisible}
          isConfirm={true}
          closeModal={handleClose}
          onConfirm={() => {
            setIdToChangeVisibility(idPreToChangeVisibility);
          }}
        ></MyModal>
      )}
      {confirmOpen && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.ConfirmDelete')}
          openModal={confirmOpen}
          isConfirm={true}
          closeModal={handleClose}
          onConfirm={() => {
            handleDeleteFile(
              file.path + file.filename,
              file.filename,
              file.idservicefile
            );
            setConfirmOpen(false);
          }}
        ></MyModal>
      )}
      {openModalFile && (
        <MyModal
          id="id_myModalFile"
          title={i18next.t('label.AddFile')}
          openModal={openModalFile}
          closeModal={handleClose}
        >
          <AddServiceFile
            idService={idService}
            serviceGuid={serviceGuid}
            closeModal={handleClose}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          ></AddServiceFile>
        </MyModal>
      )}
    </>
  );
};

export default ServiceFilesList;
