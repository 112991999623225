import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import i18next from '../../utils/locales/i18n.js';
import { ApiEndpoint, FilesEndpoint } from 'utils/ApiEndPont.js';
import { Button, Card, Col, Image, Row, Table } from 'react-bootstrap';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddServiceParts from './_AddDetailServicePart.js';
import MyModal from 'shared/Modal.js';
import { EditServiceContext } from 'context/Context.js';
import Flex from 'components/common/Flex.js';
import { FormatCurrency, FormatCurrency2Dec } from 'utils/FormatData.js';
import ConfirmAction from 'form-components/ConfirmationModal.js';
import EditServicePart from './_EditServicePart.js';
import FalconLightBox from 'components/common/FalconLightBox.js';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery.js';

const ServicePartsList = ({
  idService,
  setOpenModal,
  serviceGuid,
  idVehicle,
  isEditable,
  isFinished,
  idThecnician,
  idLocation
}) => {
  const [serviceParts, setServiceParts] = useState([]);
  const [refreshParts, setRefreshParts] = useState(false);
  const [idPartToDelete, setIdPartToDelete] = useState(false);
  const [idPartToEdit, setIdPartToEdit] = useState(false);
  const [openModalPart, setOpenModalPart] = useState(false);
  const [openModalEditPart, setOpenModalEditPart] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const URI = ApiEndpoint + 'services/parts/';

  const handleClose = () => {
    setOpenModalPart(false);
    setOpenModalEditPart(false);
    getServiceParts();
    window.location.reload();
  };

  useEffect(() => {
    getServiceParts();
  }, []);

  useEffect(() => {
    if (refreshParts === true) {
      getServiceParts();
      setRefreshParts(false);
    }
  }, [refreshParts]);

  const getServiceParts = () => {
    axios.get(URI + idService).then(response => {
      let parts = response.data;
      setServiceParts(parts);
      // setIdsymptomcategory(response.data.idsymptomcategory);
    });
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    getServiceParts();
  };
  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    axios
      .delete(URI + idPartToDelete)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        getServiceParts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTotalPrice = items =>
    items
      .filter(item => item?.chargetocustomer == true)
      .map(({ quantity, price }) => Number(quantity) * Number(price))
      .reduce((total, currentValue) => Number(total) + Number(currentValue), 0);

  const getTotalService = items =>
    items
      .map(({ quantity, price }) => Number(quantity) * Number(price))
      .reduce((total, currentValue) => total + currentValue, 0);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <GenericTableHeader
            label={i18next.t('label.Parts')}
            isFinished={isFinished}
            newFunction={() => {
              setOpenModalPart(true);
            }}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive fs--1">
            <Table
              responsive
              striped
              hover
              style={{ height: '350px', width: '100%' }}
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">{i18next.t('label.PartName')}</th>
                  <th scope="col">{i18next.t('label.Description')}</th>
                  <th scope="col">{i18next.t('label.WarehouseName')}</th>
                  <th scope="col">{i18next.t('label.Quantity')}</th>
                  <th scope="col">{i18next.t('label.Price')}</th>
                  <th scope="col">{i18next.t('label.Total')}</th>
                  <th scope="col">{i18next.t('label.Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {serviceParts.map((part, index) => (
                  <tr key={part.idserviceparts}>
                    <td>
                      {' '}
                      <div class="file-thumbnail r2test">
                        <FalconLightBox
                          image={
                            !part.part.imagesrc || part.part.imagesrc == ''
                              ? 'http://franco95.com/imgs/parts-Icon.jpg'
                              : FilesEndpoint +
                                part.part.imagesrc.replace('files/', '')
                          }
                        >
                          <img
                            class="h-100 w-100 fit-cover rounded-2 border"
                            src={
                              !part.part.imagesrc || part.part.imagesrc == ''
                                ? 'http://franco95.com/imgs/parts-Icon.jpg'
                                : FilesEndpoint +
                                  part.part.imagesrc.replace('files/', '')
                            }
                            alt=""
                          />
                          {/* <Image src={image} fluid rounded width={300} /> */}
                        </FalconLightBox>
                      </div>
                    </td>
                    <td>{part.part.partcode}</td>
                    <td>{part.part.description}</td>
                    <td>{part.part.partcode}</td>
                    <td>{part.quantity}</td>
                    <td style={{ textAlign: 'right' }}>
                      {FormatCurrency2Dec(part.price)}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {FormatCurrency2Dec(
                        Number(part.quantity) * Number(part.price)
                      )}
                    </td>
                    <td>
                      <div
                        style={{
                          maxWidth: '100px',
                          maxHeight: '100px',
                          overflow: 'hidden'
                        }}
                      >
                        {part.servicefiles &&
                          part.servicefiles.map((itemFile, index) => {
                            return (
                              <>
                                <div class="file-thumbnail sm">
                                  <FalconLightBox
                                    image={
                                      FilesEndpoint +
                                      itemFile.path.replace('/files/', '') +
                                      itemFile.filename
                                    }
                                  >
                                    <img
                                      class="h-100 w-100 fit-cover rounded-2 border"
                                      src={
                                        FilesEndpoint +
                                        itemFile.path.replace('/files/', '') +
                                        itemFile.filename
                                      }
                                      alt=""
                                    />
                                    {/* <Image src={image} fluid rounded width={300} /> */}
                                  </FalconLightBox>
                                </div>
                              </>
                            );
                          })}
                      </div>

                      {isEditable && (
                        <>
                          <Button
                            variant="falcon-default"
                            size="sm"
                            disabled={!isEditable}
                            onClick={() => {
                              setOpenModalEditPart(true);
                              setIdPartToEdit(part.idserviceparts);
                            }}
                          >
                            <FontAwesomeIcon icon="pencil-alt" />
                          </Button>
                          <Button
                            variant="falcon-default"
                            size="sm"
                            disabled={!isEditable}
                            onClick={() => {
                              setIdPartToDelete(part.idserviceparts);
                              setOpenConfirm(true);
                            }}
                          >
                            <FontAwesomeIcon icon="trash-alt" />
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>{' '}
        <Card.Footer className="bg-light py-2">
          <Row className="g-0 flex-between-center">
            <Col xs="auto">
              <h1 className="fs-0 text-900">Total Service</h1>
              <Flex>
                <h4 className="text-primary mb-0">
                  {FormatCurrency(getTotalService(serviceParts))}{' '}
                </h4>
              </Flex>
            </Col>{' '}
            <Col xs="auto">
              <h1 className="fs-0 text-900">Total Customer</h1>
              <Flex>
                <h4 className="text-primary mb-0">
                  {FormatCurrency(getTotalPrice(serviceParts))}{' '}
                </h4>
              </Flex>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      {openModalPart && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.Parts')}
          openModal={openModalPart}
          closeModal={handleClose}
        >
          <AddServiceParts
            closeModal={handleClose}
            idService={idService}
            idVehicle={idVehicle}
            idThecnician={idThecnician}
            idLocation={idLocation}
          />
        </MyModal>
      )}{' '}
      {openModalEditPart && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.Parts')}
          openModal={openModalEditPart}
          closeModal={handleClose}
        >
          <EditServicePart
            closeModal={handleClose}
            idService={idService}
            idVehicle={idVehicle}
            idServicePart={idPartToEdit}
          />
        </MyModal>
      )}
      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};
export default ServicePartsList;
