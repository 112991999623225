import axios from 'axios';
import EditVehicle from 'Catalogs/Administration/_EditVehicle';
import classNames from 'classnames';
import IconButton from 'components/common/IconButton';
import { ServiceContext } from 'context/Context';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import MyModal from 'shared/Modal';
import createMarkup from 'helpers/createMarkup.js';
import { ApiEndpoint } from 'utils/ApiEndPont';
import VehicleDetail from '../Services/_VehicleDetail';
import Select from 'react-select';
const customStyles = {
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 'auto'
  })
};

function EditVehicleService({ action }) {
  const [vehicles, setVehicles] = useState([]);
  const {
    customerId,
    serviceId,
    setServiceId,
    vehicleId,
    setVehicleId,
    setOpenAlert,
    setTypeAlert,
    setAlertMessage
  } = useContext(ServiceContext);
  const [openModalVehicle, setOpenModalVehicle] = useState(false);
  let [refreshData, setRefreshData] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [values, setValues] = useState({
    idVehicle: '0'
  });

  const {
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const handleCloseVehicle = () => {
    setOpenModalVehicle(false);
    setVehicleId(0);
    getVehicles();
  };

  useEffect(() => {
    setRefreshData(true);
    //localStorage.setItem(currentId.id, '')
    const data = localStorage.getItem(serviceId);
    if (data != null) {
      let newIdVehicle = JSON.parse(data).idVehicle;
      setVehicleId(parseInt(newIdVehicle) || 0);
    }
  }, []); // empty array makes hook working once

  useEffect(() => {
    setRefreshData(true);

    if (vehicleId > 0) {
      setVehicleId(vehicleId);
    }
  }, [vehicleId]);

  useEffect(() => {
    getVehicles();
  }, []);

  //mostrar companies
  const getVehicles = async () => {
    const UriVehicles = ApiEndpoint + 'customervehicles/';
    const res = await axios.get(UriVehicles + customerId);
    const vehiclesList = res.data.map(item => {
      return {
        value: item.idVehicle,
        label: `${item.vin} - ${item.make} - ${item.year}`
      };
    });
    setVehicles(vehiclesList);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={12}>
              <Row>
                <Col>
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {i18next.t('label.Vehicle')}
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm="auto">
                  <p
                    className="mb-0 fs--2 text-500"
                    dangerouslySetInnerHTML={createMarkup(
                      i18next.t('label.SelectAddVehiculo')
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <InputGroup>
                <div style={{ width: '600px' }}>
                  <Select
                    aria-label="Default select"
                    name="vehicleId"
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    value={selectedVehicle}
                    Required
                    onChange={value => {
                      setRefreshData(false);
                      setVehicleId(value.value);
                      setSelectedVehicle(value);
                    }}
                    options={vehicles}
                  ></Select>
                </div>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => {
                    setOpenModalVehicle(true);
                  }}
                >
                  {i18next.t('label.Add')}
                </IconButton>
              </InputGroup>
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Card.Body>
        <Row>{refreshData && vehicleId > 0 && <VehicleDetail />}</Row>
        {openModalVehicle && (
          <MyModal
            id="id_myModal"
            title={i18next.t('label.Add') + i18next.t('label.Vehicle')}
            openModal={openModalVehicle}
            closeModal={handleCloseVehicle}
          >
            <EditVehicle
              closeModal={handleCloseVehicle}
              setOpenAlert={setOpenAlert}
              setTypeAlert={setTypeAlert}
              setAlertMessage={setAlertMessage}
              idCustomer={customerId}
              idVehicle={0}
            />
          </MyModal>
        )}
      </Card.Body>
    </>
  );
}

export default EditVehicleService;
