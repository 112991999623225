import axios from 'axios';
import { EditServiceContext } from 'context/Context';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';
const URI = ApiEndpoint + 'servicestatus/';
const EditServiceStatus = ({ service, closeModal }) => {
  const [statusList, setStatusList] = useState([]);
  const [idStatus, setIdStatus] = useState([]);
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const {
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idStatus: 0
    }
  });

  useEffect(() => {
    axios(URI).then(({ data }) => {
      const result = data
        .filter(item => item.idservicestatus != service.idservicestatus)
        .map(item => {
          return { label: item.status, value: item.idservicestatus };
        });
      console.log('result');
      console.log(result);
      setStatusList(result);
    });
  }, []);

  const onSubmit = (data, e) => {
    if (idStatus == 0) {
      return;
    }
    if (service.idservice > 0) {
      const URIStatus = ApiEndpoint + 'services/status/';
      axios
        .put(URIStatus + service.idservice, {
          idservice: service.idservice,
          idStatus: idStatus
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  return (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="status">
                <Form.Label>{i18next.t('label.Status')}</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  name="status"
                  style={{ minWidth: '250px' }}
                  onChange={selectedOption => {
                    setIdStatus(`${selectedOption.target.value}`);
                  }}
                  value={`${idStatus}`}
                >
                  <option key={'location_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!statusList?.length &&
                    statusList.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Button onClick={handleSubmit(onSubmit)} variant="contained">
            {i18next.t('label.Add')}
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            {i18next.t('label.Cancel')}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditServiceStatus;
