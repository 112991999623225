export const TRANSLATIONS_ES = {
  'menu.NewService': 'Nuevo Servicio',
  'menu.Services': 'Servicios',
  Company: 'Empresa',
  Employees: 'Empleados',
  'label.NomEmpresa': 'Nombre de Empresa',
  'label.Description': 'Descripción',
  'label.Categoria': 'Categoría',
  'label.Detalle': 'Detalle',
  'label.Actions': 'Acciones',
  'label.Add': 'Agregar',
  'label.Edit': 'Editar',
  'label.CommnonFailure': 'Falla Común',
  'label.Alias': 'Alias',
  'label.Company': 'Empresa',
  'label.Contact': 'Contacto',
  'label.Address': 'Dirección',
  'label.ZipCode': 'ZipCode',
  'label.City': 'Ciudad',
  'label.State': 'Estado',
  'label.Phone': 'Teléfono',
  'label.Mobile': 'Celular',
  'label.Customer': 'Cliente',
  'label.Customers': 'Clientes',
  'label.Vin': 'Vin',
  'label.License': 'Licencia',
  'label.Year': 'Año',
  'label.Make': 'Marca',
  'label.Model': 'Modelo',
  'label.Color': 'Color',
  'label.Memo': 'Memo',
  'label.Unit': 'Unit',
  'label.Vehicle': 'Vehículo',
  'label.Location': 'Patio',
  'label.ReportedFailures': 'Fallas Reportadas',
  'label.CustomerData': 'Datos de Cliente',
  'label.RequiredWorks': 'Trabajos Requeridos',
  'label.TimeAprox': 'Tiempo Aproximado',
  'label.Price': 'Precio',
  'label.AddAndClose': 'Agregar y Cerrar',
  'label.Cancel': 'Cancelar',
  'label.ErrorSelectValid': 'Debe seleccionar un registro valido',
  'label.SuccessfulRecord': 'Registro agregado Exitosamente',
  'label.newService': 'Nuevo Servicio',
  'label.DetailsReported': 'Detalles Reportados',
  'label.DetailReported': 'Agregar Detalle Reportado',
  'label.SelectCustomer': 'Selecciona Cliente',
  'label.SelectVehicle': 'Selecciona Vehículo',
  'label.FailureReported': 'Fallas Reportadas',
  'label.GeneralData': 'Información General',
  'label.Name': 'Nombre',
  'label.Lastname': 'Apellidos',
  'label.Email': 'Correo Electrónico',
  'label.Save': 'Guardar',
  'label.shortDescription': 'Descripción Corta',
  'label.Required': 'Requerido',
  'label.faultCommons': 'Fallas Comunes',
  'label.FailuresEdit': 'Editar Fallas',
  'label.EditCustomer': 'Editar Cliente',
  'label.Addfailure': 'Agregar Falla',
  'label.birthdate': 'Fecha de Nacimiento',
  'label.ssn': 'Seguro Social',
  'label.hiredate': 'Fecha Contratación',
  'label.locationName': 'Patio',
  'label.schedule': 'Horario',
  'label.manager': 'Administrador',
  'label.Warehouse': 'Almacén',
  'label.ContactName': 'Nombre de Contacto',
  'label.Delete': 'Eliminar',
  'label.SymtomDescription': 'Descripción de falla',
  'label.WorkRequested': 'Trabajo solicitado',
  'label.Hours': 'Horas',
  'status.InProccess': 'En proceso',
  'status.status': 'Status',
  'status.InLocation': 'En Patio',
  'status.InExternalService': 'En servicio externo',
  'status.Canceled': 'Cancelado',
  'status.Completed': 'Completado',
  'label.ServiceInfo': 'Informe del Servicio',
  'label.completed': 'Completado',
  'label.onhold': 'Es espera',
  'label.pending': 'Pendiente',
  'label.title': 'Título',
  'label.password': 'Contraseña',
  'label.Contacts': 'Contactos',
  'label.Vehicles': 'Vehículos',
  'label.administration': 'Administración',
  'label.ServiceDetail': 'Detalles del Servicio',
  'label.Details': 'Detalles',
  'label.FailureEdit': 'Editar Falla',
  'label.serviceorder': 'Ordenes de Servicio',
  'label.Failures': 'Fallas',
  'label.AttachedFiles': 'Archivos Adjuntos',
  'label.FileName': 'Nombre del Archivo',
  'label.VisibleToCustomer': 'Visible al Cliente',
  'label.ConfirmDelete': 'Confirmar Eliminar',
  SuccessfulRecord: 'Registro Agregado',
  'label.SelectSomeValue': ' Seleccione algún valor',
  'label.Serial': 'Serial',
  'label.Failure': 'Falla',
  'label.Symptom': 'Problema',
  'label.Comments': 'Comentarios',
  'label.Receives': 'Recibe',
  'label.AddFile': 'Agregar Archivo',
  'label.Parts': 'Refacciones',
  'label.Print': 'Imprimir',
  'label.PartName': 'Refacción',
  'label.Quantity': 'Cantidad',
  'label.WarehouseName': 'Nombre de Almacén',
  'label.Companies': 'Compañias',
  'label.Thecnicians': 'Técnicos',
  'label.HireDate': 'Fecha Contratación',
  'label.Thecnician': 'Técnico',
  'label.Locations': 'Patios',
  'label.LocationName': 'Nombre de Patio',
  'label.SymptomsCategories': 'Categoría de Fallas',
  'label.SymptomCategory': 'Categoría  de Falla',
  'label.Warehouses': 'Almacenes',
  'label.PartsCategories': 'Categorias de Refacciones',
  'label.PartCategory': 'Categoria de Refacción',
  'label.Vendors': 'Vendedores',
  'label.Vendor': 'Vendedor',
  'label.Extension': 'Extensión',
  'label.Fax': 'Fax',
  'label.Terms': 'Téminos',
  'label.Limits': 'Límites',
  'label.PartNumber': 'Numero de Refacción',
  'label.PartCode': 'Código de Refacción',
  'label.Size': 'Tamaño',
  'label.Manufacturer': 'Fabricante',
  'label.LastCost': 'Costo',
  'label.IsTire': 'Neumático',
  'label.IsTaxable': 'Gravable',
  'label.IsNew': 'Nuevo',
  'label.IsRebuild': 'Reconstruido',
  'label.Part': 'Refacción',
  'label.Profile': 'Perfil',
  'label.Account': 'Cuenta',
  'label.Settings': 'Configuración',
  'label.Logout': 'Salir',
  'label.Notifications': 'Notificaciones',
  'label.MarkallRead': 'Marcar todo Leído',
  'label.New': 'NUEVO',
  'label.ViewAll': 'Ver todo',
  'label.Suppliers': 'Proveedores',
  'label.Supplier': 'Proveedor',
  'label.Labor': 'Trabajo',
  'label.Labors': 'Trabajos',
  'label.IsIncluded': 'Incluido',
  'label.Visible': 'Visible',
  'label.EditFailureLabor': 'Editar trabajos en Falla',
  'label.EditFailurePart': 'Editar Refacción para Falla',
  'label.RequiredData': 'Dato Obligatorio',
  'label.Technician': 'Técnico',
  'status.IsIncluded': 'Incluido',
  'label.IsExternal': 'Externo',
  'label.ConfirmChangeVisibility': '¿Confimar cambio de visibilidad?',
  'label.MakeHidden': 'Cambiar a Ocultar',
  'label.MakeVisible': 'cambiar a Visible',
  'label.RejectFailure': 'Rechazar Falla',
  'label.DelayFailure': 'Retrasar Reparación',
  'label.ConfirmFailure': 'Confirmar Reparación',
  'label.SelectAddVehiculo': '*Seleccione o Agregre un nuevo Vehículo',
  'label.AddFailsAndDetails': '*Agregar Fallas Conocidas y Detalles',
  'label.AddinformationDetails':
    '*Proporcione información relacionada con el vehículo que pueda ayudar al personal técnico o administrativo a hacer su mejor trabajo.',
  'label.Detail': 'Detalle',
  'label.Services': 'Servicios',
  'label.ThecnicalUsers': ' Técnicos Usuarios',
  'label.Active': 'Activo',
  'label.UserType': 'Tipo de Usuario',
  'label.User': 'Usuario',
  'label.Users': 'Usuarios',
  'label.Thecnical': 'Técnico',
  'label.IsActive': 'Activo',
  'label.Password': 'Contraseña',
  'label.ConfirmPassword': 'Confirmar Contraseña',
  'label.EditPassword': 'Editar Contraseña',
  'label.Status': 'Status',
  'label.From': 'De',
  'label.To': 'A',
  'label.Filter': 'Filtrar',
  'label.Total': 'Total',
  'label.ChangeStatus': 'Cambiar Status',
  'label.AsignService': 'Asignar Servicio',
  'label.FailuresReported': 'Fallas Reportadas',
  'label.IsAttended': 'Atendio',
  'label.SuccessfulDeletedRecord': '¡Registro Eliminado Correctamente!',
  'label.LaborEdit': 'Editar Trabajo',
  'label.ConfirmAttended': 'Confirmar Atención',
  'label.ChangePassword': 'Cambiar Contraseña',
  'label.Summary': 'Resumen',
  'label.ChangeStatusToExternal': 'Cambiar Status a Externo',
  'label.Asign': 'Asignar',
  'label.EditFailureStatus': 'Editar Status de Falla',
  'label.PaymentHourly': 'Pago por horas',
  'label.Percentage': 'Pago por Percentage',
  'label.Odometer': 'Odometro',
  'label.RequireAuth': 'Requiere Autorizacion',
  'label.IsAuth': 'Autorizado',
  'status.AuthConfirm': 'Autorizar',
  'label.ConfirmAuthorize': 'Desea autorizar el trabajo seleccionado?',
  'label.SuccessfulAutorizeLabor': 'Registro actualizado',
  'label.Folio': 'Folio',
  'label.AlreadyExist': 'El registro ya existe',
  'label.AlreadyExistWithDifCustomer':
    'El registro ya existe con diferente cliente',
  'label.AttendedDate': 'Atendido',
  'label.PostedOrderSales': 'Servicios',
  'label.Taxable': 'Con Impuestos',
  'label.NoTaxable': 'Sin Impuestos',
  'label.LaborDiscount': 'Descuentos en Trabajos',
  'label.Tires': 'Llantas',
  'label.TotalSalesW': 'Total',
  'label.ReceivedDate': 'Fecha de Recepcion',
  'label.vehicles': 'Vehiculos',
  'label.TaxGeneral': 'Impuestos Generales',
  'label.TaxLabors': 'Impuestos en Labores',
  'label.TaxParts': 'Impuestos en Refacciones',
  'label.AccountTypes': 'Tipos de Cuentas',
  'label.AccountType': 'Tipo de Cuenta',
  'label.BankAccounts': 'Cuentas Bancarias',
  'label.BankAccount': 'Cuenta Bancaria',
  'label.Bank': 'Banco',
  'label.TaskEdit': 'Editar Tarea',
  'label.TasksReported': 'Tareas',
  'label.AccountNumber': 'Numero de Cuenta',
  'status.PendingComplete': 'Pendiente de Completar',
  'message.GenericConfirm': 'Desea eliminar el registro?',
  'label.Task': 'Tarea',
  'label.Create': 'Creado',
  'label.Autorized': 'Autorizado',
  'message.ConfirmPendingService':
    'Desea modificar el registro para completar Servicio?',
  'label.PaymentTypes': 'Tipos de Pago',
  'label.PaymentType': 'Tipo de Pago'
};
