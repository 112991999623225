import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import SimpleBarReact from 'simplebar-react';

const StatisticsCard = ({ stat, ...rest }) => {
  const {
    title,
    value,
    decimal,
    suffix,
    prefix,
    valueClassName,
    linkText,
    link,
    badgeText,
    badgeBg,
    image,
    className
  } = stat;
  return (
    <Card className={classNames(className, 'overflow-hidden')} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <SimpleBarReact className="ask-analytics">
          <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
            <h6>
              {title}
              {badgeText && (
                <SoftBadge bg={badgeBg} pill className="ms-2">
                  {badgeText}
                </SoftBadge>
              )}
            </h6>
            <div
              className={classNames(
                valueClassName,
                'display-4 fs-4 mb-2 fw-normal font-sans-serif'
              )}
            >
              <CountUp
                start={0}
                end={value}
                duration={2.75}
                suffix={suffix}
                prefix={prefix}
                separator=","
                decimals={decimal ? 2 : 0}
                decimal="."
              />
            </div>{' '}
          </div>
        </SimpleBarReact>
      </Card.Body>
    </Card>
  );
};

StatisticsCard.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    decimal: PropTypes.bool,
    suffix: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    valueClassName: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    badgeText: PropTypes.string,
    badgeBg: PropTypes.string,
    image: PropTypes.string,
    className: PropTypes.string
  })
};

export default StatisticsCard;
