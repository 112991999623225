import axios from 'axios';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate, isJson } from 'utils/FormatData';
import StatisticsCard from './Cards/StatisticsCard';
import StatisticsCards from './Cards/StatisticsCards';
import ServicesListByStatus from './ListServices';
import Intelligence from './ListSummary/Intelligence';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
const URI = ApiEndpoint + 'services/report/';

const DashboardByLocation = () => {
  let { idLocationDefault } = useParams();
  const [services, setServices] = useState([]);
  const [idStatusSelected, setIdStatusSelected] = useState(1);
  const [servicesList, setServicesList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [idLocation, setIdLocation] = useState(1);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (idLocation < 1 && idLocationDefault > 0) {
      setIdLocation(idLocationDefault);
    }
  }, []);

  const getServices = () => {
    axios
      .get(URI + 'countbystatus?idlocation=' + idLocation)
      .then(function (response) {
        const serviceData = response.data.map(item => {
          return {
            title: item.status,
            value: item.vehicles,
            decimal: false,
            suffix: '',
            prefix: '',
            valueClassName: 'text-info',
            badgeBg: 'info',
            link: '',
            linkText: 'See all',
            idStatus: item.idservicestatus
          };
        });
        setServices(serviceData);
      })
      .then(() => {
        const URILocation = ApiEndpoint + 'locations/';
        axios.get(URILocation + idLocation).then(response => {
          setLocation(response.data);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLocationsList = async () => {
    let newArr = [];
    let listLocations = localStorage.getItem('listRoutes') ?? [];
    let listLocationArr = isJson(listLocations)
      ? JSON.parse(listLocations)
      : [];
    listLocationArr.map(item => newArr.push(item));
    console.log('listLocationArr');
    console.log(listLocationArr);

    const UriLocations = ApiEndpoint + 'locations/';
    const res = await axios.get(UriLocations);
    setLocations(res.data);
  };

  const getServicesByStatus = async () => {
    setServicesList([]);
    const res = await axios.post(URI + 'servicesbylocation', {
      idlocation: idLocation,
      idservicestatus: idStatusSelected
    });
    const resultServiceList = res.data.map(item => {
      return {
        uniqueId: item.serviceid,
        title: `#COT-${String('0000000' + item.idservice).slice(-7)}`,
        icon: 'angle-right',
        description: `${item.vehicle.vin} - ${item.vehicle.make} - ${item.vehicle.year}`,
        date: FormatDate(item.datecreate),
        company: item.company.companyName,
        status: item.servicestatus.idservicestatus
      };
    });
    setServicesList(resultServiceList);
  };

  useEffect(() => {
    getServices();
    getServicesByStatus();
    getLocationsList();
  }, []);

  useEffect(() => {
    getServices();
    getServicesByStatus();
  }, [idLocation]);

  useEffect(() => {
    getServicesByStatus();
  }, [idStatusSelected]);
  return (
    <>
      <Card className="bg-100 shadow-none border mb-3">
        <Card.Body className="py-0">
          <Row className="g-0 justify-content-between">
            <Col sm="auto">
              <Flex alignItems="center">
                <img src={barChart} width={90} alt="..." className="ms-n4" />
                <div>
                  <h6 className="text-primary fs--1 mb-0">Franco Mobile </h6>
                  <h4 className="text-primary fw-bold mb-0">
                    {location?.locationName}
                  </h4>
                </div>
                <img
                  src={lineChart}
                  width={140}
                  alt="..."
                  className="ms-n4 d-md-none d-lg-block"
                />
              </Flex>
            </Col>
            <Col md="auto" className="mb-3 mb-md-0">
              <Row className="g-3 gy-md-0 h-100 align-items-center">
                <Col xs="auto">
                  <h6 className="text-700 mb-0 text-nowrap">Location</h6>
                </Col>
                <Col md="auto">
                  <Form.Select
                    size="sm"
                    aria-label="Default select"
                    name="idLocation"
                    onChange={selectedOption => {
                      setIdLocation(`${selectedOption.target.value}`);
                    }}
                    value={`${idLocation}`}
                  >
                    {!!locations?.length &&
                      locations.map(location => (
                        <option
                          key={location.idLocation}
                          value={location.idLocation}
                        >
                          {location.locationName}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <Row className="g-3 mb-3">
            {services.map((stat, index) => (
              <Col key={index} sm={index === 2 ? 12 : 6} md={4}>
                <StatisticsCard
                  onClick={() => {
                    setIdStatusSelected(0);
                    setIdStatusSelected(stat.idStatus);
                  }}
                  stat={stat}
                  style={{ minWidth: '12rem' }}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Intelligence data={servicesList}></Intelligence>
      {/* <ServicesListByStatus idLocation={1} idStatusService={1} /> */}
    </>
  );
};

export default DashboardByLocation;
