import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import BtnExportToExcell from 'form-components/ExportToExcel';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate, FormatDateToDb } from 'utils/FormatData';
import ServiceFilters from './Filters/ServicesFilters';
import { PDFDownloadLink } from '@react-pdf/renderer';
import IconButton from 'components/common/IconButton';
import ServicesReportPdf from './Generic/ServicesReportPdf';
const URI = ApiEndpoint + 'reports/vehiclesparts';
const columns = [
  {
    accessor: 'service',
    Header: 'Service',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>{String('0000000' + rowData.row.original.idservice).slice(-7)}</>
      );
    }
  },
  {
    accessor: 'shortname',
    Header: `${i18next.t('label.Customer')}`,
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'vin',
    Header: 'Vin',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'datecreate',
    Header: 'Service Date',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatDate(rowData.row.original.datecreate) || ''}</>;
    }
  },
  {
    accessor: 'partcode',
    Header: 'Part Code',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'partnumber',
    Header: 'Part Number',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'serialnumber',
    Header: 'Serial Number',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'quantity',
    Header: 'Quantity',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.quantity)}</>;
    }
  },
  {
    accessor: 'price',
    Header: 'Price',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.price)}</>;
    }
  },
  {
    accessor: 'total',
    Header: 'Total Parts',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.total)}</>;
    }
  }  
];

const ServicePartsReport = () => {
    const [customerIdFilter, setCustomerIdFilter] = useState(0);
    const [idStatusFilter, setIdStatusFilter] = useState(0);
    const [fromFilter, setFromFilter] = useState(new Date());
    const [toFilter, setToFilter] = useState(new Date());
    const [services, setServices] = useState(null);
    const [servicesSummary, setServicesSummary] = useState([]);
    const [vehicleIdFilter, setVehicleIdFilter] = useState(0);

    const getServices = async () => {
        setServices(null);        
          const res = await axios.post(URI, {
            idcustomer: customerIdFilter,
            idvehicle: vehicleIdFilter,
            del: FormatDateToDb(fromFilter),
            al: FormatDateToDb(toFilter)
          });
          setServices(res.data.length > 0 ? res.data : []);       
      };

      useEffect(() => {
        setServices([]);
      }, [ ]);
  return (
    <>
    <ServiceFilters
      setLocationIdFilter={()=>{}}
      locationIdFilter={0}
      customerIdFilter={customerIdFilter}
      setCustomerIdFilter={setCustomerIdFilter}
      setIdStatusFilter={setIdStatusFilter}
      idStatusFilter={idStatusFilter}
      fromFilter={fromFilter}
      setFromFilter={setFromFilter}
      toFilter={toFilter}
      setToFilter={setToFilter}
      setVehicleIdFilter={setVehicleIdFilter}
      vehicleIdFilter={vehicleIdFilter}
      onlySummary={false}
      setOnlySummary={()=>{}}
      action={getServices}
      includeSummaryBtn={false}
    ></ServiceFilters>
    <Card className="mb-3">
      <Card.Header>
        <Row className="flex-between-center">
          <Col   xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions">
              { services && services.length > 0 && (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="print"
                  transform="shrink-3"
                >
                  <PDFDownloadLink
                    document={
                      <ServicesReportPdf
                        period={
                          FormatDate(fromFilter) +
                          ' - ' +
                          FormatDate(toFilter)
                        }
                        reportData={services.map(service => {
                          return {
                            idservice: String('0000000' + service.idservice).slice(
                                -7
                              ),
                              shortname: service.shortname,
                              vin: service.vin,
                              datecreate: FormatDate(service.datecreate),
                              partcode: service.partcode,
                              partnumber: service.partnumber,
                              serialnumber: service.serialnumber,
                              quantity: { v: service.quantity, t: 'n' },
                              price: { v: service.price, t: 'n' },
                              total: { v: service.total, t: 'n' }
                          };
                        })}
                      />
                    }
                    fileName={'report_services.pdf'}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Print'
                    }
                  </PDFDownloadLink>
                </IconButton>
              )}
              {services && services.length > 0 && (
                <BtnExportToExcell
                  dataReport={services.map(service => {
                    return {
                      idservice: String('0000000' + service.idservice).slice(
                        -7
                      ),
                      shortname: service.shortname,
                      vin: service.vin,
                      datecreate: FormatDate(service.datecreate),
                      partcode: service.partcode,
                      partnumber: service.partnumber,
                      serialnumber: service.serialnumber,
                      quantity: { v: service.quantity, t: 'n' },
                      price: { v: service.price, t: 'n' },
                      total: { v: service.total, t: 'n' }
                    };
                  })}
                  fileName={'report_services'}
                ></BtnExportToExcell>
              )}
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        { services && services.length > 0 && (
          <AdvanceTableWrapper
            columns={columns}
            data={services}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        )}      
      </Card.Body>
    </Card>
  </>
  )
}

export default ServicePartsReport