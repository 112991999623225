import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { ApiEndpoint, defaultIdCompany } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import AlertNotification from 'form-components/AlertNotification';
import MyModal from 'shared/Modal';
import EditVehicle from 'Catalogs/Administration/_EditVehicle';
import IconButton from 'components/common/IconButton';
import { useAuth } from 'context/AuthProvider';

const URI = ApiEndpoint + 'vehicles/';

const CreateService = () => {
  const Auth = useAuth();
  const [customers, setCustomers] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [locations, setLocations] = useState(null);
  const [validated, setValidated] = useState(false);
  const [serviceId, setServiceId] = useState('');
  const [customerId, setCustomerId] = useState(null);
  const [vehicleId, setVehicleId] = useState(0);
  const [locationId, setLocationId] = useState(0);
  const [odometer, setOdometer] = useState(0);
  const [recibe, setRecibe] = useState('');
  const [comments, setComments] = useState('');
  const [received, setReceived] = useState(new Date());
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [openModalVehicle, setOpenModalVehicle] = useState(false);
  let navigate = useNavigate();
  const currentId = uuidv4();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idCustomer: '0',
      idVehicle: '0',
      idLocation: '0',
      odometer: '0',
      recibe: '',
      comments: '',
      received: new Date()
    }
  });
  const [values, setValues] = useState({
    idCustomer: '0',
    idVehicle: '0',
    idLocation: '0',
    odometer: '0',
    recibe: '',
    comments: '',
    received: new Date()
  });

  const getCustomers = async () => {
    const URICustomers = ApiEndpoint + 'customers/';
    const res = await axios.get(URICustomers);
    setCustomers(res.data);
  };

  const getVehicles = async () => {
    const UriVehicles = ApiEndpoint + 'customervehicles/';
    if (customerId > 0) {
      const res = await axios.get(UriVehicles + customerId);
      const vehiclesList = res.data.map(item => {
        return {
          value: item.idVehicle,
          label: `${item.vin} - ${item.make} - ${item.year}`
        };
      });
      setVehicles(vehiclesList);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const getLocationsList = async () => {
    const UriLocations = ApiEndpoint + 'locations/';
    const res = await axios.get(UriLocations);
    setLocations(res.data);
  };

  useEffect(() => {
    getVehicles();
  }, [customerId]);

  useEffect(() => {
    getCustomers();
    getLocationsList();
  }, []);

  const onSubmit = (data, e) => {
    if (
      customerId == 0 ||
      vehicleId == 0 ||
      locationId == 0 ||
      recibe == '' ||
      odometer == '0'
    ) {
      setAlertMessage(i18next.t('label.MustComplete'));
      setTypeAlert('warning');
      setOpenAlert(true);
      return;
    }

    const form = e.target;
    e.preventDefault();
    if (form.checkValidity() === false) {
      setValidated(false);
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    const userData = Auth.getSessionData();
    setValidated(true);
    const dataToSubmit = {
      idCustomer: customerId,
      idVehicle: vehicleId,
      idLocation: locationId,
      comments: comments,
      recibe: recibe,
      idCompany: defaultIdCompany,
      odometer: odometer,
      datereceived: received,
      user: userData.iduser
    };
    const URI = ApiEndpoint + 'service/save/' + currentId;
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        if (!response.error) {
          let path = `/ServiceDetail/` + currentId;
          navigate(path);
        } else {
          setAlertMessage(i18next.t('label.Error') + ':' + response.message);
          setTypeAlert('warning');
          setOpenAlert(true);
        }
      })
      .catch(function (error) {
        setAlertMessage(i18next.t('label.Error') + ':' + error);
        setTypeAlert('warning');
        setOpenAlert(true);
        console.log(error);
      });
  };

  const handleCloseVehicle = () => {
    setOpenModalVehicle(false);
    getVehicles();
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Form.Group>
                <Form.Label>{i18next.t('label.Customer')}</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  size="sm"
                  name="customerId"
                  required={true}
                  onChange={selectedOption => {
                    setCustomerId(`${selectedOption.target.value}`);
                  }}
                  value={`${customerId}`}
                >
                  <option key={'customer_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!customers?.length &&
                    customers.map(customer => (
                      <option
                        key={customer.idcustomer}
                        value={customer.idcustomer}
                      >
                        {customer.shortname}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label>{i18next.t('label.Vehicle')}</Form.Label>
                <div style={{ width: '250px' }}>
                  <Select
                    aria-label="Default select"
                    name="vehicleId"
                    size="sm"
                    required={true}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    value={selectedVehicle}
                    onChange={value => {
                      setVehicleId(value.value);
                      setSelectedVehicle(value);
                    }}
                    options={vehicles}
                  ></Select>
                </div>
                {customerId && customerId > 0 && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    onClick={() => {
                      setOpenModalVehicle(true);
                    }}
                  >
                    {i18next.t('label.Add')}
                  </IconButton>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label> {i18next.t('label.Location')}</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  name="patio"
                  required={true}
                  style={{ minWidth: '250px' }}
                  onChange={selectedOption => {
                    setLocationId(`${selectedOption.target.value}`);
                  }}
                >
                  <option key={'location_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!locations?.length &&
                    locations.map(location => (
                      <option
                        key={location.idLocation}
                        value={location.idLocation}
                      >
                        {location.locationName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <FormInputText
                label={i18next.t('label.Receives')}
                name={'recibe'}
                control={control}
                required={true}
                defaultValue={recibe}
                changeHandler={event => {
                  setRecibe(event.target.value);
                }}
              ></FormInputText>
            </Row>
            <Row>
              <FormInputText
                label={i18next.t('label.Comments')}
                name={'comments'}
                control={control}
                defaultValue={comments}
                changeHandler={event => {
                  setComments(event.target.value);
                }}
              ></FormInputText>
            </Row>
            <Row>
              <FormInputText
                label={i18next.t('label.Odometer')}
                name={'odometer'}
                control={control}
                required={true}
                defaultValue={odometer}
                changeHandler={event => {
                  setOdometer(event.target.value);
                }}
              ></FormInputText>
            </Row>
            <Row>
              <Col style={{ alignContent: 'right' }}>
                <span className="input-group-text" id="inputGroup-sizing-sm">
                  {i18next.t('label.ReceivedDate')}
                </span>
              </Col>
              <Col style={{ alignContent: 'left' }}>
                <ReactDatePicker
                  className="mb-3"
                  required={true}
                  selected={received}
                  size="sm"
                  onChange={date => setReceived(date)}
                  placeholderText="Select Date"
                  dateFormat={'MM/dd/yyyy'}
                />
              </Col>
            </Row>
            <Row>
              <Button type="submit" color="primary" size="sm">
                {i18next.t('label.Save')}
              </Button>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      {openAlert && (
        <AlertNotification
          open={openAlert}
          handleClose={handleCloseAlert}
          type={typeAlert}
          message={alertMessage}
        />
      )}
      {openModalVehicle && customerId && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.Add') + i18next.t('label.Vehicle')}
          openModal={openModalVehicle}
          closeModal={handleCloseVehicle}
        >
          <EditVehicle
            closeModal={handleCloseVehicle}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
            idCustomer={customerId}
            idVehicle={0}
          />
        </MyModal>
      )}
    </>
  );
};
export default CreateService;
