import axios from 'axios';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { EditServiceContext } from 'context/Context';
import EditServiceContextProvider from 'context/_EditServiceContextProvider';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import MyModal from 'shared/Modal';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import ServiceFailures from './ServiceFailures3';
import EditServiceStatus from './_EditServiceStatus';
import ServiceDetailsList from './_ServiceDetailsList';
import ServiceFilesList from './_ServiceFilesList';
import ServiceLaborsList from './_ServiceLaborsList';
import ServicePartsList from './_ServicePartsList';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PreviewPrint from 'Catalogs/Formatos/PreviewPrint';
import { useAuth } from 'context/AuthProvider';
import EditAsignService from './Partials/AsignThecnician';
import ServiceTasks from './ServiceTasks';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ServiceTaskPreview from 'Catalogs/Formatos/ServiceTaskPreview';

const DetailService = () => {
  const [service, setService] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [idService, setIdService] = useState(null);
  const [isThecnician, setIsThecnician] = useState(false);
  const [idLocationSel, setIdLocationSel] = useState(false);
  const [isAsignable, setIsAsignable] = useState(null);
  const [isEditable, setIsEditable] = useState(null);
  const [isFinished, setIsFinished] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [idVehicle, setIdVehicle] = useState(null);
  const [totalService, setTotalService] = useState(0);
  const [openModalOrder, setOpenModalOrder] = useState(false);
  const [openModalTaks, setOpenModalTaks] = useState(false);
  const [asignService, setAsignService] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  let { id } = useParams();
  const Auth = useAuth();

  const URI = ApiEndpoint + 'services/' + id;

  const handleClose = () => {
    setOpenModal(false);
    setChangeStatus(false);
    setAsignService(false);
    window.location.reload();
  };

  const handleCloseOrder = () => {
    setOpenModalOrder(false);
  };

  const handleCloseTask = () => {
    setOpenModalTaks(false);
  };
  //mostrar companies

  useEffect(() => {
    const userData = Auth.getSessionData();
    console.log('userData');
    console.log(userData);
    if (userData.idemployee && userData.idemployee > 0) {
      setIsThecnician(true);
    }
    if (userData.idusertype === 1) {
      setIsAdmin(true);
      setIsEditable(true);
      setIsFinished(false);
    }
    getService();
  }, []);

  const getService = () => {
    setIsEditable(true);
    setIsFinished(false);
    setIsAsignable(false);
    axios.get(URI).then(response => {
      let serviceNew = response.data[0];
      setIdService(serviceNew.idservice);
      setService(serviceNew);
      setIdVehicle(serviceNew.idvehicle);
      setIdLocationSel(serviceNew.idlocation);
      if (
        serviceNew.idservicestatus == 3 ||
        serviceNew.idservicestatus == 4 ||
        serviceNew.idservicestatus == 5
      ) {
        setIsEditable(false);
      }
      if (serviceNew.idservicestatus == 5 || serviceNew.idservicestatus == 4) {
        setIsFinished(true);
      }
      if (
        (serviceNew.idservicestatus == 1 || serviceNew.idservicestatus == 2) &&
        (!serviceNew.idtechnician || serviceNew.idtechnician == 0)
      ) {
        setIsAsignable(true);
      }

      let totalLabors = getTotalLaborPrice(serviceNew.servicelabors ?? []);
      let totalParts = getTotalPartsPrice(serviceNew.serviceparts ?? []);

      setTotalService(totalLabors + totalParts);
      setLastRefresh(new Date());
    });
  };

  const getTotalLaborPrice = items => {
    return items
      .filter(item => !item.isincluded)
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };

  const getTotalPartsPrice = items =>
    items
      .filter(item => item?.chargetocustomer == true)
      .map(({ quantity, price }) => Number(quantity) * Number(price))
      .reduce((total, currentValue) => Number(total) + Number(currentValue), 0);

  return (
    <EditServiceContextProvider>
      <>
        <PageHeader
          title={
            i18next.t('label.ServiceDetail') +
            ' - ' +
            String('0000000' + service.idservice).slice(-7)
          }
          titleTag="h5"
          className="mb-3"
        >
          <>
            <Row>
              <Col>
                <p className="mb-3">Created {FormatDate(service.datecreate)}</p>
              </Col>
              <Col>
                <p className="mb-3">
                  {isFinished
                    ? 'Finish Date' + FormatDate(service.datefinished)
                    : ''}
                </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: 'left' }}>
                Status:
                <SoftBadge
                  pill
                  icon="arrow-down"
                  bg={classNames({
                    success: service.idservicestatus === 1,
                    primary: service.idservicestatus === 2,
                    warning: service.idservicestatus === 3,
                    secondary: service.idservicestatus === 4,
                    info: service.idservicestatus === 5,
                    black:
                      service.idservicestatus > 5 || service.idservicestatus < 1
                  })}
                  className="me-2"
                >
                  {service.idservicestatus === 1 &&
                    `${i18next.t('status.InProccess')}`}
                  {service.idservicestatus === 2 &&
                    `${i18next.t('status.InLocation')}`}
                  {service.idservicestatus === 3 &&
                    `${i18next.t('status.InExternalService')}`}
                  {service.idservicestatus === 4 &&
                    `${i18next.t('status.Canceled')}`}
                  {service.idservicestatus === 5 &&
                    `${i18next.t('status.Completed')}`}
                  <FontAwesomeIcon
                    icon={classNames({
                      check:
                        service.idservicestatus ===
                        `${i18next.t('status.Completed')}`,
                      redo:
                        service.idservicestatus ===
                        `${i18next.t('status.InLocation')}`,
                      stream:
                        service.idservicestatus ===
                        `${i18next.t('status.InExternalService')}`,
                      ban:
                        service.idservicestatus ===
                        `${i18next.t('status.Canceled')}`,
                      highlight:
                        service.idservicestatus ===
                        `${i18next.t('status.InProccess')}`
                    })}
                    transform="shrink-2"
                    className="ms-1"
                  />
                </SoftBadge>
                {((lastRefresh && !isFinished && !isThecnician) || isAdmin) && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-down"
                    iconClassName="me-1"
                    onClick={() => {
                      setChangeStatus(true);
                    }}
                  >
                    {i18next.t('label.ChangeStatus')}
                  </IconButton>
                )}
              </Col>
            </Row>
          </>
        </PageHeader>
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col md={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Customer</h5>
                <h6 className="mb-2">{service.customer?.shortname}</h6>
                <p className="mb-1 fs--1">{service.customer?.address}</p>
                <p className="mb-0 fs--1">
                  <strong>Email: {service.customer?.email}</strong>
                </p>
                <p className="mb-0 fs--1">
                  <strong>Phone: {service.customer?.phone}</strong>
                </p>
              </Col>
              <Col md={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">{i18next.t('label.Vehicle')}</h5>
                <h6 className="mb-2">{service.vehicle?.vin}</h6>
                <p className="mb-0 fs--1">
                  {service.vehicle?.year ?? ''}-{service.vehicle?.make ?? ''}-
                  {service.vehicle?.model ?? ''}
                </p>
                <div className="text-500 fs--1">
                  Unit #: {service.vehicle?.unit ?? ''}
                </div>

                {isAsignable && (
                  <>
                    {isThecnician ? (
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-down"
                        iconClassName="me-1"
                        onClick={() => {
                          setAsignService(true);
                        }}
                      >
                        {i18next.t('label.TakeService')}
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-down"
                        iconClassName="me-1"
                        onClick={() => {
                          setAsignService(true);
                        }}
                      >
                        {i18next.t('label.AsignService')}
                      </IconButton>
                    )}
                  </>
                )}
              </Col>
              <Col md={4}>
                <h5 className="mb-3 fs-0">{i18next.t('label.Total')}</h5>
                <div className="d-flex">
                  <div className="flex-1">
                    <h4 className="text-primary mb-0">
                      {FormatCurrency(totalService)}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col xs="12" style={{ textAlign: 'right' }}>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="arrow-down"
              className="me-1 mb-2 mb-sm-0"
              iconClassName="me-1"
              onClick={() => {
                setOpenModalOrder(true);
              }}
            >
              Print
            </IconButton>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="print"
              iconClassName="me-1"
              className="me-1 mb-2 mb-sm-0"
            >
              Invoice
            </IconButton> */}
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={12}>
            <FalconComponentCard
              noGuttersBottom
              style={{ minHeight: 500, overflow: 'auto' }}
            >
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceLaborsList
                      isEditable={isEditable}
                      isFinished={isFinished}
                      idService={idService}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FalconComponentCard
              noGuttersBottom
              style={{ minHeight: 500, overflow: 'auto' }}
            >
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServicePartsList
                      isEditable={isEditable}
                      isFinished={isFinished}
                      serviceGuid={id}
                      idService={idService}
                      idVehicle={idVehicle}
                      idThecnician={service.idtechnician}
                      idLocation={idLocationSel}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <FalconComponentCard
              noGuttersBottom
              style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
            >
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceFilesList
                      isEditable={isEditable}
                      isFinished={isFinished}
                      serviceGuid={id}
                      idService={idService}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
          <Col lg={4}>
            <FalconComponentCard
              noGuttersBottom
              style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
            >
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceDetailsList
                      isEditable={isEditable}
                      isFinished={isFinished}
                      idService={idService}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col lg={12}>
            <FalconComponentCard
              noGuttersBottom
              style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
            >
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceTasks
                      isEditable={isEditable}
                      isFinished={isFinished}
                      idService={idService}
                      printFunction={setOpenModalTaks}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col lg={12}>
            <FalconComponentCard
              noGuttersBottom
              style={{ minHeight: 500, maxHeight: 500, overflow: 'auto' }}
            >
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceFailures
                      isEditable={isEditable}
                      isFinished={isFinished}
                      idService={idService}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        {openModalTaks && (
          <MyModal
            id="id_myModal2"
            closeModal={handleCloseTask}
            title={i18next.t('label.Print')}
            openModal={openModalTaks}
          >
            <ServiceTaskPreview
              idService={`${id}`}
              currentId={idService}
              closeModal={handleCloseTask}
            ></ServiceTaskPreview>
            {/* <ServiceOrder
              service={service}
              idService={idService}
              closeModal={handleCloseOrder}
            /> */}
          </MyModal>
        )}
        {openModalOrder && (
          <MyModal
            id="id_myModal"
            closeModal={handleCloseOrder}
            title={i18next.t('label.Print')}
            openModal={openModalOrder}
          >
            <PreviewPrint
              idService={`${id}`}
              currentId={idService}
              closeModal={handleCloseOrder}
            ></PreviewPrint>
            {/* <ServiceOrder
              service={service}
              idService={idService}
              closeModal={handleCloseOrder}
            /> */}
          </MyModal>
        )}
        {changeStatus && (
          <MyModal
            id="id_myModalStatus"
            closeModal={handleClose}
            title={i18next.t('label.Status')}
            openModal={changeStatus}
          >
            <EditServiceStatus
              service={service}
              idService={idService}
              closeModal={handleClose}
            />
          </MyModal>
        )}
        {asignService && (
          <MyModal
            id="id_myModalAsign"
            closeModal={handleClose}
            title={i18next.t('label.Asign')}
            openModal={asignService}
          >
            <EditAsignService
              service={service}
              idService={idService}
              closeModal={handleClose}
            />
          </MyModal>
        )}
      </>
    </EditServiceContextProvider>
  );
};

export default DetailService;
