import axios from 'axios';
import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';

const EditBankAccount = ({
  idBankAccount,
  closeModal,
  setOpenAlert,
  setTypeAlert,
  setAlertMessage
}) => {
  const [validated, setValidated] = useState(false);
  const [bankAccount, setBankAccount] = useState(values);
  const [accountTypes, setAccountTypes] = useState(null);
  const [idaccounttype, setIdaccounttype] = useState(null);
  const URI = ApiEndpoint + 'bankaccount/';
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      bankaccount: '',
      idaccounttype: '',
      accountnumber: '',
      description: '',
      aba: '',
      ismain: 'false',
      bank: ''
    }
  });
  const [values, setValues] = useState({
    bankaccount: '',
    idaccounttype: '',
    accountnumber: '',
    description: '',
    aba: '',
    ismain: 'false',
    bank: ''
  });

  useEffect(() => {
    const uriSup = ApiEndpoint + 'accounttypes/';
    axios(uriSup).then(({ data }) => {
      const accountTps = data.map(accType => {
        return {
          value: `${accType.idaccounttype}`,
          label: `${accType.accounttype}`
        };
      });
      setAccountTypes(accountTps);
    });
  }, []);

  useEffect(() => {
    axios.get(URI + idBankAccount).then(response => {
      setBankAccount(response.data);
    });
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    if (idBankAccount > 0) {
      axios
        .put(URI + idBankAccount, {
          bankaccount: values.bankaccount,
          idaccounttype: values.idaccounttype,
          accountnumber: values.accountnumber,
          description: values.description,
          aba: values.aba,
          ismain: values.ismain,
          bank: values.bank
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          bankaccount: values.bankaccount,
          idaccounttype: values.idaccounttype,
          accountnumber: values.accountnumber,
          description: values.description,
          aba: values.aba,
          ismain: values.ismain,
          bank: values.bank
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const onChangeIsMain = event => {
    setValues({ ...values, ['ismain']: !values.ismain });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3" controlId="idaccounttype">
              <Form.Label>{i18next.t('label.AccountType')}</Form.Label>
              <Form.Select
                aria-label="Default select"
                name="idaccounttype"
                value={idaccounttype}
                style={{ minWidth: '250px' }}
                onChange={selectedOption => {
                  setIdaccounttype(`${selectedOption.target.value}`);
                  console.log(`${selectedOption.target.value}`);
                  onChange(selectedOption);
                }}
              >
                <option key={'acctype_0'} value={0}>
                  {i18next.t('label.SelectSomeValue')}
                </option>
                {!!accountTypes?.length &&
                  accountTypes.map(accType => (
                    <option key={accType.value} value={accType.value}>
                      {accType.label}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="bank">
              <FormInputText
                label={i18next.t('label.Bank')}
                changeHandler={onChange}
                name={'bank'}
                control={control}
                defaultValue={values.bank}
              ></FormInputText>
            </Form.Group>
            <Form.Group className="mb-3" controlId="bankaccount">
              <FormInputText
                label={i18next.t('label.BankAccount')}
                changeHandler={onChange}
                name={'bankaccount'}
                control={control}
                defaultValue={values.bankaccount}
              ></FormInputText>
            </Form.Group>
            <Form.Group className="mb-3" controlId="accountnumber">
              <FormInputText
                label={i18next.t('label.BankAccount')}
                changeHandler={onChange}
                name={'accountnumber'}
                control={control}
                defaultValue={values.accountnumber}
              ></FormInputText>
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <FormInputText
                label={i18next.t('label.Description')}
                changeHandler={onChange}
                name={'description'}
                control={control}
                defaultValue={values.description}
              ></FormInputText>
            </Form.Group>
            <Form.Group className="mb-3" controlId="aba">
              <FormInputText
                label={i18next.t('label.ABA')}
                changeHandler={onChange}
                name={'aba'}
                control={control}
                defaultValue={values.aba}
              ></FormInputText>
            </Form.Group>
            <Form.Group className="mb-3" controlId="ismain">
              <Form.Check
                type="switch"
                id="ismain"
                label={i18next.t('label.IsMain')}
                checked={values.ismain}
                onChange={onChangeIsMain}
              />
            </Form.Group>
            <Button type="submit" color="primary" size="sm">
              {i18next.t('label.Save')}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditBankAccount;
