import { useState } from 'react';
import { decryptData, encryptData } from 'utils/Encrypth';

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = decryptData(localStorage.getItem(keyName));
      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(
          keyName,
          encryptData(JSON.stringify(defaultValue))
        );
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = newValue => {
    try {
      localStorage.setItem(keyName, encryptData(JSON.stringify(newValue)));
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};
