import axios from 'axios';
import AlertNotification from 'form-components/AlertNotification';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import MyModal from 'shared/Modal';
import { ApiEndpoint } from 'utils/ApiEndPont';
import AddDetailServiceOnEdit from './_AddDetailServiceOnEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmAction from 'form-components/ConfirmationModal';

const ServiceDetailsList = ({ idService, isFinished, isEditable }) => {
  const [detailList, setDetailList] = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(0);
  const [idDetailToDelete, setIdDetailToDelete] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const URI = ApiEndpoint + 'services/details/';
  useEffect(() => {
    getServiceDetails();
  }, []);

  const getServiceDetails = () => {
    console.log(URI + idService);
    axios.get(URI + idService).then(response => {
      let details = response.data;
      console.log(details);
      setDetailList(details);
      // setIdsymptomcategory(response.data.idsymptomcategory);
    });
  };

  const handleClose = () => {
    setOpenModalDetail(false);
    getServiceDetails();
    window.location.reload();
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    getServiceDetails();
  };
  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    axios
      .delete(URI + idDetailToDelete)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        getServiceDetails();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <GenericTableHeader
            label={i18next.t('label.Details')}
            isFinished={isFinished}
            newFunction={() => {
              setOpenModalDetail(true);
            }}
          />
        </Card.Header>
        <Card.Body className="p-0" style={{ height: 350 }}>
          <ul className="list-group">
            {detailList.map((detail, index) => (
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                key={detail.idservicedetail}
              >
                <>
                  {' '}
                  {detail.detail}
                  <Button
                    variant="falcon-default"
                    size="sm"
                    disabled={!isEditable}
                    onClick={() => {
                      setIdDetailToDelete(detail.idservicedetail);
                      setOpenConfirm(true);
                    }}
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                </>
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card>
      {openModalDetail && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.Addfailure')}
          openModal={openModalDetail}
          closeModal={handleClose}
        >
          <AddDetailServiceOnEdit
            closeModal={handleClose}
            idService={idService}
          />
        </MyModal>
      )}{' '}
      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default ServiceDetailsList;
