import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FormatCurrency2Dec, FormatDate } from 'utils/FormatData';
import classNames from 'classnames';
import MyModal from 'shared/Modal';
import PreviewPayment from 'Catalogs/Formatos/PreviewPayment';

const ListPayments = ({ paymentsList, refreshServices }) => {
  const [paymentoPrint, setPaymentoPrint] = useState(0);
  const [openPrint, setOpenPrint] = useState(false);

  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const columns = [
    {
      accessor: 'idpayment',
      Header: 'Payment', //Header: 'Info'
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        return (
          <>
            <strong>
              #P-
              {String('0000000' + rowData.row.original.idpayment).slice(-7)}
            </strong>
            <p className="mb-0 text-500">
              Captured: {FormatDate(rowData.row.original.datecreate) || ''}
            </p>
            <p className="mb-0 text-500">
              {rowData.row.original.idpaymentstatus === 1 &&
                `Applied: ${FormatDate(rowData.row.original.datepayment)}`}
            </p>
          </>
        );
      }
    },
    {
      accessor: 'bankaccount',
      Header: 'Payment', //'Customer'
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <>
            <p>
              <strong>{rowData.row.original.bankaccount}</strong>
            </p>
            <p>
              <strong>
                Total Payment {FormatCurrency2Dec(rowData.row.original.total)}
              </strong>
            </p>
          </>
        );
      }
    },
    {
      accessor: 'customer',
      Header: 'Customer', //'Customer'
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <>
            <strong>{rowData.row.original.shortname}</strong>

            <p className="mb-0 text-500">{rowData.row.original.description}</p>
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const status = rowData.row.original.idpaymentstatus;
        return (
          <>
            <SoftBadge
              pill
              bg={classNames({
                success: status === 1,
                danger: status === 2
              })}
              className="d-block"
            >
              {status === 1 && `${i18next.t('status.Completed')}`}
              {status === 2 && `${i18next.t('status.Canceled')}`}
            </SoftBadge>
          </>
        );
      }
    },
    {
      accessor: 'Imprimir',
      Header: 'Imprimir',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const currentIdService = rowData.row.original.idpayment;
        return (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{i18next.t('label.Edit')}</Tooltip>}
          >
            <>
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  setPaymentoPrint(`${currentIdService}`);
                  setOpenPrint(true);
                }}
              >
                <FontAwesomeIcon icon="print" /> Print
              </Button>
            </>
          </OverlayTrigger>
        );
      }
    }
  ];
  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={paymentsList}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <>
              <h4>Payments</h4>
            </>
          </Card.Header>
          <Card.Body className="p-0">
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {openPrint && (
        <MyModal
          style={{ width: '100%' }}
          openModal={openPrint}
          closeModal={handleClosePrint}
          title="Service"
          size="xl"
        >
          <PreviewPayment
            idPayment={paymentoPrint}
            closeModal={handleClosePrint}
          ></PreviewPayment>
        </MyModal>
      )}
    </>
  );
};

export default ListPayments;
