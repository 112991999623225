import axios from 'axios';
import { useEffect, useState } from 'react';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDateToDb } from 'utils/FormatData';
import FilterServices from './Partials/_FilterServices';
import ListServicesComponent from 'components/franco/ListServicesComponent';

const ServicesList = () => {
  const [locationIdFilter, setLocationIdFilter] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [vehicleIdFilter, setVehicleIdFilter] = useState(0);
  const [filterFolio, setFilterFolio] = useState(0);
  const [fromFilter, setFromFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toFilter, setToFilter] = useState(new Date());
  const [services, setServices] = useState([]);

  //mostrar companies /services/filters
  const getServices = async () => {
    const URI2 = ApiEndpoint + 'servicesfilters/';
    const res = await axios.post(URI2 + 'filters', {
      idcompany: 0,
      idcustomer: customerIdFilter,
      idvehicle: vehicleIdFilter,
      idlocation: locationIdFilter,
      idstatus: idStatusFilter,
      del: FormatDateToDb(fromFilter),
      al: FormatDateToDb(toFilter),
      folio: Number(filterFolio)
    });
    setServices(res.data.length > 0 ? res.data : []);
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      <FilterServices
        setLocationIdFilter={setLocationIdFilter}
        locationIdFilter={locationIdFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        action={getServices}
        setVehicleIdFilter={setVehicleIdFilter}
        vehicleIdFilter={vehicleIdFilter}
        filterFolio={filterFolio}
        setFilterFolio={setFilterFolio}
      ></FilterServices>
      <ListServicesComponent services={services}></ListServicesComponent>
    </>
  );
};

export default ServicesList;
