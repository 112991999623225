import {
  Page,
  Document,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer';

import { useEffect, useState } from 'react';
import HeaderPdf from 'shared/HeaderPdf';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
const BORDER_COLOR = '#000';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  headerDiv: {
    'text-align': 'center!important',
    'margin-bottom': '1rem!important',
    display: 'flex',
    'flex-wrap': 'wrap'
  },
  logoheader: {
    'text-align': 'left!important',
    flex: '0 0 auto',
    width: '16.66666667%'
  },
  textHeader: {
    'font-size': '.8333333333rem!important'
  },
  em: {
    fontStyle: 'bold'
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  headerBg: {
    // backgroundColor: '#aaa'
  },
  table: {
    display: 'table',
    width: '50%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row'
  },

  tableCellHeader: {
    fontSize: 10,
    fontWeight: 'bold'
    // fontFamily: "CustomRoboto",
  },
  tableCell: {
    margin: 1,
    fontSize: 9
    // fontFamily: "CustomRoboto",
  },
  textCenter: {
    textAlign: 'center'
  }
});
let tableCol = {
  borderStyle: 'solid',
  borderColor: '#dcdcdc',
  borderBottomColor: '#000',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0.5
};
// Create Document Component
const ServiceReportSummaryPdf = ({ reportData, period }) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={{ padding: '15px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Image
                style={{ width: '100px' }}
                source="/css/falcon.png"
                //assets/img/illustrations/falcon.png
              />
            </View>
            <View
              style={{
                flex: 3,
                alignItems: 'left',
                textAlign: 'left'
              }}
            >
              <HeaderPdf></HeaderPdf>
            </View>
          </View>
          <View key={'title'} style={[tableCol, { width: '100%' }]}>
            <Text
              style={{
                margin: 2,
                fontSize: 12,
                textAlign: 'center'
              }}
            >
              Summary Services By Period (Summary)
            </Text>
          </View>
          <View key={'title'} style={[tableCol, { width: '100%' }]}>
            <Text
              style={{
                margin: 1,
                fontSize: 9,
                textAlign: 'center'
              }}
            >
              Period: {period}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 4, height: 1, backgroundColor: 'black' }} />
          </View>
          <View
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            <>
              <View key={'title1'} style={styles.tableRow}>
                <View
                  key={'title2'}
                  style={[styles.tableCellHeader, { width: '6%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Service
                  </Text>
                </View>
                <View
                  key={'title3'}
                  style={[styles.tableCellHeader, { width: '7%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Created
                  </Text>
                </View>
                <View
                  key={'title4'}
                  style={[styles.tableCellHeader, { width: '12%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Location
                  </Text>
                </View>
                <View
                  key={'title5'}
                  style={[styles.tableCellHeader, { width: '15%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Customer
                  </Text>
                </View>
                <View
                  key={'title6'}
                  style={[styles.tableCellHeader, { width: '12%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Vehicle
                  </Text>
                </View>
                <View
                  key={'title7'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    % Tax
                  </Text>
                </View>
                <View
                  key={'title8'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Parts
                  </Text>
                </View>
                <View
                  key={'title9'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Labors
                  </Text>
                </View>
                <View
                  key={'title9'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Discount
                  </Text>
                </View>
                <View
                  key={'title10'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Subtotal
                  </Text>
                </View>
                <View
                  key={'title11'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Tax
                  </Text>
                </View>
                <View
                  key={'title12'}
                  style={[styles.tableCellHeader, { width: '10%' }]}
                >
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'center'
                    }}
                  >
                    Total
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{ flex: 4, height: 1, backgroundColor: 'black' }}
                />
              </View>
              {reportData &&
                reportData.map((item, idx) => (
                  <View key={idx} style={styles.tableRow}>
                    <View key={idx} style={[tableCol, { width: '6%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'left'
                        }}
                      >
                        {item.idservice}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '7%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'left'
                        }}
                      >
                        {item.datecreate}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '12%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'left'
                        }}
                      >
                        {item.locationName}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '15%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'left'
                        }}
                      >
                        {item.company}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '12%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'left'
                        }}
                      >
                        {item.vin}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '5%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'left'
                        }}
                      >
                        {item.tax} %
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '10%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'right'
                        }}
                      >
                        {FormatCurrency(Number(item.parts))}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '10%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'right'
                        }}
                      >
                        {FormatCurrency(Number(item.labors))}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '10%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'right'
                        }}
                      >
                        {FormatCurrency(Number(item.laborsdiscount))}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '10%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'right'
                        }}
                      >
                        {FormatCurrency(Number(item.subtotal))}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '10%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'right'
                        }}
                      >
                        {FormatCurrency(Number(item.totaltax))}
                      </Text>
                    </View>
                    <View key={idx} style={[tableCol, { width: '10%' }]}>
                      <Text
                        style={{
                          margin: 1,
                          fontSize: 8,
                          textAlign: 'right'
                        }}
                      >
                        {FormatCurrency(Number(item.total))}
                      </Text>
                    </View>
                  </View>
                ))}
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{ flex: 5, height: 1, backgroundColor: 'black' }}
                />
              </View>
              <View key={'rowtotal'} style={styles.tableRow}>
                <View key={'idx_total1'} style={[tableCol, { width: '58%' }]}>
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 10,
                      textAlign: 'right'
                    }}
                  >
                    Total
                  </Text>
                </View>
                <View key={'idx_total2'} style={[tableCol, { width: '10%' }]}>
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'right'
                    }}
                  >
                    {FormatCurrency(
                      reportData.reduce(
                        (total, currentValue) =>
                          (total = total + Number(currentValue.parts)),
                        0
                      )
                    )}
                  </Text>
                </View>
                <View key={'idx_total3'} style={[tableCol, { width: '10%' }]}>
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'right'
                    }}
                  >
                    {FormatCurrency(
                      reportData.reduce(
                        (total, currentValue) =>
                          (total = total + Number(currentValue.labors)),
                        0
                      )
                    )}
                  </Text>
                </View>
                <View key={'idx_total5'} style={[tableCol, { width: '10%' }]}>
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'right'
                    }}
                  >
                    {FormatCurrency(
                      reportData.reduce(
                        (total, currentValue) =>
                          (total = total + Number(currentValue.subtotal)),
                        0
                      )
                    )}
                  </Text>
                </View>
                <View key={'idx_total4'} style={[tableCol, { width: '10%' }]}>
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'right'
                    }}
                  >
                    {FormatCurrency(
                      reportData.reduce(
                        (total, currentValue) =>
                          (total = total + Number(currentValue.totaltax)),
                        0
                      )
                    )}
                  </Text>
                </View>
                <View key={'idx_total3'} style={[tableCol, { width: '10%' }]}>
                  <Text
                    style={{
                      margin: 1,
                      fontSize: 8,
                      textAlign: 'right'
                    }}
                  >
                    {FormatCurrency(
                      reportData.reduce(
                        (total, currentValue) =>
                          (total = total + Number(currentValue.total)),
                        0
                      )
                    )}
                  </Text>
                </View>
              </View>
            </>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ServiceReportSummaryPdf;
