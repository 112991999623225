import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import BtnExportToExcell from 'form-components/ExportToExcel';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import {
  FormatCurrency,
  FormatCurrency2Dec,
  FormatDate,
  FormatDateToDb
} from 'utils/FormatData';
import ServiceFilters from './Filters/ServicesFilters';
import LaborsFilters from './Filters/_LaborsFilters';
import LaborsReportPdf from './Generic/LaborsReportPdf';
import ServicesReportPdf from './Generic/ServicesReportPdf';
import ServiceReportSummary from './ServicesReports/ServiceReportSummary';

const URI = ApiEndpoint + 'services/';
const columns = [
  {
    accessor: 'service',
    Header: 'Service',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>{String('0000000' + rowData.row.original.idservice).slice(-7)}</>
      );
    }
  },
  {
    accessor: 'shortname',
    Header: `${i18next.t('label.Customer')}`,
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'locationName',
    Header: 'Location',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'vin',
    Header: 'Vin',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'datecreate',
    Header: 'Service Date',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatDate(rowData.row.original.datecreate) || ''}</>;
    }
  },
  {
    accessor: 'Labors',
    Header: 'Total Labors',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.Labors)}</>;
    }
  },
  {
    accessor: 'Parts',
    Header: 'Total Parts',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.Parts)}</>;
    }
  },
  {
    accessor: 'PartsTaxables',
    Header: 'Total Parts Tax',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.PartsTaxables)}</>;
    }
  },
  {
    accessor: 'totalServiceCustomer',
    Header: 'Total Service',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.totalServiceCustomer)}</>;
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  }
];

const ServicesReport = () => {
  const [locationIdFilter, setLocationIdFilter] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(new Date());
  const [toFilter, setToFilter] = useState(new Date());
  const [services, setServices] = useState(null);
  const [servicesSummary, setServicesSummary] = useState([]);
  const [vehicleIdFilter, setVehicleIdFilter] = useState(0);
  const [onlySummary, setOnlySummary] = useState(0);
  //mostrar companies /services/filters
  const getServices = async () => {
    setServices(null);
    setServicesSummary(null);
    const URI2 = ApiEndpoint + 'reports/';
    if (onlySummary) {
      const res = await axios.post(URI2 + 'servicessummary', {
        idcompany: 0,
        idcustomer: customerIdFilter,
        idvehicle: vehicleIdFilter,
        idlocation: locationIdFilter,
        idstatus: idStatusFilter,
        del: FormatDateToDb(fromFilter),
        al: FormatDateToDb(toFilter)
      });
      console.log('setServices');
      console.log(res.data);
      setServicesSummary(res.data);
    } else {
      const res = await axios.post(URI2 + 'services', {
        idcompany: 0,
        idcustomer: customerIdFilter,
        idvehicle: vehicleIdFilter,
        idlocation: locationIdFilter,
        idstatus: idStatusFilter,
        del: FormatDateToDb(fromFilter),
        al: FormatDateToDb(toFilter)
      });
      setServices(res.data.length > 0 ? res.data : []);
    }
  };

  useEffect(() => {
    setServices([]);
  }, [onlySummary]);

  const total =
    onlySummary || !services
      ? 0
      : services.reduce(
          (total, currentValue) =>
            (total = total + Number(currentValue.totalServiceCustomer)),
          0
        );

  return (
    <>
      <ServiceFilters
        setLocationIdFilter={setLocationIdFilter}
        locationIdFilter={locationIdFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        setVehicleIdFilter={setVehicleIdFilter}
        vehicleIdFilter={vehicleIdFilter}
        onlySummary={onlySummary}
        setOnlySummary={setOnlySummary}
        action={getServices}
      ></ServiceFilters>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                {!onlySummary && services && services.length > 0 && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    transform="shrink-3"
                  >
                    <PDFDownloadLink
                      document={
                        <ServicesReportPdf
                          period={
                            FormatDate(fromFilter) +
                            ' - ' +
                            FormatDate(toFilter)
                          }
                          reportData={services.map(service => {
                            return {
                              idservice: String(
                                '0000000' + service.idservice
                              ).slice(-7),
                              shortname: service.shortname,
                              vin: service.vin,
                              model: service.model,
                              year: service.year,
                              locationName: service.locationName,
                              idservicestatus: service.idservicestatus,
                              employee:
                                service.firstname + ' ' + service.lastname,
                              status: service.status,
                              datecreate: FormatDate(service.datecreate),
                              laborscustomer: service.LaborsCustomer,
                              partscustomer: service.PartsCustomer,
                              total: service.totalServiceCustomer
                            };
                          })}
                        />
                      }
                      fileName={'report_services.pdf'}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Print'
                      }
                    </PDFDownloadLink>
                  </IconButton>
                )}
                {!onlySummary && services && services.length > 0 && (
                  <BtnExportToExcell
                    dataReport={services.map(service => {
                      return {
                        idservice: String('0000000' + service.idservice).slice(
                          -7
                        ),
                        shortname: service.shortname,
                        vin: service.vin,
                        model: service.model,
                        year: service.year,
                        locationName: service.locationName,
                        employee: service.firstname + ' ' + service.lastname,
                        status: service.status,
                        datecreate: FormatDate(service.datecreate),
                        totallabors: { v: service.Labors, t: 'n' },
                        totalLaborsCustomer: {
                          v: service.LaborsCustomer,
                          t: 'n'
                        },
                        totalParts: { v: service.Parts, t: 'n' },
                        totalPartsTaxables: {
                          v: service.PartsTaxables,
                          t: 'n'
                        },
                        totalPartsCustomer: {
                          v: service.PartsCustomer,
                          t: 'n'
                        },
                        totalService: { v: service.totalCostService, t: 'n' },
                        totalServiceCustomer: {
                          v: service.totalServiceCustomer,
                          t: 'n'
                        }
                      };
                    })}
                    fileName={'report_services'}
                  ></BtnExportToExcell>
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {!onlySummary && services && services.length > 0 && (
            <AdvanceTableWrapper
              columns={columns}
              data={services}
              sortable
              pagination
              perPage={10}
            >
              <Card className="mb-3">
                <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Table responsive striped hover style={{ width: '100%' }}>
                      <tbody>
                        <tr style={{ textAlign: 'right' }} className="right">
                          <td>
                            {i18next.t('label.Total')}
                            {'  '}
                            {FormatCurrency2Dec(total)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                  <AdvanceTablePagination table />
                </Card.Footer>
              </Card>
            </AdvanceTableWrapper>
          )}
          {onlySummary && servicesSummary != null && (
            <ServiceReportSummary
              dataInfo={servicesSummary}
            ></ServiceReportSummary>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ServicesReport;
