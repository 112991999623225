import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import AlertNotification from 'form-components/AlertNotification.js';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { GetRandomPassword, ValidateUser } from 'helpers/Security';
import { ColoredLine } from 'utils/Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const URI = ApiEndpoint + 'customercontact/';

export default function EditCustomerContact({
  idContact,
  idCustomer,
  closeModal,
  setOpenAlert,
  setTypeAlert,
  setAlertMessage
}) {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  });
  const [password, setPassword] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idCustomer: idCustomer,
      idcustomercontact: '',
      name: '',
      lastname: '',
      title: '',
      phone: '',
      mobile: '',
      email: '',
      password: '',
      alias: ''
    }
  });
  const [values, setValues] = useState({
    idCustomer: idCustomer,
    idcustomercontact: '',
    name: '',
    lastname: '',
    title: '',
    phone: '',
    mobile: '',
    email: '',
    password: '',
    alias: ''
  });
  const [contact, setContact] = useState(values);
  const [randomPass, setRandomPass] = useState('');
  useEffect(() => {
    axios.get(URI + idContact).then(response => {
      setContact(response.data);
    });
  }, []); // empty array makes hook working once

  useEffect(() => {
    setPassword(randomPass);
    setConfirmPassword(randomPass);
    setShowPass(true);
  }, [randomPass]); // empty array makes hook working once
  // const fields = ['warehousename', 'address',  'phone', 'manager'];
  // fields.forEach(field => setValue(field, warehouse[field]));

  const onSubmitContact = (data, e) => {
    e.preventDefault();
    const form = e.target;

    if (form.checkValidity() === false) {
      console.log('entre a submit 123');
      setValidated(true);

      e.stopPropagation();
      return;
    }

    setValidated(true);
    const URIInsert = ApiEndpoint + 'customercontacts/';
    if (idContact > 0) {
      axios
        .put(URIInsert + idContact, {
          idcustomercontact: idContact,
          idCustomer: idCustomer,
          alias: contact.alias,
          name: contact.name,
          lastname: contact.lastname,
          title: contact.title,
          phone: contact.phone,
          mobile: contact.mobile,
          email: contact.email
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URIInsert, {
          idCustomer: idCustomer,
          name: contact.name,
          lastname: contact.lastname,
          title: contact.title,
          phone: contact.phone,
          mobile: contact.mobile,
          email: contact.email,
          password: password,
          salt: uuidv4(),
          alias: contact.alias
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onChange = event => {
    const { name, value } = event.target;
    setContact({ ...contact, [name]: value });
  };

  const onChangePassword = event => {
    setPassword(event.target.value);
    validateInput(event);
  };
  const onChangeConfirm = event => {
    setConfirmPassword(event.target.value);
    validateInput(event);
  };
  const validateInput = e => {
    if (idContact > 0) {
      return;
    }
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'password':
          if (!value) {
            stateObj[name] = 'Please enter Password.';
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj['confirmPassword'] =
              'Password and Confirm Password does not match.';
          } else if (value.length < 6) {
            stateObj['password'] = 'Password is too small.';
          } else {
            stateObj['confirmPassword'] = confirmPassword
              ? ''
              : error.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Please enter Confirm Password.';
          } else if (password && value !== password) {
            stateObj[name] = 'Password and Confirm Password does not match.';
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form validated={validated}>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="title">
                  <FormInputText
                    control={control}
                    label={i18next.t('label.title')}
                    name="title"
                    changeHandler={onChange}
                    defaultValue={contact.title}
                  ></FormInputText>
                </Form.Group>
              </Col>
              <Col>
                {' '}
                <Form.Group className="mb-3" controlId="name">
                  <FormInputText
                    label={i18next.t('label.Name')}
                    changeHandler={onChange}
                    name={'name'}
                    control={control}
                    defaultValue={contact.name}
                  ></FormInputText>
                </Form.Group>
              </Col>
              <Col>
                {' '}
                <Form.Group className="mb-3" controlId="lastname">
                  <FormInputText
                    control={control}
                    label={i18next.t('label.Lastname')}
                    name="lastname"
                    changeHandler={onChange}
                    defaultValue={contact.lastname}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="phone">
                  <FormInputText
                    control={control}
                    label={i18next.t('label.Phone')}
                    name="phone"
                    changeHandler={onChange}
                    defaultValue={contact.phone}
                  ></FormInputText>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="mobile">
                  <FormInputText
                    control={control}
                    label={i18next.t('label.Mobile')}
                    name="mobile"
                    changeHandler={onChange}
                    defaultValue={contact.mobile}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} className="mb-3" controlId="email">
                  <FormInputText
                    control={control}
                    label={i18next.t('label.Email')}
                    name="email"
                    type="email"
                    changeHandler={onChange}
                    defaultValue={contact.email}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <ColoredLine color="gray"></ColoredLine>
              </Col>
            </Row>
            <Row>
              <Col>
                {idContact === 0 && (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      setRandomPass(GetRandomPassword());
                    }}
                  >
                    Generate Password
                  </Button>
                )}
              </Col>
            </Row>
            {idContact === 0 && (
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      placeholder={'Password'}
                      value={password}
                      name="password"
                      onChange={onChangePassword}
                      type={showPass ? 'text' : 'password'}
                    />
                  </Form.Group>
                  {error.password && (
                    <span className="err">{error.password}</span>
                  )}
                </Col>
                <Col>
                  <FormInputText
                    control={control}
                    label={i18next.t('label.ConfirmPassword')}
                    name="confirmPassword"
                    type={showPass ? 'text' : 'password'}
                    required={false}
                    changeHandler={onChangeConfirm}
                    defaultValue={confirmPassword}
                  ></FormInputText>
                  {error.confirmPassword && (
                    <span className="err">{error.confirmPassword}</span>
                  )}
                </Col>
              </Row>
            )}
            <Button
              type="submit"
              onClick={handleSubmit(onSubmitContact)}
              color="primary"
              size="sm"
            >
              {i18next.t('label.Save')}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
