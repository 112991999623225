import axios from 'axios';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import EditServiceContextProvider from 'context/_EditServiceContextProvider';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServiceCustomerFilesList from './_ServiceCustomerFilesList';
import ServiceCustomerFailures from './_ServiceCustomerFailures';
import ServiceCustomerLaborsList from './_ServiceCustomerLaborsList';
import ServiceCustomerPartsList from './_ServiceCustomerPartsList';
import FilesPreviewList from 'components/_FilesPreviewList';

const DetailServiceCustomer = () => {
  const [service, setService] = useState([]);
  const [idService, setIdService] = useState(null);
  const [totalService, setTotalService] = useState(0);
  const [openModalOrder, setOpenModalOrder] = useState(false);
  let { id } = useParams();
  const URI = ApiEndpoint + 'services/' + id;
  const handleClose = () => {
    setOpenModalOrder(false);
    getService();
  };

  //mostrar companies

  useEffect(() => {
    getService();
  }, []);

  const getService = () => {
    axios.get(URI).then(response => {
      let serviceNew = response.data[0];
      setIdService(serviceNew.idservice);
      setService(serviceNew);

      let totalLabors = getTotalLaborPrice(serviceNew.servicelabors ?? []);
      let totalParts = getTotalPartsPrice(serviceNew.serviceparts ?? []);
      setTotalService(totalLabors + totalParts);
    });
  };

  const getTotalLaborPrice = items => {
    return items
      .filter(item => !item.isincluded)
      .map(({ price }) => price)
      .reduce(
        (price, currentValue) => Number(price ?? 0) + Number(currentValue),
        0
      );
  };

  const getTotalPartsPrice = items =>
    items
      .filter(item => item?.chargetocustomer == true)
      .map(({ quantity, price }) => Number(quantity) * Number(price))
      .reduce((total, currentValue) => Number(total) + Number(currentValue), 0);

  return (
    <EditServiceContextProvider>
      <>
        <PageHeader
          title={
            i18next.t('label.ServiceDetail') +
            ' - ' +
            String('0000000' + service.idservice).slice(-7)
          }
          titleTag="h5"
          className="mb-3"
        >
          <p className="mb-3">{FormatDate(service.datecreate)}</p>
          Status:
          <SoftBadge
            pill
            bg={classNames({
              success: service.idservicestatus === 1,
              primary: service.idservicestatus === 2,
              warning: service.idservicestatus === 3,
              secondary: service.idservicestatus === 4,
              info: service.idservicestatus === 5
            })}
            className="me-2"
          >
            {service.idservicestatus === 1 &&
              `${i18next.t('status.InProccess')}`}
            {service.idservicestatus === 2 &&
              `${i18next.t('status.InLocation')}`}
            {service.idservicestatus === 3 &&
              `${i18next.t('status.InExternalService')}`}
            {service.idservicestatus === 4 && `${i18next.t('status.Canceled')}`}
            {service.idservicestatus === 5 &&
              `${i18next.t('status.Completed')}`}
            <FontAwesomeIcon
              icon={classNames({
                check:
                  service.idservicestatus ===
                  `${i18next.t('status.Completed')}`,
                redo:
                  service.idservicestatus ===
                  `${i18next.t('status.InLocation')}`,
                stream:
                  service.idservicestatus ===
                  `${i18next.t('status.InExternalService')}`,
                ban:
                  service.idservicestatus === `${i18next.t('status.Canceled')}`,
                ban:
                  service.idservicestatus ===
                  `${i18next.t('status.InProccess')}`
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        </PageHeader>
        <Card className="mb-3">
          <Card.Body>
            <Row>
              <Col md={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">Customer</h5>
                <h6 className="mb-2">{service.customer?.shortname}</h6>
                <p className="mb-1 fs--1">{service.customer?.address}</p>
                <p className="mb-0 fs--1">
                  <strong>
                    Email:{' '}
                    <a href={`$mailto:${service.customer?.email}`}>
                      {service.customer?.email}
                    </a>
                  </strong>
                </p>
                <p className="mb-0 fs--1">
                  <strong>
                    Phone:{' '}
                    <a href="tel:7897987987">{service.customer?.phone}</a>
                  </strong>
                </p>
              </Col>
              <Col md={4} className="mb-4 mb-lg-0">
                <h5 className="mb-3 fs-0">{i18next.t('label.Vehicle')}</h5>
                <h6 className="mb-2">{service.vehicle?.vin}</h6>
                <p className="mb-0 fs--1">
                  {service.vehicle?.year ?? ''}-{service.vehicle?.make ?? ''}-
                  {service.vehicle?.model ?? ''}
                </p>
                <div className="text-500 fs--1">
                  Unit #: {service.vehicle?.unit ?? ''}
                </div>
              </Col>
              <Col md={4}>
                <h5 className="mb-3 fs-0">{i18next.t('label.Total')}</h5>
                <div className="d-flex">
                  <div className="flex-1">
                    <p className="mb-0 fs--1">
                      <h4 className="text-primary mb-0">
                        {FormatCurrency(totalService)}
                      </h4>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <Row>
          <Col xs="12" style={{ textAlign: 'right' }}>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="arrow-down"
              className="me-1 mb-2 mb-sm-0"
              iconClassName="me-1"
              onClick={() => {
                setOpenModalOrder(true);
              }}
            >
              Cotizacion
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="print"
              iconClassName="me-1"
              className="me-1 mb-2 mb-sm-0"
            >
              Invoice
            </IconButton>
          </Col>
        </Row> */}

        <Row className="g-3 mb-3">
          <Col lg={12}>
            <FalconComponentCard noGuttersBottom style={{ overflow: 'auto' }}>
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceCustomerLaborsList idService={idService} />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FalconComponentCard noGuttersBottom style={{ overflow: 'auto' }}>
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceCustomerPartsList idService={idService} />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <FalconComponentCard noGuttersBottom style={{ overflow: 'auto' }}>
              <FalconComponentCard.Body
                children={
                  idService && (
                    <ServiceCustomerFilesList
                      serviceGuid={id}
                      idService={idService}
                    />
                  )
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col lg={12}>
            <FalconComponentCard noGuttersBottom style={{ overflow: 'auto' }}>
              <FalconComponentCard.Body
                children={
                  idService && <ServiceCustomerFailures idService={idService} />
                }
              />
            </FalconComponentCard>
          </Col>
        </Row>
      </>
    </EditServiceContextProvider>
  );
};

export default DetailServiceCustomer;
