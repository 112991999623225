import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import BtnExportToExcell from 'form-components/ExportToExcel';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ServicesReportPdf from 'Reports/Generic/ServicesReportPdf';
import VehiclessReportPdf from 'Reports/Generic/VehiclessReportPdf';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate, FormatDateToDb } from 'utils/FormatData';
import VehiclesFilters from './_VehicleFilters';

const URI = ApiEndpoint + 'services/';
const columns = [
  {
    accessor: 'locationName',
    Header: 'Location',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'vin',
    Header: `${i18next.t('label.Vin')}`,
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'model',
    Header: 'Model',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'year',
    Header: 'Year',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'shortname',
    Header: 'Customer',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'datecreate',
    Header: 'Service Date',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>
          {rowData.row.original.datecreate
            ? FormatDate(rowData.row.original.datecreate)
            : '-'}
        </>
      );
    }
  }
];

const VehiclesReport = () => {
  const [locationIdFilter, setLocationIdFilter] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(new Date());
  const [toFilter, setToFilter] = useState(new Date());
  const [vehiclesList, setVehiclesList] = useState([]);
  //mostrar companies /services/filters
  const getReport = async () => {
    const URI2 = ApiEndpoint + 'reports/';
    const res = await axios.post(URI2 + 'vehicleslist', {
      idcustomer: customerIdFilter,
      idlocation: locationIdFilter,
      idstatus: idStatusFilter,
      del: FormatDateToDb(fromFilter),
      al: FormatDateToDb(toFilter)
    });
    setVehiclesList(res.data.length > 0 ? res.data : []);
  };

  return (
    <>
      <VehiclesFilters
        setLocationIdFilter={setLocationIdFilter}
        locationIdFilter={locationIdFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        action={getReport}
      ></VehiclesFilters>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                {vehiclesList && vehiclesList.length > 0 && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    transform="shrink-3"
                  >
                    <PDFDownloadLink
                      document={
                        <VehiclessReportPdf
                          period={
                            FormatDate(fromFilter) +
                            ' - ' +
                            FormatDate(toFilter)
                          }
                          reportData={vehiclesList.map(vehicle => {
                            return {
                              customer: vehicle.shortname,
                              vin: vehicle.vin,
                              model: vehicle.model,
                              year: vehicle.year,
                              locationName: vehicle.locationName,
                              status: vehicle.status,
                              datecreate: !vehicle.datecreate
                                ? FormatDate(vehicle.datecreate)
                                : '-'
                            };
                          })}
                        />
                      }
                      fileName={'report_vehicles.pdf'}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Print'
                      }
                    </PDFDownloadLink>
                  </IconButton>
                )}
                {vehiclesList && vehiclesList.length > 0 && (
                  <BtnExportToExcell
                    dataReport={vehiclesList.map(vehicle => {
                      return {
                        customer: vehicle.shortname,
                        vin: vehicle.vin,
                        model: vehicle.model,
                        year: vehicle.year,
                        locationName: vehicle.locationName,
                        status: vehicle.status,
                        datecreate: !vehicle.datecreate
                          ? FormatDate(vehicle.datecreate)
                          : '-'
                      };
                    })}
                    fileName={'report_vehicles'}
                  ></BtnExportToExcell>
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTableWrapper
            columns={columns}
            data={vehiclesList}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default VehiclesReport;
