import { Col, Image, Row, Table } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { ApiEndpoint } from 'utils/ApiEndPont';
import axios from 'axios';
import IconButton from 'components/common/IconButton';
import SimpleBarReact from 'simplebar-react';
import ReactToPrint from 'react-to-print';
import {
  Format2Dec,
  FormatCurrency,
  FormatCurrency2Dec,
  FormatDate
} from 'utils/FormatData';
const Br = () => '\n';

const PreviewPayment = ({ idPayment }) => {
  let componentRef = useRef();
  const [payment, setPayment] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [print, setPrint] = useState(false);
  const [total, setTotal] = useState(0);
  const pageStyle = `{ size: 2.5in 4in }`;

  const getPayment = () => {
    const URI = ApiEndpoint + 'payments/getpayment/' + idPayment;
    axios.get(URI).then(response => {
      let serviceData = response.data[0];
      setPayment(serviceData);
    });
  };

  const getPaymentDetails = () => {
    const URI = ApiEndpoint + 'payments/getpaymentdetail/' + idPayment;
    axios.get(URI).then(response => {
      let serviceData = response.data;
      setPaymentDetails(serviceData);
    });
  };

  useEffect(() => {
    getPayment();
    getPaymentDetails();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const totalPayment = !paymentDetails
    ? 0
    : paymentDetails.reduce(
        (total, currentValue) => (total = total + Number(currentValue.amount)),
        0
      );

  return (
    <>
      <Row>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col style={{ alignContent: 'right' }}>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="file"
            className="me-1 mb-2 mb-sm-0"
            iconClassName="me-1"
            onClick={() => {
              setPrint(true);
            }}
          ></IconButton>
        </Col>
        <Col>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="print"
                className="me-1 mb-2 mb-sm-0"
                iconClassName="me-1"
              >
                Print
              </IconButton>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>

      {payment && (
        <div ref={componentRef}>
          <Row
            className="align-items-center text-center"
            style={{ marginBottom: '0px !important' }}
          >
            <Col sm={2} className="text-sm-start">
              <Image
                style={{ width: '100px', marginBottom: '0px !important' }}
                src="../../css/falcon.png"
              />
            </Col>
            <Col
              sm={6}
              style={{ textAlign: 'center', marginBottom: '0px !important' }}
              className="mt-0 mt-sm-0"
            >
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '12px'
                }}
                className="mt-0 mt-sm-0"
              >
                FRANCO MOBILE REPAIR
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                SHOP: 425 TAFT HWY / MAIL: 7605 Cranberry Way
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                Bakersfield, CA. 93307
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                Phone: 661-377-4222
              </div>
              <div style={{ textAlign: 'center', fontSize: '10px' }}>
                MAIL ADDRESS: 7605 Cranberry Way, Bakersfield,  CA. 93313
              </div>
            </Col>
            <Col className="text-sm-end mt-0 mt-sm-0">
              <Table>
                <tbody>
                  <tr>
                    <th colSpan={2}>
                      PAYMENT # <Br />{' '}
                      {String('0000000' + payment.idpayment).slice(-7)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: 'left' }}>
              <h6> </h6>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <h6> PAYMENT Date: {FormatDate(payment.datepayment)} </h6>
            </Col>
          </Row>{' '}
          <hr style={{ margin: 0 }} />
          <Row
            className="align-items-center"
            style={{
              padding: '0px',
              marginTop: '0px !important',
              marginBottom: '0px !important'
            }}
          >
            <Col
              style={{
                marginTop: '0px !important',
                marginBottom: '0px !important'
              }}
            >
              {/* <h6 className="text-500">Invoice to</h6> */}
              <h6
                style={{
                  marginTop: '0px !important',
                  marginBottom: '0px !important'
                }}
              >
                {payment.customer.company}
              </h6>
              <p className="fs--1" style={{ marginBottom: '0px !important' }}>
                {payment.customer.address}
                <br />
                {payment.customer.city}, {payment.customer.state}
                <br />
                {payment.customer.email} {payment.customer.phone}
              </p>
            </Col>
          </Row>
          <hr style={{ margin: 0, marginBottom: '0px !important' }} />
          <Row>
            <Col>
              <Table
                striped
                className="border-bottom"
                style={{
                  minHeight: '430px',
                  maxHeight: '430px',
                  padding: '0px',
                  paddingLeft: '2px',
                  display: 'table',
                  width: '100%'
                }}
              >
                <thead className="light">
                  <tr>
                    <th
                      style={{
                        fontSize: 12,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      className="border-0 text-center"
                    >
                      Service / Description
                    </th>
                    <th
                      style={{
                        fontSize: 12,
                        color: '#2D2D2D',
                        fontWeight: 'bold'
                      }}
                      className="border-0 text-end"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentDetails &&
                    paymentDetails.map(detail => (
                      <tr>
                        <td
                          style={{
                            fontSize: 12,
                            color: '#2D2D2D',
                            margin: 1,
                            padding: 1
                          }}
                          className="align-middle text-center"
                        >
                          {detail.idservice
                            ? 'Payment for service ' +
                              String('0000000' + detail.idservice).slice(-7)
                            : detail.description}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            color: '#2D2D2D',
                            margin: 1,
                            padding: 1
                          }}
                          className="align-middle text-end"
                        >
                          {FormatCurrency2Dec(detail.amount)}
                        </td>
                      </tr>
                    ))}
                  {(() => {
                    let rows = [];
                    for (
                      let i = paymentDetails ? paymentDetails.length : 0;
                      i < 25;
                      i++
                    ) {
                      rows.push(
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            colSpan={6}
                            className="align-left"
                          >
                            -
                          </td>

                          <td
                            style={{
                              fontSize: 8,
                              color: '#2D2D2D',
                              margin: 1,
                              padding: 1
                            }}
                            className="align-middle text-end"
                          ></td>
                        </tr>
                      );
                    }
                    return rows;
                  })()}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                style={{
                  margin: '1px',
                  marginBottom: '0px !important',
                  padding: '1px'
                }}
                borderless
                size="sm"
                className="fs--1 text-end"
              >
                <tbody>
                  <tr className=" fw-bolder text-800">
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        alignContent: 'right',
                        textAlign: 'right',
                        alignItems: 'right',
                        size: 9
                      }}
                    >
                      Total: {FormatCurrency(Number(totalPayment || 0))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PreviewPayment;
