import axios from 'axios';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Accordion, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormInputText } from 'form-components/FormInputText';
import { useForm } from 'react-hook-form';
const FilterVehicleServices = ({
  setVehicleIdFilter,
  vehicleIdFilter,
  filterFolio,
  setFilterFolio,
  action
}) => {
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const { control } = useForm();

  //mostrar companies
  const getVehicles = async () => {
    // if (customerIdFilter < 1) {
    //   return;
    // }
    const UriVehicles = ApiEndpoint + 'allvehicles/';
    const res = await axios.get(UriVehicles);
    const vehiclesList = res.data.map(item => {
      return {
        value: item.idVehicle,
        label: `${item.vin} - ${item.make} - ${item.year}`
      };
    });
    const firstItem = [{ value: 0, label: 'All Vehicles' }];
    setVehicles(firstItem.concat(vehiclesList));
  };

  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filters</Accordion.Header>
        <Accordion.Body>
          <Form as={Row} className="gx-2">
            <Row>
              <Col xs="auto">
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {i18next.t('label.Vehicle')}
                  </InputGroup.Text>
                  <div style={{ width: '250px' }}>
                    <Select
                      aria-label="Default select"
                      name="vehicleIdFilter"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={selectedVehicle}
                      onChange={value => {
                        setVehicleIdFilter(value.value);
                        setSelectedVehicle(value);
                      }}
                      options={vehicles}
                    ></Select>
                  </div>
                </InputGroup>
              </Col>
              <Col xs="auto">
                <InputGroup size="sm">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {i18next.t('label.Folio')}
                  </InputGroup.Text>
                  <Form.Control
                    value={filterFolio || ''}
                    name={filterFolio}
                    placeholder={i18next.t('label.Folio')}
                    onChange={event => {
                      setFilterFolio(event.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col xs="auto">
                <Row>
                  <Col style={{ alignContent: 'right' }}></Col>
                  <Col>
                    <Button
                      type="button"
                      className="mb-5"
                      onClick={() => action()}
                      color="primary"
                      size="sm"
                    >
                      {i18next.t('label.Filter')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FilterVehicleServices;
