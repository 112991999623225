import axios from 'axios';
import PageHeader from 'components/common/PageHeader';
import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';
import PendingServices from './PendingServices';
import CustomerDebt from './CustomerDebt';
import { useAuth } from 'context/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import ConfirmAction from 'form-components/ConfirmationModal';

const NewPayment = () => {
  const Auth = useAuth();
  const [customers, setCustomers] = useState(null);
  const [paymentsTypes, setPaymentsTypes] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [bankAccounts, setBankAccounts] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [services, setServices] = useState(null);
  const [debtCustomer, setDebtCustomer] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(null);
  const [totalPending, setTotalPending] = useState(
    services ? services.length : 0
  );
  const [values, setValues] = useState({
    idCustomer: '0',
    amount: '0',
    bankCharge: '0',
    paymentDate: '0',
    paymentType: '0',
    bankAccount: '0',
    reference: '',
    otherAmount: '0',
    otherDescription: ''
  });
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idCustomer: '0',
      amount: '0',
      bankCharge: '0',
      paymentDate: '0',
      paymentType: '0',
      bankAccount: '0',
      reference: '',
      otherAmount: '0',
      otherDescription: ''
    }
  });

  const getCustomers = async () => {
    const URICustomers = ApiEndpoint + 'customers/';
    const res = await axios.get(URICustomers);
    setCustomers(res.data);
  };
  const getPaymentTypes = async () => {
    const URIPaymentTypes = ApiEndpoint + 'paymenttypes/';
    const res = await axios.get(URIPaymentTypes);
    setPaymentsTypes(res.data);
  };

  const getBankAccounts = async () => {
    const URIPaymentTypes = ApiEndpoint + 'bankaccounts/';
    const res = await axios.get(URIPaymentTypes);
    setBankAccounts(res.data);
  };

  useEffect(() => {
    getCustomers();
    getPaymentTypes();
    getBankAccounts();
  }, []);

  const getPendingServices = idCustomer => {
    console.log('idcustomer');
    console.log(idCustomer);
    setServices(null);
    if (!idCustomer) return;

    const URI = ApiEndpoint + 'services/pendingbycustomer/';
    axios
      .get(URI + idCustomer)
      .then(function (response) {
        setServices(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetDebtByCustomer = idCustomer => {
    setDebtCustomer(null);
    if (!idCustomer) return;

    const URI = ApiEndpoint + 'services/debtcustomer/';
    axios
      .get(URI + idCustomer)
      .then(function (response) {
        setDebtCustomer(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPendingServices(customerId);
    GetDebtByCustomer(customerId);
  }, [customerId]);

  const onChange = event => {
    const { name, value } = event.target;
    console.log(name);
    console.log(value);
    setValues({ ...values, [name]: value });
  };

  const addOtherDebt = () => {
    const OtherPayment = {
      debt: values.otherAmount,
      description: values.otherDescription,
      total: values.otherAmount,
      shortname: 'N/A',
      vehicle: values.otherDescription,
      idservice: '0',
      datereceived: new Date(),
      serviceid: uuidv4()
    };
    console.log(OtherPayment);
    services.unshift(OtherPayment);

    setServices(services);
    setTotalPending(totalPending + 1);
    console.log(services);
  };

  const PaymentConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }

    const dataToSubmit = {
      idcustomer: customerId,
      total: values.amount,
      bankCharge: values.bankCharge,
      datepayment: values.paymentDate,
      idpaymenttype: values.paymentType,
      idbankaccount: values.bankAccount,
      reference: values.reference,
      paymentid: '',
      idpaymentstatus: 1,
      datecreate: new Date(),
      services: services.filter(item => item.debt > 0)
    };

    console.log('dataToSubmit');
    console.log(dataToSubmit);
    const URI = ApiEndpoint + 'payments/create/';
    axios
      .post(URI, dataToSubmit)
      .then(function (response) {
        setAlertMessage(i18next.t('label.SuccessfulRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = () => {
    setOpenConfirm(true);
    return;
  };
  return (
    <>
      <PageHeader
        title={i18next.t('label.NewPayment')}
        titleTag="h5"
        className="mb-3"
      >
        <>
          <Row>
            <Col>
              <p className="mb-3"></p>
            </Col>
            <Col></Col>
          </Row>
        </>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={2} className="mb-4 mb-lg-0">
              <Form.Label>{i18next.t('label.Customer')}</Form.Label>
            </Col>
            <Col md={6} className="mb-4 mb-lg-0">
              <Form.Group>
                <Form.Select
                  aria-label="Default select"
                  size="sm"
                  name="customerId"
                  required={true}
                  onChange={selectedOption => {
                    setCustomerId(null);
                    console.log(selectedOption.target.value);
                    setCustomerId(`${selectedOption.target.value}`);
                  }}
                  value={`${customerId}`}
                >
                  <option key={'customer_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!customers?.length &&
                    customers.map(customer => (
                      <option
                        key={customer.idcustomer}
                        value={customer.idcustomer}
                      >
                        {customer.shortname}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-4 mb-lg-0">
              {debtCustomer && (
                <>
                  <CustomerDebt debtCustomer={debtCustomer}></CustomerDebt>
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={2} className="mb-4 mb-lg-0">
              <FormInputText
                label={i18next.t('label.PaymentDate')}
                name={'paymentDate'}
                control={control}
                required={true}
                type={'date'}
                defaultValue={values.paymentDate}
                changeHandler={onChange}
              ></FormInputText>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="mb-4 mb-lg-0">
              <Form.Label>{i18next.t('label.PaymentType')}</Form.Label>
            </Col>
            <Col md={4} className="mb-4 mb-lg-0">
              <Form.Group>
                <Form.Select
                  aria-label="Default select"
                  size="sm"
                  name="paymentType"
                  required={true}
                  value={values.paymentType}
                  onChange={selectedOption => {
                    onChange(selectedOption);
                    //setIdEmployee(`${selectedOption.target.value}`);
                  }}
                >
                  <option key={'paymenttype_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!paymentsTypes?.length &&
                    paymentsTypes.map(paymentType => (
                      <option
                        key={paymentType.idpaymenttype}
                        value={paymentType.idpaymenttype}
                      >
                        {paymentType.paymenttype}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={2} className="mb-4 mb-lg-0">
              <Form.Label>{i18next.t('label.BankAccount')}</Form.Label>
            </Col>
            <Col md={4} className="mb-4 mb-lg-0">
              <Form.Group>
                <Form.Select
                  aria-label="Default select"
                  size="sm"
                  name="bankAccount"
                  required={true}
                  onChange={selectedOption => {
                    onChange(selectedOption);
                    //setIdEmployee(`${selectedOption.target.value}`);
                  }}
                  defaultValue={values.bankAccount}
                >
                  <option key={'paymenttype_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!bankAccounts?.length &&
                    bankAccounts.map(bankAccount => (
                      <option
                        key={bankAccount.idbankaccount}
                        value={bankAccount.idbankaccount}
                      >
                        {bankAccount.bankaccount}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          {customerId && (
            <Row>
              <Col>
                <FormInputText
                  label={i18next.t('label.Description')}
                  name={'otherDescription'}
                  control={control}
                  defaultValue={values.otherDescription}
                  changeHandler={onChange}
                ></FormInputText>
              </Col>
              <Col>
                {' '}
                <FormInputText
                  label={i18next.t('label.Amount')}
                  name={'otherAmount'}
                  control={control}
                  required={true}
                  defaultValue={values.otherAmount}
                  changeHandler={onChange}
                ></FormInputText>
              </Col>
              <Col>
                <Button onClick={addOtherDebt} variant="contained">
                  Guardar
                </Button>
              </Col>
            </Row>
          )}

          <hr />
          <Row>
            {services && (
              <PendingServices
                setServices={setServices}
                services={services}
                totalService={totalPending}
                totalInPayment={values.amount}
              ></PendingServices>
            )}
          </Row>
          <Row>
            <Col>
              <FormInputText
                label={i18next.t('label.Amount')}
                name={'amount'}
                control={control}
                required={true}
                defaultValue={values.amount}
                changeHandler={onChange}
              ></FormInputText>
            </Col>
            <Col>
              <FormInputText
                label={i18next.t('label.BankCharge')}
                name={'bankCharge'}
                control={control}
                required={true}
                defaultValue={values.bankCharge}
                changeHandler={onChange}
              ></FormInputText>
            </Col>
            <Col>
              <FormInputText
                label={i18next.t('label.Reference')}
                name={'reference'}
                control={control}
                required={true}
                defaultValue={values.reference}
                changeHandler={onChange}
              ></FormInputText>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col></Col>
            <Col>
              {' '}
              <Button onClick={onSubmit} variant="contained">
                Guardar
              </Button>{' '}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {openConfirm && (
        <ConfirmAction
          message={'Desea registrar el pago?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={PaymentConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default NewPayment;
