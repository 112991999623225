import axios from 'axios';
import GenericTableHeader from 'form-components/TableHeaders/GenericTableHeader';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import ListItem from './Partials/ListItem';
import MyModal from 'shared/Modal';
import { EditServiceContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddServiceTask from './Partials/_AddServiceTask';
import { FormatDate } from 'utils/FormatData';
import ConfirmAction from 'form-components/ConfirmationModal';

const URI = ApiEndpoint + 'services/tasks/';
const ServiceTasks = ({ idService, isEditable, isFinished, printFunction }) => {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [idtask, setIdTask] = useState(0);
  const [idTaskToDelete, setIdTaskToDelete] = useState(0);
  const [serviceTaskList, setServiceTaskList] = useState([]);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(null);
  const handleClose = () => {
    setOpenModalTask(false);
    getServiceTasks();
    window.location.reload();
  };

  useEffect(() => {
    getServiceTasks();
  }, []);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    getServiceTasks();
  };
  const DeleteConfirmed = isConfirmed => {
    if (!isConfirmed) {
      return;
    }
    const URIDelete = ApiEndpoint + 'services/task/';
    axios
      .delete(URIDelete + idTaskToDelete)
      .then(function (response) {
        if (response.data.error) {
          setAlertMessage(i18next.t('label.Error'));
          setTypeAlert('warning');
          setOpenAlert(true);
          return;
        }
        setAlertMessage(i18next.t('label.SuccessfulDeletedRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        getServiceTasks();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getServiceTasks = () => {
    axios.get(URI + idService).then(response => {
      let tasks = response.data;
      setServiceTaskList(tasks);
      // setIdsymptomcategory(response.data.idsymptomcategory);
    });
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <GenericTableHeader
            label={i18next.t('label.TasksReported')}
            isFinished={isFinished}
            newFunction={() => {
              setIdTask(0);
              setOpenModalTask(true);
            }}
            allowPrint={true}
            printFunction={() => {
              printFunction(true);
            }}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive fs--1">
            <Table
              responsive
              striped
              hover
              style={{ height: '350px', width: '100%' }}
            >
              <thead>
                <tr>
                  <th scope="col">{i18next.t('label.Task')}</th>
                  <th scope="col">{i18next.t('label.Create')}</th>
                  <th scope="col">{i18next.t('label.Autorized')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {serviceTaskList &&
                  serviceTaskList.map((task, index) => (
                    <tr key={task.idservicetask}>
                      <td>{task.task}</td>
                      <td>{FormatDate(task.datecreate)}</td>
                      <td>{task.autorized ? 'yes' : 'no'}</td>
                      <td>
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!isEditable}
                          onClick={() => {
                            setIdTaskToDelete(task.idservicetask);
                            setOpenConfirm(true);
                          }}
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {serviceTaskList.length == 0 && (
            <span>
              <em>No data was found.</em>
            </span>
          )}
        </Card.Body>
      </Card>

      {openModalTask && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.TaskEdit')}
          openModal={openModalTask}
          closeModal={handleClose}
        >
          <AddServiceTask
            closeModal={handleClose}
            idService={idService}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          ></AddServiceTask>
        </MyModal>
      )}
      {openConfirm && (
        <ConfirmAction
          message={'Desea eliminar el registro?'}
          title={'Confirmacion'}
          handleClose={handleCloseConfirm}
          open={openConfirm}
          ConfirmAction={DeleteConfirmed}
        ></ConfirmAction>
      )}
    </>
  );
};

export default ServiceTasks;
