import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleBarReact from 'simplebar-react';
import i18next from 'i18next';
import classNames from 'classnames';
import { FormatCurrency, FormatCurrency2Dec } from 'utils/FormatData';
import SoftBadge from 'components/common/SoftBadge';

const SingleItem = ({
  icon,
  title,
  description,
  category,
  comments,
  status,
  price,
  quantity
}) => {
  return (
    <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative">
      <Row>
        <Col xs={3}>
          <Row>
            <Col xs={10}>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 className="fs--1 text-600 mb-0 ps-3">{category}</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={10}>
              <h5 className="fs--1 text-800">{description}</h5>
              <p className="mb-0 fs--2 text-500">{comments}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={2} sx={{ textAlign: 'right' }}>
          <Flex>
            <h5 className="text-primary mb-0">
              {FormatCurrency2Dec(price ?? 0)}
            </h5>
          </Flex>
        </Col>
        <Col xs={1} sx={{ textAlign: 'center' }}>
          <Flex>
            <h5 className="text-primary mb-0">{quantity}</h5>
          </Flex>
        </Col>
        <Col xs={2} sx={{ textAlign: 'right' }}>
          <Flex>
            <h5 className="text-primary mb-0">
              {FormatCurrency2Dec(price * quantity ?? 0)}
            </h5>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const ListItemLabor = ({ data }) => {
  return (
    <SimpleBarReact className="ask-analytics">
      <div className="pt-0 px-card">
        <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative">
          <Row>
            <Col xs={3}>
              <Row>
                <Col xs={10}>
                  <Row>
                    <Col>
                      <h4 className="fs--1 text-600 mb-0 ps-3">
                        {i18next.t('label.Part')}
                      </h4>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={10}>{i18next.t('label.Details')}</Col>
              </Row>
            </Col>
            <Col xs={1} sx={{ textAlign: 'center' }}>
              <Flex>
                <h4 className="fs--1 text-600 mb-0 ps-3">
                  {i18next.t('label.Price')}
                </h4>
              </Flex>
            </Col>
            <Col xs={2} sx={{ textAlign: 'center' }}>
              <Flex>
                <h4 className="fs--1 text-600 mb-0 ps-3">
                  {i18next.t('label.Quantity')}
                </h4>
              </Flex>
            </Col>
            <Col xs={2} sx={{ textAlign: 'right' }}>
              <Flex>
                <h4 className="fs--1 text-600 mb-0 ps-3">
                  {i18next.t('label.Total')}
                </h4>
              </Flex>
            </Col>
          </Row>
        </div>
        {data.map(item => (
          <SingleItem
            key={item.title}
            icon={item.icon}
            title={item.title}
            description={item.description}
            category={item.category}
            comments={item.comments}
            status={item.status}
            price={item.price}
            quantity={item.quantity}
          />
        ))}
      </div>
    </SimpleBarReact>
  );
};

ListItemLabor.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired
};

export default ListItemLabor;
