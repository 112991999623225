import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import BtnExportToExcell from 'form-components/ExportToExcel';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency, FormatDate, FormatDateToDb } from 'utils/FormatData';
import ServiceFilters from './Filters/ServicesFilters';
import LaborsFilters from './Filters/_LaborsFilters';
import LaborsReportPdf from './Generic/LaborsReportPdf';
import ServicesReportPdf from './Generic/ServicesReportPdf';
import ServiceReportSummaryPdf from './Generic/SummaryServicesReportPdf';

const URI = ApiEndpoint + 'summaryservices/';
const columns = [
  {
    accessor: 'service',
    Header: 'Service',
    headerProps: { style: { minWidth: '15px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return (
        <>{String('0000000' + rowData.row.original.idservice).slice(-7)}</>
      );
    }
  },
  {
    accessor: 'locationName',
    Header: 'Location',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'company',
    Header: `${i18next.t('label.Customer')}`,
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'vin',
    Header: 'Vin',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'tax',
    Header: 'Tax',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'parts',
    Header: 'Parts',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'labors',
    Header: 'Labors',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'laborsdiscount',
    Header: 'Discount',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  },
  {
    accessor: 'total',
    Header: 'Total',
    headerProps: { style: { minWidth: '80px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatCurrency(rowData.row.original.total)}</>;
    }
  },
  {
    accessor: 'datecreate',
    Header: 'Service Date',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' },
    Cell: rowData => {
      return <>{FormatDate(rowData.row.original.datecreate) || ''}</>;
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { style: { minWidth: '100px' }, className: 'ps-2' },
    cellProps: { className: 'ps-2' }
  }
];

const ServicesSummaryReport = () => {
  const [locationIdFilter, setLocationIdFilter] = useState(0);
  const [customerIdFilter, setCustomerIdFilter] = useState(0);
  const [idStatusFilter, setIdStatusFilter] = useState(0);
  const [fromFilter, setFromFilter] = useState(new Date());
  const [toFilter, setToFilter] = useState(new Date());
  const [services, setServices] = useState([]);
  //mostrar companies /services/filters
  const getServices = async () => {
    const URI2 = ApiEndpoint + 'reports/';
    const res = await axios.post(URI2 + 'summaryservices', {
      idcompany: 0,
      idcustomer: customerIdFilter,
      idvehicle: 0,
      idlocation: locationIdFilter,
      idstatus: idStatusFilter,
      del: FormatDateToDb(fromFilter),
      al: FormatDateToDb(toFilter)
    });
    setServices(res.data.length > 0 ? res.data : []);
  };

  return (
    <>
      <ServiceFilters
        setLocationIdFilter={setLocationIdFilter}
        locationIdFilter={locationIdFilter}
        customerIdFilter={customerIdFilter}
        setCustomerIdFilter={setCustomerIdFilter}
        setIdStatusFilter={setIdStatusFilter}
        idStatusFilter={idStatusFilter}
        fromFilter={fromFilter}
        setFromFilter={setFromFilter}
        toFilter={toFilter}
        setToFilter={setToFilter}
        action={getServices}
        includeSummaryBtn={false}
      ></ServiceFilters>
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                {services && services.length > 0 && (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="print"
                    transform="shrink-3"
                  >
                    <PDFDownloadLink
                      document={
                        <ServiceReportSummaryPdf
                          period={
                            FormatDate(fromFilter) +
                            ' - ' +
                            FormatDate(toFilter)
                          }
                          reportData={services.map(service => {
                            return {
                              idservice: String(
                                '0000000' + service.idservice
                              ).slice(-7),
                              locationName: service.locationName,
                              company: service.company,
                              vin: service.vin,
                              tax: service.tax,
                              parts: Number(service.parts),
                              labors: Number(service.labors),
                              laborsdiscount: Number(service.laborsdiscount),
                              subtotal: Number(service.subtotal),
                              totaltax: Number(service.totaltax),
                              total: Number(service.total),
                              datecreate: FormatDate(service.datecreate)
                            };
                          })}
                        />
                      }
                      fileName={'summary_services.pdf'}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Print'
                      }
                    </PDFDownloadLink>
                  </IconButton>
                )}
                {services && services.length > 0 && (
                  <BtnExportToExcell
                    dataReport={services.map(service => {
                      return {
                        idservice: String('0000000' + service.idservice).slice(
                          -7
                        ),
                        locationName: service.locationName,
                        company: service.company,
                        vin: service.vin,
                        tax: service.tax,
                        parts: { v: service.parts, t: 'n' },
                        labors: { v: service.labors, t: 'n' },
                        discount: { v: service.laborsdiscount, t: 'n' },
                        subtotal: { v: service.subtotal, t: 'n' },
                        totaltax: { v: service.totaltax, t: 'n' },
                        total: { v: service.total, t: 'n' },
                        datecreate: FormatDate(service.datecreate)
                      };
                    })}
                    fileName={'summary_services.pdf'}
                  ></BtnExportToExcell>
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTableWrapper
            columns={columns}
            data={services}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default ServicesSummaryReport;
