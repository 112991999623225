import axios from 'axios';
import Flex from 'components/common/Flex';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { ApiEndpoint } from 'utils/ApiEndPont';
import Select from 'react-select';
const ServiceFilters = ({
  setLocationIdFilter,
  locationIdFilter,
  customerIdFilter,
  setCustomerIdFilter,
  setIdStatusFilter,
  idStatusFilter,
  fromFilter,
  setFromFilter,
  toFilter,
  setToFilter,
  setVehicleIdFilter,
  vehicleIdFilter,
  onlySummary,
  setOnlySummary,
  action,
  includeSummaryBtn = true
}) => {
  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  //mostrar locations
  const getLocationsList = async () => {
    const UriLocations = ApiEndpoint + 'locations/';
    const res = await axios.get(UriLocations);
    setLocations(res.data);
  };

  const getCustomers = async () => {
    const URICustomers = ApiEndpoint + 'customers/';
    const res = await axios.get(URICustomers);
    setCustomers(res.data);
  };

  useEffect(() => {
    const URI = ApiEndpoint + 'servicestatus/';
    axios(URI).then(({ data }) => {
      const result = data.map(item => {
        return { label: item.status, value: item.idservicestatus };
      });
      setStatusList(result);
    });
  }, []);

  useEffect(() => {
    getVehicles();
  }, [customerIdFilter]);

  //mostrar companies
  const getVehicles = async () => {
    const UriVehicles = ApiEndpoint + 'customervehicles/';
    const res = await axios.get(UriVehicles + customerIdFilter);
    const vehiclesList = res.data.map(item => {
      return {
        value: item.idVehicle,
        label: `${item.vin} - ${item.make} - ${item.year}`
      };
    });
    const firstItem = [{ value: 0, label: 'All Vehicles' }];
    setVehicles(firstItem.concat(vehiclesList));
  };

  useEffect(() => {
    getLocationsList();
    getCustomers();
    getVehicles();
  }, []);

  const onChangeOnlySummary = event => {
    setOnlySummary(!onlySummary);
  };
  return (
    <Card className="mb-3">
      <Card.Body>
        <Form>
          <Row className="align-items-center g-3">
            <Col xs="auto">
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">
                  {i18next.t('label.Locations')}
                </InputGroup.Text>
                <Form.Select
                  size="sm"
                  aria-label="Default select"
                  name="locationIdFilter"
                  onChange={selectedOption => {
                    setLocationIdFilter(`${selectedOption.target.value}`);
                  }}
                  value={`${locationIdFilter}`}
                >
                  <option key={'location_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!locations?.length &&
                    locations.map(location => (
                      <option
                        key={location.idLocation}
                        value={location.idLocation}
                      >
                        {location.locationName}
                      </option>
                    ))}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col xs="auto">
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">
                  {i18next.t('label.Customer')}
                </InputGroup.Text>
                <Form.Select
                  aria-label="Default select"
                  size="sm"
                  name="employeeIdFilter"
                  onChange={selectedOption => {
                    setCustomerIdFilter(`${selectedOption.target.value}`);
                  }}
                  value={`${customerIdFilter}`}
                >
                  <option key={'location_0'} value={0}>
                    {i18next.t('label.SelectSomeValue')}
                  </option>
                  {!!customers?.length &&
                    customers.map(customer => (
                      <option
                        key={customer.idcustomer}
                        value={customer.idcustomer}
                      >
                        {customer.shortname}
                      </option>
                    ))}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col xs="auto">
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">
                  {i18next.t('label.Vehicle')}
                </InputGroup.Text>
                <div style={{ width: '250px' }}>
                  <Select
                    aria-label="Default select"
                    name="vehicleIdFilter"
                    size="sm"
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    value={selectedVehicle}
                    onChange={value => {
                      setVehicleIdFilter(value.value);
                      setSelectedVehicle(value);
                    }}
                    options={vehicles}
                  ></Select>
                </div>
              </InputGroup>
            </Col>
            {idStatusFilter != null ? (
              <Col xs="auto">
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-sm">
                    {i18next.t('label.Status')}
                  </InputGroup.Text>
                  <Form.Select
                    aria-label="Default select"
                    size="sm"
                    name="status"
                    onChange={selectedOption => {
                      setIdStatusFilter(`${selectedOption.target.value}`);
                    }}
                    value={`${idStatusFilter}`}
                  >
                    <option key={'location_0'} value={0}>
                      {i18next.t('label.SelectSomeValue')}
                    </option>
                    {!!statusList?.length &&
                      statusList.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </Col>
            ) : (
              ''
            )}

            <Col xs="auto">
              <Row>
                <Col style={{ alignContent: 'right' }}>
                  <span class="input-group-text" id="inputGroup-sizing-sm">
                    {i18next.t('label.From')}
                  </span>
                </Col>
                <Col style={{ alignContent: 'left' }}>
                  <ReactDatePicker
                    className="mb-3"
                    selected={fromFilter}
                    size="sm"
                    onChange={date => setFromFilter(date)}
                    placeholderText="Select Date"
                    dateFormat={'MM/dd/yyyy'}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row>
                <Col style={{ alignContent: 'right' }}>
                  <span class="input-group-text" id="inputGroup-sizing-sm">
                    {i18next.t('label.To')}
                  </span>
                </Col>
                <Col>
                  <ReactDatePicker
                    className="mb-3"
                    selected={toFilter}
                    size="sm"
                    onChange={date => setToFilter(date)}
                    placeholderText={i18next.t('label.To')}
                    dateFormat={'MM/dd/yyyy'}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs="auto">
              {includeSummaryBtn && (
                <Form.Group className="mb-3" controlId="onlysummary">
                  <Form.Check
                    type="switch"
                    id="onlysummary"
                    label={i18next.t('label.Summary')}
                    checked={onlySummary}
                    onChange={onChangeOnlySummary}
                  />
                </Form.Group>
              )}
            </Col>
            <Col xs="auto">
              <Button
                type="button"
                className="mb-3"
                onClick={() => action()}
                color="primary"
                size="sm"
              >
                {i18next.t('label.Filter')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ServiceFilters;
