import React, { useContext, useEffect } from 'react';
import LogoutContent from './LogoutContent';
import { useAuth } from 'context/AuthProvider';

const Logout = () => {
  const Auth = useAuth();

  useEffect(() => {
    Auth.logout();
  }, []);
  return (
    <div className="text-center">
      <LogoutContent />
    </div>
  );
};

export default Logout;
