export const FormatDate = data => {
  var date = new Date(data);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return month + '/' + dt + '/' + year;
};

export const FormatDateToDb = data => {
  var date = new Date(data);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return year + '/' + month + '/' + dt;
};

export const FormatCurrency = data => {
  return (
    '$' +
    (isNumber(data)
      ? parseFloat(data)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      : data)
  );
};

export const FormatCurrency2Dec = data => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return '$' + formatter.format(data);
};

export const Format2Dec = data => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(data);
};

export const IsNumber = data => {
  return IsNumber(data);
};

var isNumber = function isNumber(value) {
  return typeof value === 'number' && isFinite(value);
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
