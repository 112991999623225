import axios from 'axios';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ApiEndpoint } from 'utils/ApiEndPont';

export const ValidateUser = async alias => {
  const URI = ApiEndpoint + 'users/validate/';
  const res = await axios.get(URI + alias);
  return res.error;
};

export const GetRandomPassword = () => {
  let uuid = uuidv4();
  return uuid.split('-')[0];
};
