import axios from 'axios';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatDate } from 'utils/FormatData';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import Intelligence from 'Dashboards/ListSummary/Intelligence';
import StatisticsCard from 'Dashboards/Cards/StatisticsCard';
import { decryptData } from 'utils/Encrypth';
const URI = ApiEndpoint + 'services/report/';

const DashboardByCustomer = () => {
  const [services, setServices] = useState([]);
  const [idStatusSelected, setIdStatusSelected] = useState(1);
  const [servicesList, setServicesList] = useState([]);
  const [location, setLocation] = useState(null);
  const [idCustomer, setIdCustomer] = useState(0);

  useEffect(() => {
    let userData = JSON.parse(
      decryptData(localStorage.getItem('loginFalconData'))
    );
    setIdCustomer(userData.idcustomer ?? 0);
  }, []);

  const getServices = () => {
    axios
      .get(URI + 'countbylocation?idcustomer=' + idCustomer)
      .then(function (response) {
        const serviceData = response.data.map(item => {
          return {
            title: item.status,
            value: item.vehicles,
            decimal: false,
            suffix: '',
            prefix: '',
            valueClassName: 'text-info',
            badgeBg: 'info',
            link: '',
            linkText: 'See all',
            idStatus: item.idservicestatus
          };
        });
        console.log('serviceData');
        console.log(serviceData);
        setServices(serviceData);
      })
      .then(() => {
        const URILocation = ApiEndpoint + 'servicesbycustomer/';
        axios.get(URILocation + idCustomer).then(response => {
          setLocation(response.data);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getServicesByStatus = async () => {
    setServicesList([]);
    const res = await axios.post(URI + 'servicesbycustomer', {
      idcustomer: idCustomer,
      idservicestatus: idStatusSelected
    });
    console.log(res.data);
    const resultServiceList = res.data.map(item => {
      return {
        title: String('0000000' + item.idservice).slice(-7),
        icon: 'angle-right',
        description: `${item.vehicle.vin} - ${item.vehicle.make} - ${item.vehicle.year}`,
        date: FormatDate(item.datecreate),
        company: item.company.companyName,
        status: item.servicestatus.idservicestatus,
        uniqueId: item.serviceid
      };
    });
    console.log('resultServiceList');
    console.log(resultServiceList);
    setServicesList(resultServiceList);
  };

  useEffect(() => {
    getServicesByStatus();
  }, [idStatusSelected]);

  useEffect(() => {
    getServices();
    getServicesByStatus();
  }, [idCustomer]);
  return (
    <>
      {idCustomer > 0 && (
        <>
          <Card className="bg-100 shadow-none border mb-3">
            <Card.Body className="py-0">
              <Row className="g-0 justify-content-between">
                <Col sm="auto">
                  <Flex alignItems="center">
                    <img
                      src={barChart}
                      width={90}
                      alt="..."
                      className="ms-n4"
                    />
                    <div>
                      <h6 className="text-primary fs--1 mb-0">
                        Franco Mobile{' '}
                      </h6>
                      <h4 className="text-primary fw-bold mb-0">
                        {location?.locationName}
                      </h4>
                    </div>
                    <img
                      src={lineChart}
                      width={140}
                      alt="..."
                      className="ms-n4 d-md-none d-lg-block"
                    />
                  </Flex>
                </Col>
                <Col md="auto" className="mb-3 mb-md-0">
                  {/* <Row className="g-3 gy-md-0 h-100 align-items-center">
                <Col xs="auto">
                  <h6 className="text-700 mb-0 text-nowrap">
                    Showing Data For:
                  </h6>
                </Col>
                <Col md="auto">
                  <FiltersDates />
                </Col>
              </Row> */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="g-3 mb-3">
            <Col xxl={9}>
              <Row className="g-3 mb-3">
                {services.map((stat, index) => (
                  <Col key={index} sm={index === 2 ? 12 : 6} md={4}>
                    <StatisticsCard
                      onClick={() => {
                        setIdStatusSelected(0);
                        console.log('stat.idStatus');
                        console.log(stat);
                        setIdStatusSelected(stat.idStatus);
                      }}
                      stat={stat}
                      style={{ minWidth: '12rem' }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Intelligence data={servicesList} isCustomer={true}></Intelligence>
        </>
      )}
      {/* <ServicesListByStatus idLocation={1} idStatusService={1} /> */}
    </>
  );
};

export default DashboardByCustomer;
