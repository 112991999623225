import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormInputText } from 'form-components/FormInputText';
import { EditServiceContext } from 'context/Context';
import Select from 'react-select';

const URI = ApiEndpoint + 'scategories/';
const URIFailures = ApiEndpoint + 'failures/';

export default function AddServiceParts({
  closeModal,
  failureList,
  idService,
  idVehicle,
  idThecnician,
  idLocation
}) {
  const { setOpenAlert, setTypeAlert, setAlertMessage } =
    useContext(EditServiceContext);
  const [selectedPartStr, setSelectedPartStr] = useState('');
  const [partsList, setPartsList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [idWarehouse, setIdWarehouse] = useState(0);
  const [idEmployee, setIdEmployee] = useState(0);
  const [chargetoCustomer, setChargetoCustomer] = useState(1);
  const [idPart, setIdPart] = useState(0);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [part, setPart] = useState(0);

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      idWarehouse: '',
      idEmployee: '',
      idPart: '',
      price: '0',
      serialnumber: '',
      quantity: '1',
      chargetoCustomer: '1'
    }
  });
  const [values, setValues] = useState({
    idWarehouse: '',
    idEmployee: '',
    idPart: '',
    price: '0',
    serialnumber: '',
    quantity: '1',
    chargetoCustomer: '1'
  });
  const [partService, setPartService] = useState(values);

  useEffect(() => {
    const uriParts = ApiEndpoint + 'parts/';
    axios(uriParts).then(({ data }) => {
      const partList = data.map(parts => {
        return {
          value: `${parts.idparts}`,
          label: `${parts.partcode}-${parts.description}`
        };
      });
      setPartsList(partList);
    });
  }, []); // empty array makes hook working once

  useEffect(() => {
    const uriWarehouse = ApiEndpoint + 'warehouse/';
    axios(uriWarehouse).then(({ data }) => {
      const warehouseList = data.map(warehouse => {
        return {
          value: `${warehouse.idwarehouse}`,
          label: `${warehouse.warehousename}`
        };
      });
      setWarehouseList(warehouseList);
    });
  }, []); // empty array makes hook working once

  useEffect(() => {
    const uriEmployees = ApiEndpoint + 'employees/';
    axios(uriEmployees).then(({ data }) => {
      const employeeslist = data.map(employee => {
        return {
          value: `${employee.idemployee}`,
          label: `${employee.firstname} ${employee.lastname}`
        };
      });
      setEmployeeList(employeeslist);
    });
  }, []);

  useEffect(() => {
    setIdEmployee(idThecnician ?? 0);
  }, []);

  const onSubmit = async (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    const uriParts = ApiEndpoint + 'services/parts/' + idService;

    axios
      .post(uriParts, {
        idservice: idService,
        idwarehouse: idWarehouse,
        idemployee: idEmployee,
        idpart: idPart,
        price: partService.price,
        serialnumber: partService.serial,
        quantity: partService.quantity,
        chargetocustomer: chargetoCustomer,
        idvehicle: idVehicle
      })
      .then(function (response) {
        setAlertMessage(i18next.t('label.SuccessfulRecord'));
        setTypeAlert('success');
        setOpenAlert(true);
        console.log(response);
        closeModal();
      })
      .catch(function (error) {
        console.log(error);
        setAlertMessage(i18next.t('label.ErrorSelectValid'));
        setTypeAlert('warning');
        setOpenAlert(true);
      });
  };

  const onChange = event => {
    const { name, value } = event.target;
    setPartService({ ...partService, [name]: value });
  };

  const onChangePart = value => {
    setPartService({ ...partService, ['idPart']: value });
    setIdPart(value);
    const uriParts = ApiEndpoint + 'parts/' + value;
    axios(uriParts).then(({ data }) => {
      setPartService(data);
    });
  };

  const onChangeIsVisible = event => {
    setChargetoCustomer(!chargetoCustomer);
  };
  return (
    <div>
      <Card variant="elevation">
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="employee">
                  <Form.Label>{i18next.t('label.Thecnician')}</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    name="employee"
                    style={{ minWidth: '250px' }}
                    value={idEmployee}
                    onChange={selectedOption => {
                      onChange(selectedOption);
                      setIdEmployee(`${selectedOption.target.value}`);
                    }}
                  >
                    <option key={'location_0'} value={0}>
                      {i18next.t('label.SelectSomeValue')}
                    </option>
                    {!!employeeList?.length &&
                      employeeList.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="warehouse">
                  <Form.Label>{i18next.t('label.Warehouse')}</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    name="warehouse"
                    style={{ minWidth: '250px' }}
                    onChange={selectedOption => {
                      setIdWarehouse(`${selectedOption.target.value}`);
                      console.log(`${selectedOption.target.value}`);
                      onChange(selectedOption);
                    }}
                  >
                    <option key={'location_0'} value={0}>
                      {i18next.t('label.SelectSomeValue')}
                    </option>
                    {!!warehouseList?.length &&
                      warehouseList.map(warehouse => (
                        <option key={warehouse.value} value={warehouse.value}>
                          {warehouse.label}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="idPart">
                  <Form.Label>{i18next.t('label.Part')}</Form.Label>
                  <div style={{ width: '450px' }}>
                    <Select
                      aria-label="Default select"
                      name="part"
                      size="sm"
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      value={part}
                      onChange={value => {
                        setPart(value);
                        onChangePart(`${value.value}`);
                      }}
                      options={partsList}
                    ></Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="quantity">
                  <FormInputText
                    label={i18next.t('label.Quantity')}
                    changeHandler={onChange}
                    name={'quantity'}
                    control={control}
                    defaultValue={partService.quantity}
                  ></FormInputText>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="price">
                  <FormInputText
                    label={i18next.t('label.Price')}
                    changeHandler={onChange}
                    name={'price'}
                    control={control}
                    defaultValue={partService.price}
                  ></FormInputText>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="serial">
                  <FormInputText
                    label={i18next.t('label.Serial')}
                    changeHandler={onChange}
                    name={'serial'}
                    control={control}
                    defaultValue={partService.serial}
                  ></FormInputText>
                </Form.Group>
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="isVisible"
                  label={i18next.t('label.VisibleToCustomer')}
                  value={chargetoCustomer}
                  defaultChecked="true"
                  onChange={onChangeIsVisible}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                  size="sm"
                >
                  {i18next.t('label.Save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
