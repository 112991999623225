import {
  Page,
  Document,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer';

import { useEffect, useState } from 'react';
import HeaderPdf from 'shared/HeaderPdf';
import { FormatCurrency, FormatDate } from 'utils/FormatData';
const BORDER_COLOR = '#000';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  headerDiv: {
    'text-align': 'center!important',
    'margin-bottom': '1rem!important',
    display: 'flex',
    'flex-wrap': 'wrap'
  },
  logoheader: {
    'text-align': 'left!important',
    flex: '0 0 auto',
    width: '16.66666667%'
  },
  textHeader: {
    'font-size': '.8333333333rem!important'
  },
  em: {
    fontStyle: 'bold'
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  headerBg: {
    // backgroundColor: '#aaa'
  },
  table: {
    display: 'table',
    width: '50%',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row'
  },

  tableCellHeader: {
    fontSize: 10,
    fontWeight: 'bold'
    // fontFamily: "CustomRoboto",
  },
  tableCell: {
    margin: 1,
    fontSize: 9
    // fontFamily: "CustomRoboto",
  },
  textCenter: {
    textAlign: 'center'
  }
});
let tableCol = {
  borderStyle: 'solid',
  borderColor: '#dcdcdc',
  borderBottomColor: '#000',
  borderWidth: 0,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0.5
};

const Br = () => '\n';
// Create Document Component
const ServicesReportPdf = ({ reportData, period }) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={{ padding: '15px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Image
                style={{ width: '100px' }}
                source="/css/falcon.png"
                //assets/img/illustrations/falcon.png
              />
            </View>
            <View style={{ flex: 3 }}>
              <HeaderPdf></HeaderPdf>
            </View>
          </View>
          <View key={'title'} style={[tableCol, { width: '100%' }]}>
            <Text
              style={{
                margin: 2,
                fontSize: 12,
                textAlign: 'center'
              }}
            >
              Services By Period
            </Text>
          </View>
          <View key={'title'} style={[tableCol, { width: '100%' }]}>
            <Text
              style={{
                margin: 1,
                fontSize: 9,
                textAlign: 'center'
              }}
            >
              Period: {period}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 4, height: 1, backgroundColor: 'black' }} />
          </View>
          <View
            style={{
              width: '100%',
              height: '100%'
            }}
          >
            <View key={'idx_rowtitle'} style={styles.tableRow}>
              <View key={'idx_col1title'} style={[tableCol, { width: '15%' }]}>
                <Text style={[styles.tableCell]}>Service</Text>
              </View>
              <View key={'idx_col2_title'} style={[tableCol, { width: '25%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 7,
                    textAlign: 'left'
                  }}
                >
                  Customer
                </Text>
              </View>
              <View key={'idx_col3_title'} style={[tableCol, { width: '18%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 7,
                    textAlign: 'left'
                  }}
                >
                  Location
                </Text>
              </View>
              <View key={'idx_col4_title'} style={[tableCol, { width: '28%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 8,
                    textAlign: 'left'
                  }}
                >
                  Vehicle
                </Text>
              </View>
              <View key={'idx_col5_title'} style={[tableCol, { width: '25%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 7,
                    textAlign: 'left'
                  }}
                >
                  Asigned To
                </Text>
              </View>
              <View key={'idx_col6_title'} style={[tableCol, { width: '15%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 8,
                    textAlign: 'left'
                  }}
                >
                  Created
                </Text>
              </View>
              <View key={'idx_col7_title'} style={[tableCol, { width: '25%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 7,
                    textAlign: 'left'
                  }}
                >
                  Status
                </Text>
              </View>
              <View key={'idx_col8_title'} style={[tableCol, { width: '18%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 8,
                    textAlign: 'left'
                  }}
                >
                  Total Labors
                </Text>
              </View>
              <View key={'idx_col9_title'} style={[tableCol, { width: '18%' }]}>
                <Text
                  style={{
                    margin: 1,
                    fontSize: 8,
                    textAlign: 'left'
                  }}
                >
                  Total Parts
                </Text>
              </View>
              <View
                key={'idx_col10_title'}
                style={[tableCol, { width: '15%' }]}
              >
                <Text
                  style={{
                    margin: 1,
                    fontSize: 10,
                    textAlign: 'right'
                  }}
                >
                  Service Total
                </Text>
              </View>
            </View>
            {reportData &&
              reportData.map((item, idx) => (
                <View key={idx} style={styles.tableRow}>
                  <View key={idx} style={[tableCol, { width: '15%' }]}>
                    <Text
                      style={[styles.tableCell, item.style ? item.style : {}]}
                    >
                      {item.idservice}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '25%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 7,
                        textAlign: 'left'
                      }}
                    >
                      {item.shortname}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '18%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 8,
                        textAlign: 'left'
                      }}
                    >
                      {item.locationName}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '28%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 7,
                        textAlign: 'left'
                      }}
                    >
                      {item.vin}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '25%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 7,
                        textAlign: 'left'
                      }}
                    >
                      {item.employee}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '15%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 7,
                        textAlign: 'left'
                      }}
                    >
                      {item.datecreate}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '25%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 7,
                        textAlign: 'left'
                      }}
                    >
                      {item.status}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '18%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 8,
                        textAlign: 'right'
                      }}
                    >
                      {FormatCurrency(item.laborscustomer)}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '18%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 8,
                        textAlign: 'right'
                      }}
                    >
                      {FormatCurrency(item.partscustomer)}
                    </Text>
                  </View>
                  <View key={idx} style={[tableCol, { width: '15%' }]}>
                    <Text
                      style={{
                        margin: 1,
                        fontSize: 10,
                        textAlign: 'right'
                      }}
                    >
                      {FormatCurrency(item.total)}
                    </Text>
                  </View>
                </View>
              ))}
            <View key={'idx_rowtitle1'} style={styles.tableRow}>
              <View
                key={'idx_col1title1'}
                style={[tableCol, { width: '68%' }]}
              ></View>
              <View
                key={'idx_col8_title1'}
                style={[tableCol, { width: '35%' }]}
              >
                <Text
                  style={{
                    margin: 1,
                    fontSize: 8,
                    textAlign: 'right'
                  }}
                >
                  Total Labors:{' '}
                  {FormatCurrency(
                    reportData.reduce(
                      (total, currentValue) =>
                        (total = total + Number(currentValue.laborscustomer)),
                      0
                    )
                  )}
                </Text>
              </View>
              <View
                key={'idx_col9_title1'}
                style={[tableCol, { width: '35%' }]}
              >
                <Text
                  style={{
                    margin: 1,
                    fontSize: 8,
                    textAlign: 'right'
                  }}
                >
                  Total Parts:{' '}
                  {FormatCurrency(
                    reportData.reduce(
                      (total, currentValue) =>
                        (total = total + Number(currentValue.partscustomer)),
                      0
                    )
                  )}
                </Text>
              </View>
              <View
                key={'idx_col10_title'}
                style={[tableCol, { width: '15%' }]}
              >
                <Text
                  style={{
                    margin: 1,
                    fontSize: 10,
                    textAlign: 'right'
                  }}
                >
                  {FormatCurrency(
                    reportData.reduce(
                      (total, currentValue) =>
                        (total = total + Number(currentValue.total)),
                      0
                    )
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ServicesReportPdf;
