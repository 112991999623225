import axios from 'axios';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import React from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { ApiEndpoint, FilesEndpoint } from 'utils/ApiEndPont';

// Rendering individual images
const FilePreview = ({ file }) => {
  console.log(file);
  return (
    <div
      className="file-item"
      style={{ height: '200px', width: '200px', margin: '2px' }}
    >
      <img
        alt={`img - ${file.description}`}
        src={FilesEndpoint + file.path.replace('/files/', '') + file.filename}
        className="file-img"
      />
    </div>
  );
};

// ImageList Component
const FilesPreviewList1 = ({ files }) => {
  // render each image by calling Image component
  const renderImage = (file, index) => {
    return <FilePreview file={file} />;
  };

  // Return the list of files
  return <section className="file-list">{files.map(renderImage)}</section>;
};

const FilesList = ({ files, setImgIndex }) => {
  const handleDownload = (url, filename) => {
    const config = { responseType: 'blob', path: url };
    // axios.post(URIFiles, config).then(response => {
    //     new File(response, filename);
    // });

    const URIFiles = ApiEndpoint + 'services/getfile';
    axios({
      url: URIFiles,
      method: 'POST',
      data: config,
      responseType: 'blob' // important
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Row className="g-2">
      {files.map((file, index) => {
        return (
          <Col xs={3}>
            {file.filetype.toLowerCase().includes('pdf') ||
            file.filetype.toLowerCase().includes('mp3') ||
            file.filetype.toLowerCase().includes('aac') ||
            file.filetype.toLowerCase().includes('ogg') ||
            file.filetype.toLowerCase().includes('m4a') ||
            file.filetype.toLowerCase().includes('wav') ||
            file.filetype.toLowerCase().includes('audio') ? (
              <Image
                style={{ height: '200px', width: '200px', margin: '2px' }}
                src={
                  file.filetype.includes('pdf')
                    ? '/css/pdf.png'
                    : file.filetype.toLowerCase().includes('pdf') ||
                      file.filetype.toLowerCase().includes('mp3') ||
                      file.filetype.toLowerCase().includes('aac') ||
                      file.filetype.toLowerCase().includes('ogg') ||
                      file.filetype.toLowerCase().includes('m4a') ||
                      file.filetype.toLowerCase().includes('wav') ||
                      file.filetype.toLowerCase().includes('audio')
                    ? '/css/audio_file.png'
                    : `${
                        FilesEndpoint +
                        file.path.replace('/files/', '') +
                        file.filename
                      }`
                }
                fluid
                rounded
                onClick={() =>
                  handleDownload(file.path + file.filename, file.filename)
                }
              />
            ) : file.filetype.includes('mp4') ||
              file.filetype.includes('3gp') ? (
              <ReactPlayer
                width="200px"
                height="200px"
                url={
                  FilesEndpoint +
                  file.path.replace('/files/', '') +
                  file.filename
                }
                controls={true}
                className="react-player"
              />
            ) : (
              <Image
                style={{ height: '200px', width: '200px', margin: '2px' }}
                src={`${
                  FilesEndpoint +
                  file.path.replace('/files/', '') +
                  file.filename
                }`}
                fluid
                rounded
                onClick={() => setImgIndex(index)}
              />
            )}

            <p className="h6">{file.description}</p>
          </Col>
        );
      })}
    </Row>
  );
};

const FilesPreviewList = ({ files }) => {
  console.log(files);
  return (
    <Card className="mt-3">
      <FalconCardHeader title="Files" light />
      <Card.Body>
        <FalconLightBoxGallery
          images={files
            .filter(
              file =>
                !(
                  file.filetype.includes('pdf') ||
                  file.filetype.toLowerCase().includes('pdf') ||
                  file.filetype.toLowerCase().includes('mp3') ||
                  file.filetype.toLowerCase().includes('aac') ||
                  file.filetype.toLowerCase().includes('ogg') ||
                  file.filetype.toLowerCase().includes('m4a') ||
                  file.filetype.toLowerCase().includes('wav') ||
                  file.filetype.toLowerCase().includes('audio')
                )
            )
            .map(file => {
              return (
                FilesEndpoint + file.path.replace('/files/', '') + file.filename
              );
            })}
          imagestitles={files
            .filter(
              file =>
                !(
                  file.filetype.includes('pdf') ||
                  file.filetype.toLowerCase().includes('pdf') ||
                  file.filetype.toLowerCase().includes('mp3') ||
                  file.filetype.toLowerCase().includes('aac') ||
                  file.filetype.toLowerCase().includes('ogg') ||
                  file.filetype.toLowerCase().includes('m4a') ||
                  file.filetype.toLowerCase().includes('wav')
                )
            )
            .map(file => {
              return file.description;
            })}
        >
          {setImgIndex => (
            <FilesList
              files={files.filter(
                file =>
                  !(
                    file.filetype.includes('pdf') ||
                    file.filetype.toLowerCase().includes('pdf') ||
                    file.filetype.toLowerCase().includes('mp3') ||
                    file.filetype.toLowerCase().includes('aac') ||
                    file.filetype.toLowerCase().includes('ogg') ||
                    file.filetype.toLowerCase().includes('m4a') ||
                    file.filetype.toLowerCase().includes('wav')
                  )
              )}
              setImgIndex={setImgIndex}
            />
          )}
        </FalconLightBoxGallery>
      </Card.Body>
    </Card>
  );
};

export default FilesPreviewList;
