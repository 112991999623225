import React, { useContext } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import i18n from 'utils/locales/i18n';

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item
        as={'li'}
        className={localStorage.getItem('locale') == 'es' ? 'active' : ''}
      >
        <Nav.Link className="px-2 " onClick={() => i18n.changeLanguage('es')}>
          ES
        </Nav.Link>
      </Nav.Item>
      <Nav.Item
        as={'li'}
        className={localStorage.getItem('locale') == 'en' ? 'active' : ''}
      >
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => i18n.changeLanguage('en')}
        >
          / EN
        </Nav.Link>
      </Nav.Item>

      <NotificationDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
