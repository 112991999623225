import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ServicesTableHeader from 'form-components/TableHeaders/ServicesTableHeader';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { ApiEndpoint } from 'utils/ApiEndPont';
import { FormatCurrency2Dec, FormatDate } from 'utils/FormatData';

const PendingServices = ({ services, setServices, totalInPayment }) => {
  const [currentPaymentTotal, setCurrentPaymentTotal] = useState(0);
  const columns = [
    {
      accessor: 'idservice',
      Header: 'Services', //Header: 'Info'
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        return (
          <>
            <strong>
              #COT-
              {String('0000000' + rowData.row.original.idservice).slice(-7)}
            </strong>
            <p className="mb-0 text-500">
              Received: {FormatDate(rowData.row.original.datereceived) || ''}
            </p>
            <p className="mb-0 text-500">
              {rowData.row.original.idservicestatus === 5 &&
                `Finished: ${FormatDate(rowData.row.original.datefinished)}`}
            </p>
          </>
        );
      }
    },
    {
      accessor: 'customer',
      Header: 'Description', //'Customer'
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <>
            <strong>{rowData.row.original.shortname}</strong>
            <p className="mb-0 text-500">
              {' '}
              <strong>vehicle: {rowData.row.original.vehicle}</strong>{' '}
            </p>
            <p className="mb-0 text-500">{rowData.row.original.locationName}</p>
          </>
        );
      }
    },
    {
      accessor: 'total',
      Header: 'Total', //'Customer'
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return (
          <>
            <strong>{FormatCurrency2Dec(rowData.row.original.total)}</strong>
          </>
        );
      }
    },
    {
      accessor: 'debt',
      Header: 'Debt', //'Customer'
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const idservice = rowData.row.original.serviceid;
        const debt = rowData.row.original.debt;
        const total = rowData.row.original.total;
        return (
          <>
            <a
              class="link-opacity-10"
              onClick={() => {
                const editData = services.map(item =>
                  item.idservice === idservice
                    ? { ...item, debt: Number(total) }
                    : item
                );
                var totalDebt = editData
                  .filter(item => item.debt > 0)
                  .reduce(
                    (totalDebt, row) =>
                      (totalDebt = Number(totalDebt) + Number(row.debt)),
                    0
                  );
                console.log('totalDebt', totalDebt);
                setCurrentPaymentTotal(totalDebt);
                setServices(editData);
              }}
              href="#"
            >
              Full Payment
            </a>
            <Form.Control
              size="sm"
              type="text"
              name="debt"
              value={debt}
              placeholder="$0"
              onChange={event => {
                onChangeInput(event, idservice);
              }}
            />
          </>
        );
      }
    }
  ];

  const onChangeInput = (e, idservice) => {
    const { name, value } = e.target;

    const editData = services.map(item =>
      item.serviceid === idservice ? { ...item, debt: Number(value) } : item
    );
    var maxDebt = editData.filter(item => item.debt > 0);

    console.log('editData', editData);
    var totalDebt = editData
      .filter(item => item.debt > 0)
      .reduce(
        (totalDebt, row) => (totalDebt = Number(totalDebt) + Number(row.debt)),
        0
      );
    setCurrentPaymentTotal(totalDebt);
    setServices(editData);
    e.preventDefault();
  };
  return (
    <>
      {services && (
        <>
          <AdvanceTableWrapper
            columns={columns}
            data={services}
            sortable
            pagination
            perPage={10}
          >
            <Card className="mb-3">
              <Card.Body className="p-0">
                <Row className="flex-end-center mb-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTablePagination table />
                <hr />
                <Row>
                  <Col></Col>
                  <Col>
                    {Number(totalInPayment) - Number(currentPaymentTotal)}
                  </Col>
                  <Col>{currentPaymentTotal}</Col>
                </Row>
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </>
      )}
    </>
  );
};

export default PendingServices;
