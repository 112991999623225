import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/avatars/avatar1.png';
import Avatar from 'components/common/Avatar';
import i18next from 'i18next';
import MyModal from 'shared/Modal';
import EditUserPasswordProfile from 'Catalogs/Security/_EditPasswordProfile';

const ProfileDropdown = () => {
  const [openModalPass, setOpenModalPass] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  const handleClose = () => {
    setOpenModalPass(false);
  };

  return (
    <>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          <Avatar src={team3} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            {/* <Dropdown.Item href="https://nexusdigitalmkt.com.mx/">Set status</Dropdown.Item>*/}
            <Dropdown.Item
              onClick={() => {
                setOpenModalPass(true);
              }}
            >
              {i18next.t('label.ChangePassword')}
            </Dropdown.Item>
            {/*<Dropdown.Item href="https://nexusdigitalmkt.com.mx/">Feedback</Dropdown.Item>*/}
            <Dropdown.Divider />
            {/* <Dropdown.Item as={Link} to="/user/settings">
            {i18next.t('label.Settings')}
          </Dropdown.Item> */}
            <Dropdown.Item as={Link} to="/security/Logout">
              {i18next.t('label.Logout')}
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {openModalPass && (
        <MyModal
          id="id_myModal"
          title={i18next.t('label.Edit') + ' ' + i18next.t('label.Password')}
          openModal={openModalPass}
          closeModal={handleClose}
        >
          <EditUserPasswordProfile
            closeModal={handleClose}
            setOpenAlert={setOpenAlert}
            setTypeAlert={setTypeAlert}
            setAlertMessage={setAlertMessage}
          />
        </MyModal>
      )}
    </>
  );
};

export default ProfileDropdown;
