import axios from 'axios';
import { FormInputText } from 'form-components/FormInputText';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ApiEndpoint } from 'utils/ApiEndPont';

const EditAccountType = ({
  idAccountType,
  closeModal,
  setOpenAlert,
  setTypeAlert,
  setAlertMessage
}) => {
  const [validated, setValidated] = useState(false);
  const URI = ApiEndpoint + 'accounttype/';
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      accounttype: ''
    }
  });
  const [values, setValues] = useState({
    accounttype: ''
  });

  useEffect(() => {
    axios.get(URI + idAccountType).then(response => {
      setValues(response.data);
    });
  }, []);

  const onSubmit = (data, e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setValidated(true);
    if (idAccountType > 0) {
      axios
        .put(URI + idAccountType, {
          idaccounttype: idAccountType,
          accounttype: values.accounttype
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .post(URI, {
          accounttype: values.accounttype
        })
        .then(function (response) {
          setAlertMessage(i18next.t('label.SuccessfulRecord'));
          setTypeAlert('success');
          setOpenAlert(true);
          console.log(response);
          closeModal();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const onChange = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3" controlId="accounttype">
              <FormInputText
                label={i18next.t('label.AccountType')}
                changeHandler={onChange}
                name={'accounttype'}
                control={control}
                defaultValue={values.accounttype}
              ></FormInputText>
            </Form.Group>
            <Button type="submit" color="primary" size="sm">
              {i18next.t('label.Save')}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditAccountType;
