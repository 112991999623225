import { Text, View } from '@react-pdf/renderer';
import React from 'react';

const HeaderPdf = () => {
  const Br = () => '\n';
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Text style={{ textAlign: 'center', fontSize: '12px' }}>
        FRANCO MOBILE REPAIR
      </Text>
      <Text style={{ textAlign: 'center', fontSize: '10px' }}>
        SHOP: 425 TAFT HWY / MAIL: 7605 Cranberry Way
        <Br /> Bakersfield, CA. 93313
        <Br /> Phone: 661-377-4222
        <Br /> MAIL ADDRESS: 7605 Cranberry Way, Bakersfield, CA. 93313
      </Text>
    </View>
  );
};

export default HeaderPdf;
