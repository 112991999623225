import { PDFDownloadLink } from '@react-pdf/renderer';
import IconButton from 'components/common/IconButton';
import BtnExportToExcell from 'form-components/ExportToExcel';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LaborsReportPdf from 'Reports/Generic/LaborsReportPdf';
import LaborsReportList from 'Reports/LaborsReport';
import { v4 as uuidv4 } from 'uuid';

const ServicesTableHeader = ({
  dataReport,
  setOpenModalService,
  titleReport = 'SalesReport',
  showNew = true,
  print = false
}) => {
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Services</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div id="orders-actions">
          {print && dataReport && dataReport.length > 0 && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="print"
              transform="shrink-3"
            >
              <PDFDownloadLink
                document={<LaborsReportPdf reportData={dataReport} />}
                fileName={'reportlabors.pdf'}
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Print'
                }
              </PDFDownloadLink>
            </IconButton>
          )}
          {showNew && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
            >
              <Link
                className="d-none d-sm-inline-block ms-1"
                to={'/Services/NewService/?id=' + uuidv4()}
              >
                <strong>New</strong>
              </Link>
            </IconButton>
          )}
          {showNew && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={() => setOpenModalService(true)}
            >
              <strong>New Quick</strong>
            </IconButton>
          )}
          {dataReport && (
            <BtnExportToExcell
              dataReport={dataReport}
              fileName={titleReport}
            ></BtnExportToExcell>
          )}
          {/* <IconButton
            variant="falcon-default"
            size="sm"
            costo
            vs
            precioicon="external-link-alt"
            icon="filter"
            transform="shrink-3"
            className="mx-2"
          >
            <span className="d-none d-sm-inline-block ms-1">Filter</span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="external-link-alt"
            transform="shrink-3"
          >
            <span className="d-none d-sm-inline-block ms-1">Export</span>
          </IconButton> */}
        </div>
      </Col>
    </Row>
  );
};

export default ServicesTableHeader;
