import React from 'react';
import { Card } from 'react-bootstrap';

const WelcomePage = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">Hello</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Welcome to FrancoAdmin System
        </p>
        <hr />
      </Card.Body>
    </Card>
  );
};

export default WelcomePage;
